import React, { FC, useEffect, useState } from 'react';
import { Center, Container, Form } from '../../utils/StyledComponents/containers';
import { LogoSvg } from '../../utils/uiComponents';
import InputEmail from '../../components/Forms/InputEmail';
import { emailPattern } from '../../constants/patterns.constants';
import InputPassword from '../../components/Forms/InputPassword';
import Checkbox from '../../components/Forms/Checkbox';
import LinkToTermsAndConditions from '../../components/LinkToTermsAndConditions';
import { PrimarySmallButton } from '../../utils/StyledComponents/buttons';
import { Trans } from 'react-i18next';
import { useForm, useWatch } from 'react-hook-form';
import { history } from '../../utils/history';
import * as theme from '../../theme';
import InputPhone from '../../components/Forms/InputPhone';
import VerifyEmailCodeModal from '../VerifyEmailCodeModal';
import { REQUEST_STATUSES } from '../../constants/requestStatuses.constants';
import VerifyPhoneCodeModal from '../VerifyPhoneCodeModal';
import { getTargetSurveyPath } from '../../utils/authorizedVotingHelper';

const AuthorizedVotingRegistration: FC<any> = (props) => {
  const {
    registerUser,
    surveyDetails,
    surveyDetailsFetchStatus,
    user,
    getSurveyDetail,
    registerFetchStatus,
    token,
    getAuthInfo,
    verifyEmailFetchStatus,
    accountForSurveyVotingData,
    verifyPhoneFetchStatus,
    resetSurveyState,
    emailModalOpen,
    setEmailModalOpen,
    phoneModalOpen,
    setPhoneModalOpen,
  } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
    formState,
    setError,
    clearErrors,
    control,
    setValue,
  } = useForm<any>({
    defaultValues: {
      email: user?.email || accountForSurveyVotingData?.email || null,
      phone: user?.phone || accountForSurveyVotingData?.phone || null,
    },
  });

  const password1 = useWatch({
    name: 'password1',
    defaultValue: '',
    control,
  });
  const password2 = useWatch({
    name: 'password2',
    defaultValue: '',
    control,
  });

  const onSubmit = (data: any) => {
    let isValid = true;
    if (data.password1 !== data.password2) {
      isValid = false;
      setError('password2', { type: 'differentPasswords' });
    }
    if (isValid) {
      if (data.hasOwnProperty('phone') && !data.phone) delete data.phone;
      registerUser(data, history.location.state);
    }
  };

  const getIsPhoneRequired = () => {
    return surveyDetails?.require_to_vote_phone;
  };

  const verifyEmailCallback = () => {
    setEmailModalOpen({ open: false, triggerRequest: false });
    checkIfVerificationRequired();
  };

  const verifyPhoneCallback = () => {
    setPhoneModalOpen({ open: false, triggerRequest: false });
    checkIfVerificationRequired();
  };

  const checkIfVerificationRequired = () => {
    if ((surveyDetailsFetchStatus !== REQUEST_STATUSES.DONE || !surveyDetails) && !user) return;
    if (
      surveyDetails.require_to_vote_email &&
      !user.is_email_verified &&
      verifyEmailFetchStatus !== REQUEST_STATUSES.DONE
    ) {
      setEmailModalOpen({ triggerRequest: true, open: emailModalOpen.open });
      setTimeout(() => {
        setEmailModalOpen({ triggerRequest: true, open: true });
      }, 100);
    } else if (
      surveyDetails.require_to_vote_phone &&
      !user.is_phone_verified &&
      verifyPhoneFetchStatus !== REQUEST_STATUSES.DONE
    ) {
      setPhoneModalOpen({ triggerRequest: true, open: phoneModalOpen.open });
      setTimeout(() => {
        setPhoneModalOpen({ triggerRequest: true, open: true });
      }, 100);
    } else if (
      (surveyDetails.require_to_vote_email &&
        (user.is_email_verified || verifyEmailFetchStatus === REQUEST_STATUSES.DONE)) ||
      (surveyDetails.require_to_vote_phone &&
        (user.is_phone_verified || verifyPhoneFetchStatus === REQUEST_STATUSES.DONE)) ||
      (!surveyDetails.require_to_vote_email && !surveyDetails.require_to_vote_phone)
    ) {
      resetSurveyState();
      history.push(getTargetSurveyPath({ surveyUid: accountForSurveyVotingData?.survey_uuid }));
    }
  };

  useEffect(() => {
    clearErrors('password2');
  }, [password1, password2]);

  useEffect(() => {
    if ((registerFetchStatus !== REQUEST_STATUSES.DONE || !user?.email) && token) {
      getAuthInfo();
    }
  }, [registerFetchStatus, user]);

  useEffect(() => {
    if (!user) return;
    checkIfVerificationRequired();
  }, [surveyDetailsFetchStatus, user]);

  return (
    <>
      <VerifyEmailCodeModal
        open={emailModalOpen.open}
        triggerRequest={emailModalOpen.triggerRequest}
        verifyCallback={verifyEmailCallback}
      />
      <VerifyPhoneCodeModal
        open={phoneModalOpen.open}
        triggerRequest={phoneModalOpen.triggerRequest}
        verifyCallback={verifyPhoneCallback}
      />
      <Container>
        <Center>
          <LogoSvg src={'/images/ivoting-logo.svg'} />
          <Form onSubmit={handleSubmit(onSubmit)}>
            <InputEmail
              formRef={register}
              fieldError={errors.email}
              validate={{
                required: true,
                pattern: emailPattern,
              }}
              formState={formState}
            />
            {accountForSurveyVotingData?.quire_to_vote_phone && (
              <InputPhone
                control={control}
                label={'Phone'}
                showRequiredStar={getIsPhoneRequired()}
                name={'phone'}
                formRef={register}
                fieldError={errors?.phone}
                formState={formState}
                validate={{ required: getIsPhoneRequired() }}
                borderColor={theme.colors.grayBorder}
                caretColor={theme.colors.grayBorder}
                labelColor={theme.colors.grayBorder}
                setValue={setValue}
              />
            )}
            <InputPassword
              label={'Password'}
              name={'password1'}
              formRef={register}
              fieldError={errors.password1}
              validate={{ required: true }}
              formState={formState}
            />
            <InputPassword
              label={'RepeatPassword'}
              name={'password2'}
              formRef={register}
              fieldError={errors.password2}
              validate={{ required: true }}
              formState={formState}
            />
            <Checkbox
              labelComponent={<LinkToTermsAndConditions />}
              name={'regulation'}
              formRef={register}
              formState={formState}
              validate={{ required: true }}
              uppercase={false}
              fieldError={errors.regulation}
              style={{ marginTop: '1rem' }}
            />
            <PrimarySmallButton
              type="submit"
              disabled={
                surveyDetailsFetchStatus === REQUEST_STATUSES.FETCHING ||
                registerFetchStatus === REQUEST_STATUSES.FETCHING
              }
            >
              <Trans>Further</Trans>
            </PrimarySmallButton>
          </Form>
        </Center>
      </Container>
    </>
  );
};

export default AuthorizedVotingRegistration;
