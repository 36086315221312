import React, { useState } from 'react';
import { colors, device } from '../theme';
import { history } from '../utils/history';
import styled from 'styled-components';
import { Group } from '../types/groups.types';
import ContactPersonButton from './ContactPersonButton';
import { getContactPersonsPath, getGroupsPath } from '../constants/paths.constants';
import EditIcon from '@material-ui/icons/Edit';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { CircleButton } from '../utils/StyledComponents/buttons';
import NumberBadge from './NumberBadge';

const DummyIcon = styled.div`
  font-size: 18px;
  padding-left: 5px;
  padding-right: 5px;
`;

const ContactPersonsButtonsContainer = styled.div`
  max-height: 200px;
  overflow-y: scroll;
  width: 90%;

  @media ${device.laptop} {
    max-width: 600px;
  }
  margin-bottom: 0.325rem;
`;

const Button: any = styled.div`
  padding: 0.875rem 1.325rem;
  margin: 0.375rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.lightBlue};
  border-radius: 50px;
  color: white;
  width: 90%;

  @media ${device.laptop} {
    max-width: 600px;
  }
`;

export const ActionButtons = styled.div`
  display: flex;
`;

export const ActionButton: any = styled.button`
  border-radius: 50%;
  background-color: ${(props: any) => (props.big ? colors.darkGreen : colors.darkBlue)};
  border: ${(props: any) => (props.withBorder ? '1px white solid' : 'none')};
  color: white;
  height: ${(props: any) => (props.big ? '3rem' : '2rem')};
  min-width: ${(props: any) => (props.autoWidth ? '2rem' : '')};
  width: ${(props: any) => (props.autoWidth ? 'auto' : props.big ? '3rem' : '2rem')};
  font-size: ${(props: any) => (props.big ? '2rem' : '0.875rem')};
  margin-left: ${(props: any) => (props.big ? '0' : '0.375rem')};
  &:hover {
    cursor: ${(props: any) => (props.noCursor ? '' : 'pointer')};
  }

  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg.MuiSvgIcon-root {
    height: 70%;
  }
`;

interface Props {
  group: Group;
  readOnly?: boolean;
  updateContactPerson?(): void;
  deleteContactPerson?(): void;
  leaveGroup?(config: { uuid: string }): void;
}

const GroupButton: React.FC<Props> = ({ group, readOnly, leaveGroup }) => {
  const [showContactPersons, setShowContactPersons] = useState<any>(false);

  const handleEditClick = () => {
    history.push(`${getGroupsPath()}/${group.uuid}`);
  };

  const handleAddClick = () => {
    history.push(`${getContactPersonsPath()}/new-contact?group=${group.id}`);
  };

  const handleLeaveGroupClick = () => {
    leaveGroup?.({ uuid: group.uuid });
  };

  const toggle = () => {
    setShowContactPersons((prev: any) => !prev);
  };

  return (
    <>
      <Button>
        {group.name}
        <ActionButtons>
          {typeof group.contacts_count === 'number' && (
            <NumberBadge number={group.contacts_count} />
          )}
          {leaveGroup && (
            <ActionButton type={'button'} onClick={handleLeaveGroupClick}>
              <ExitToAppIcon />
            </ActionButton>
          )}
          {!readOnly && !leaveGroup && (
            <ActionButton type={'button'} onClick={handleEditClick}>
              <EditIcon />
            </ActionButton>
          )}
          {!readOnly && !leaveGroup && (
            <ActionButton type={'button'} onClick={toggle}>
              {!showContactPersons ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </ActionButton>
          )}
        </ActionButtons>
      </Button>
      {showContactPersons && (
        <>
          <ContactPersonsButtonsContainer>
            {group.contacts.map((contact, key) => (
              <ContactPersonButton
                key={key}
                contact={contact}
                group={group}
                showEditButton={false}
                color={'white'}
              />
            ))}
          </ContactPersonsButtonsContainer>
          <CircleButton
            borderColor={colors.darkGreen}
            bgColor={colors.darkGreen}
            color={'#ffffff'}
            onClick={handleAddClick}
          >
            +
          </CircleButton>
        </>
      )}
    </>
  );
};

export default GroupButton;
