import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { postNewSurvey, updateNewSurveyData } from '../../../reducers/Surveys/surveys.actions';
import SurveyTypeHeader from '../../../components/SurveyTypeHeader';
import { history } from '../../../utils/history';
import { getCreateProjectPath, getSurveysPath } from '../../../constants/paths.constants';
import { AbsoluteDrawerBackButton } from '../../../components/DrawerBackButton';
import { Center, FlexContainer } from '../../../utils/StyledComponents/containers';
import SummarySettingsPreview from '../NewSurvey/SummarySettingsPreview';
import useProjectDataValidation from './utils/useProjectDataValidation';
import { ProjectStep1Schema, ProjectStep2Schema, ProjectStep3Schema } from './utils/validation';
import { FormLabel } from '../../../utils/StyledComponents/forms';
import { Trans } from 'react-i18next';
import SelectedGroups from '../../../components/SelectedGroups';
import { getCoordinatorGroups, getGroups } from '../../../reducers/Groups/groups.actions';
import { PrimarySmallButton } from '../../../utils/StyledComponents/buttons';
import { colors } from '../../../theme';
import { PrimarySmallSpinnerButton } from '../../../components/SpinnerButton';
import {
  FinalProjectType,
  ProjectAnswerInputsType,
  ProjectQuestionInputsType,
} from './utils/types';
import moment from 'moment';
import { Image, InputValue, Label, LabelValueContainer } from './Step4Components/StyledComponents';
import AnswerInputPreview from './Step4Components/AnswerInputPreview';
import SurveyShareModal from '../../../components/SurveyShareModal/SurveyShareModal';
import { REQUEST_STATUSES } from '../../../constants/requestStatuses.constants';
import { defaultProjectImage } from './utils/defaultValues';
import { InfoIcon } from '../../../components/Modal/ModalTitleIcons';
import SimpleModal from '../../../components/Modal/SimpleModal';

interface Props {
  newSurvey: FinalProjectType;
  groups: Array<any>;
  coordinatorGroups: Array<any>;
  postNewSurvey(data: any): void;
  postNewSurveyData: {
    data: any;
    fetchStatus: string;
    fetchError: any;
  };
}

const ProjectStep4: React.FC<Props> = (props) => {
  const { newSurvey, groups, coordinatorGroups, postNewSurvey, postNewSurveyData } = props;
  const surveySuccessfullyCreated = postNewSurveyData.fetchStatus === REQUEST_STATUSES.DONE;

  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);
  const [isAutomaticGroupCreationModalOpen, setIsAutomaticGroupCreationModalOpen] =
    useState<boolean>(true);

  useProjectDataValidation({
    data: newSurvey,
    schemas: [ProjectStep1Schema, ProjectStep2Schema, ProjectStep3Schema],
    notValidCallback: () => {
      history.replace(getCreateProjectPath({ step: 1 }));
    },
  });

  const onSubmit = () => {
    const getValidUrl = (url = '') => {
      let newUrl = window.decodeURIComponent(url);
      newUrl = newUrl.trim().replace(/\s/g, '');

      if (/^(:\/\/)/.test(newUrl)) {
        return `https${newUrl}`;
      }
      if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
        return `https://${newUrl}`;
      }

      return newUrl;
    };

    const getStartDate = (value: string, start_date: Date | undefined) => {
      switch (value) {
        case 'custom':
          return moment(start_date).format('YYYY-MM-DDTHH:mm:ss');
        case '15':
        case '30':
        case '60':
        case 'indefinitely':
          return moment().format('YYYY-MM-DDTHH:mm:ss');
      }
    };

    const getEndDate = (value: string, end_date: Date | undefined) => {
      switch (value) {
        case '15':
        case '30':
        case '60':
          return moment()
            .add(+value, 'minutes')
            .format('YYYY-MM-DDTHH:mm:ss');
        case 'custom':
          return moment(end_date).format('YYYY-MM-DDTHH:mm:ss');
        case 'indefinitely':
          return null;
      }
    };

    const questions = newSurvey?.questions?.map(
      (question: ProjectQuestionInputsType, questionIndex: number) => {
        return {
          question: question.question,
          min_required_answers: question.min_required_answers,
          max_required_answers: question.max_required_answers,
          answers: question.answers?.map((answer: ProjectAnswerInputsType, answerIndex: number) => {
            return {
              text: answer.text,
              project_data: {
                value: answer.project_data?.value,
                currency: answer.project_data?.currency,
                short_description: answer.project_data?.short_description,
              },
              link: !!answer.link ? getValidUrl(answer.link) : null,
              image: !!answer.image_id ? answer.image_id : null,
              is_default:
                newSurvey.default_answer &&
                question.selected_default_answer &&
                +question.selected_default_answer === answerIndex,
            };
          }),
        };
      }
    );

    const data = {
      // main
      survey_type: 'project',
      image: !!newSurvey.image_id ? newSurvey.image_id : null,
      name: newSurvey.name,
      voting_ordinance: !!newSurvey.voting_ordinance ? newSurvey.voting_ordinance : null,
      voting_card_instruction: !!newSurvey.voting_card_instruction
        ? newSurvey.voting_card_instruction
        : null,
      questions,

      // settings

      // time
      start_date: getStartDate(newSurvey.time?.value, newSurvey.time?.start_date),
      end_date: getEndDate(newSurvey.time?.value, newSurvey.time?.end_date),

      // type
      is_overt: true,

      // protocol
      is_protocol_label_nick: newSurvey.is_protocol_label_nick,
      is_protocol_label_name: newSurvey.is_protocol_label_name,

      // additional
      is_results_only_for_owner: newSurvey.is_results_only_for_owner,
      is_voting_card_vote: newSurvey.is_voting_card_vote,
      show_on_page: newSurvey.show_on_page,
      show_results_after_voted: newSurvey.show_results_after_voted,
      send_vote_confirmations: newSurvey.send_vote_confirmations,
      instruction_link: getValidUrl(newSurvey.instruction_link),

      // auth
      require_to_vote_email: newSurvey.require_to_vote_email,
      require_to_vote_phone: newSurvey.require_to_vote_phone,
      require_to_vote_qr: newSurvey.require_to_vote_qr,
      require_to_vote_name: newSurvey.require_to_vote_name,
      require_to_vote_address: newSurvey.require_to_vote_address,
      require_to_vote_min_age: newSurvey.require_to_vote_min_age_checkbox
        ? newSurvey.require_to_vote_min_age
        : null,

      // groups
      invited_groups: newSurvey.invited_groups,
    };

    postNewSurvey(data);
  };

  return (
    <>
      {!surveySuccessfullyCreated && (
        <AbsoluteDrawerBackButton
          isBackVisible={true}
          onClick={() => {
            history.push(getCreateProjectPath({ step: 3 }));
          }}
        />
      )}
      {shareModalOpen && surveySuccessfullyCreated && (
        <SurveyShareModal
          uuid={postNewSurveyData.data?.uuid}
          survey={postNewSurveyData.data}
          isOpen={shareModalOpen}
          setIsOpen={setShareModalOpen}
        />
      )}
      {surveySuccessfullyCreated && (
        <SimpleModal
          title={'Utworzono grupę'}
          text={`W wyniku opublikowania została pomyślnie utworzona grupa automatyczna o nazwie identycznej z nazwą opublikowanego głosowania: ${newSurvey.name}.`}
          open={isAutomaticGroupCreationModalOpen}
          setOpen={setIsAutomaticGroupCreationModalOpen}
          onButtonClick={() => setIsAutomaticGroupCreationModalOpen(false)}
          iconComponent={InfoIcon}
        />
      )}
      <FlexContainer>
        <Center>
          <SurveyTypeHeader type={'project'} />
          <LabelValueContainer>
            <Label>Nazwa głosowania</Label>
            <InputValue>{newSurvey.name}</InputValue>
          </LabelValueContainer>
          <LabelValueContainer>
            <Label>Zdjęcie projektu</Label>
            <Image
              bgImage={
                (newSurvey.image &&
                  newSurvey.image[0] &&
                  URL.createObjectURL(newSurvey.image[0])) ||
                defaultProjectImage
              }
            />
          </LabelValueContainer>
          {newSurvey.instruction_checkbox && (
            <>
              <LabelValueContainer>
                <Label>Nr zarządzenia bądź nr uchwały</Label>
                <InputValue>{newSurvey.voting_ordinance}</InputValue>
              </LabelValueContainer>
              <LabelValueContainer>
                <Label>Instrukcja głosowania</Label>
                <InputValue>{newSurvey.voting_card_instruction}</InputValue>
              </LabelValueContainer>
            </>
          )}
          {newSurvey?.questions?.map((question: any, questionIndex: number) => {
            return (
              <React.Fragment key={`question-${questionIndex}`}>
                <LabelValueContainer>
                  <Label>Rodzaj zadania {questionIndex + 1}</Label>
                  <InputValue>{question.question}</InputValue>
                </LabelValueContainer>
                <LabelValueContainer>
                  <Label>Zadania do wyboru</Label>
                  {question.answers.map((answer: any, answerIndex: number) => {
                    return (
                      <AnswerInputPreview
                        key={`question-${questionIndex}-answer-${answerIndex}`}
                        answer={answer}
                        is_default={
                          newSurvey.default_answer &&
                          +question.selected_default_answer === answerIndex
                        }
                      />
                    );
                  })}
                </LabelValueContainer>
              </React.Fragment>
            );
          })}
          <SummarySettingsPreview survey={newSurvey} type={'project'} />
          {newSurvey.invited_groups?.length > 0 && (
            <>
              <FormLabel formType={'survey'}>
                <Trans>InvitedGroups</Trans>
              </FormLabel>
              <SelectedGroups
                selectedGroups={newSurvey.invited_groups}
                getGroups={getGroups}
                getCoordinatorGroups={getCoordinatorGroups}
                groups={groups}
                coordinatorGroups={coordinatorGroups}
              />
            </>
          )}
          {!surveySuccessfullyCreated && (
            <>
              <PrimarySmallButton
                type={'button'}
                onClick={() => {
                  history.push(getCreateProjectPath({ step: 1 }));
                }}
              >
                <Trans>Edit</Trans>
              </PrimarySmallButton>
              <PrimarySmallSpinnerButton
                isSpinning={postNewSurveyData.fetchStatus === REQUEST_STATUSES.FETCHING}
                onClick={onSubmit}
                borderColor={colors.darkGreen}
                bgColor={colors.darkGreen}
                color={'#ffffff'}
                style={{ marginTop: '24px' }}
              >
                <Trans>Opublikuj</Trans>
              </PrimarySmallSpinnerButton>
            </>
          )}

          {surveySuccessfullyCreated && (
            <>
              {!postNewSurveyData.data?.is_closed_vote && (
                <PrimarySmallButton onClick={() => setShareModalOpen(true)}>
                  <Trans>ShareVoting</Trans>
                </PrimarySmallButton>
              )}
              <PrimarySmallButton
                onClick={() => history.push(`${getSurveysPath()}`)}
                style={{ marginTop: '24px' }}
              >
                <Trans>Further</Trans>
              </PrimarySmallButton>
            </>
          )}
        </Center>
      </FlexContainer>
    </>
  );
};

function mapStateToProps(state: any, otherProps: RouteComponentProps) {
  return {
    newSurvey: state.surveys.newSurvey,
    groups: state.groups.list,
    coordinatorGroups: state.groups.coordinators.list.items,
    postNewSurveyData: state.surveys.postNewSurveyData,
  };
}

export default withRouter(
  connect(mapStateToProps, { updateNewSurveyData, getGroups, getCoordinatorGroups, postNewSurvey })(
    ProjectStep4
  )
);
