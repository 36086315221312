import React from 'react';
import { Trans } from 'react-i18next';
import ErrorMessage from './ErrorMessage';
import { FormTypes } from '../../types/form.types';
import i18next from 'i18next';
import { Input, PrimaryLabel } from '../../utils/uiComponents';
import { emailPattern } from '../../constants/patterns.constants';
import { FormInput, FormLabel } from '../../utils/StyledComponents/forms';

interface EmailFormType extends FormTypes {
  customErrorMessage?: string | undefined | boolean;
  final?: boolean;
}

const InputEmail: React.FC<EmailFormType> = ({
  label = 'Email',
  name = 'email',
  type = 'email',
  placeholder = '',
  formRef,
  fieldError,
  customErrorMessage,
  validate = {},
  formState,
  defaultValue = '',
  borderColor,
  color,
  caretColor,
  formType,
  final,
}) => {
  return (
    <>
      <FormLabel formType={formType} color={color}>
        <Trans>{label}</Trans>
        {validate.required ? '*' : ''}
      </FormLabel>
      <FormInput
        type={type}
        {...formRef(name, { ...validate, pattern: emailPattern })}
        placeholder={i18next.t(placeholder)}
        isDirty={formState.dirtyFields[name]}
        caretColor={caretColor}
        borderColor={borderColor}
        color={color}
        defaultValue={defaultValue}
        formType={formType}
      />
      <ErrorMessage
        fieldError={fieldError}
        validate={validate}
        customErrorMessage={typeof customErrorMessage === 'string' ? customErrorMessage : undefined}
      />
    </>
  );
};

export default InputEmail;
