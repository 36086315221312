import React, { useEffect, useState } from 'react';
import { SurveyAnswer, SurveyQuestion } from '../../types/surveys.types';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import ErrorMessage from '../Forms/ErrorMessage';
import { FormLabel } from '../../utils/StyledComponents/forms';
import { colors } from '../../theme';
import { Input } from '@material-ui/core';

const InfoText = styled.span`
  color: ${colors.gray};
  font-size: 15px;
`;

const getImageBorderColor = (props: any) => {
  if (props.borderColor) return props.borderColor;
  if (props.disabled) return '#0A5057';
  if (props.isDirty || props.defaultValue) return '#373737';
  if (props.selected) return colors.lightBlue;
  return '#CACACA';
};

const AnswerContainer: any = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  ${(props: any) =>
    props.vote &&
    `&:hover {
      cursor: pointer;
    }`}
`;

const FreeAnswerContainer: any = styled.div`
  display: flex;
  flex-direction: column;
`;

const Image: any = styled.div`
  margin-top: 0.625rem;
  height: 100px;
  width: 100px;
  border: ${(props: any) => (props.selected ? '5px' : '2px')} solid
    ${(props: any) => getImageBorderColor(props)};
  border-radius: 10px;
  background: ${(props: any) => (props.bgImage ? `url(${props.bgImage})` : 'transparent')};
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  ${(props: any) =>
    props.vote &&
    `&:hover {
      cursor: pointer;
    }`}
`;

const Square: any = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  height: 20px;
  width: 20px;
  background: ${(props: any) => (props.selected ? '#16BECF' : 'transparent')};
  border: 3px solid #16becf;
  border-radius: 5px;

  :after {
    text-transform: lowercase;
    line-height: 1px;
    content: 'x';
    color: #ffffff;
    display: ${(props: any) => (props.selected && props.showX ? 'block' : 'none')};
  }
`;

interface Props {
  question: SurveyQuestion;
  vote?: boolean;
  error?: string;
  freeAnswerError?: string;
  defaultAnswer?: boolean;
  showFreeAnswers?: boolean;

  selectedAnswers?: Array<SurveyAnswer>;
  selectedAnswersCountForQuestion?: number;
  surveyType: string;

  setSelectedAnswers?(answer: SurveyAnswer): void;

  changeFreeAnswerValue?(answer: SurveyAnswer, value: string): void;
}

const QuestionPreview: React.FC<Props> = ({
  question,
  vote = false,
  setSelectedAnswers,
  selectedAnswers,
  error,
  defaultAnswer = false,
  showFreeAnswers = true,
  changeFreeAnswerValue,
  selectedAnswersCountForQuestion,
  surveyType,
  freeAnswerError,
}) => {
  const [freeAnswerValue, setFreeAnswerValue] = useState<string>('');
  const [questionData, setQuestionData] = useState<SurveyQuestion>(() => {
    if (question.hasOwnProperty('is_free_answers') && question.is_free_answers && showFreeAnswers) {
      question.answers.push({
        is_free_answer: true,
        question_id: question.id,
        text: '',
        is_default: false,
        image: null,
        id: `${question.id}-${question.answers.length}`,
      });
    }
    return question;
  });

  const handleOnChange = (answer: SurveyAnswer, value: any) => {
    setFreeAnswerValue(value);
    changeFreeAnswerValue && changeFreeAnswerValue(answer, value);
  };

  const isSelected = (answer: SurveyAnswer) => {
    return (
      (answer.is_default && !vote) ||
      (selectedAnswers &&
        selectedAnswers.find((selectedAnswer) => selectedAnswer.id === answer.id) &&
        vote) ||
      (!defaultAnswer && !vote)
    );
  };

  return (
    <>
      <FormLabel formType={'survey'}>
        <Trans>Question</Trans>
      </FormLabel>
      {typeof questionData.question === 'string' && questionData.question}
      <div style={{ marginTop: '2rem' }}>
        {questionData?.answers?.map((answer, key) => {
          return (
            <React.Fragment key={key}>
              {answer.image && (
                <div key={key} style={{ textAlign: 'center' }}>
                  <Image
                    bgImage={answer.image.image || URL.createObjectURL(answer.image[0])}
                    selected={
                      selectedAnswers?.find((selectedAnswer) => selectedAnswer.id === answer.id) &&
                      vote
                    }
                    onClick={() => {
                      if (answer.id && setSelectedAnswers && vote) setSelectedAnswers(answer);
                    }}
                    vote={vote}
                  />
                  {answer.text}
                </div>
              )}
              {!answer.image && (
                <>
                  <AnswerContainer
                    vote={vote}
                    onClick={() => {
                      if (answer.id && vote && setSelectedAnswers)
                        setSelectedAnswers({
                          ...answer,
                          text: answer.is_free_answer ? freeAnswerValue : answer.text,
                        });
                    }}
                  >
                    {answer.hasOwnProperty('is_free_answer') && answer.is_free_answer ? (
                      <>
                        {' '}
                        <Square selected={isSelected(answer)} showX={vote} />
                        <FreeAnswerContainer>
                          {'Odpowiedź swobodna:'}
                          <Input
                            onChange={(e) => handleOnChange(answer, e.target.value)}
                            value={freeAnswerValue}
                            name={'answer'}
                            onClick={(e: any) => {
                              e.stopPropagation();
                            }}
                          />
                        </FreeAnswerContainer>
                      </>
                    ) : (
                      <>
                        {' '}
                        <Square selected={isSelected(answer)} showX={vote} /> {answer.text}
                      </>
                    )}
                  </AnswerContainer>
                  {answer.hasOwnProperty('is_free_answer') &&
                    answer.is_free_answer &&
                    freeAnswerError && (
                      <ErrorMessage fieldError={undefined} customErrorMessage={freeAnswerError} />
                    )}
                </>
              )}
            </React.Fragment>
          );
        })}
        {surveyType === 'extended' && (
          <InfoText>
            {!vote && question.is_free_answers && `Pytanie z odpowiedzią swobodną`}
            <br />
            Minimalna liczba wymaganych odpowiedzi {question.min_required_answers}
            <br />
            Maksymalna liczba wymaganych odpowiedzi {question.max_required_answers}
            <br />
            {vote &&
              selectedAnswersCountForQuestion !== undefined &&
              `Liczba udzielonych odpowiedzi ${selectedAnswersCountForQuestion}`}
          </InfoText>
        )}
        {error && <ErrorMessage fieldError={undefined} customErrorMessage={error} />}
      </div>
    </>
  );
};

export default QuestionPreview;
