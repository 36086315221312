export const UPDATE_SURVEYS_LIST = 'UPDATE_SURVEYS_LIST';
export const RESET_SURVEYS_LIST = 'RESET_SURVEYS_LIST';

export const UPDATE_NEW_SURVEY_DATA = 'UPDATE_NEW_SURVEY_DATA';
export const RESET_NEW_SURVEY_DATA = 'RESET_NEW_SURVEY_DATA';

export const UPDATE_SURVEY_DETAIL = 'UPDATE_SURVEY_DETAIL';
export const UPDATE_SURVEY_DETAIL_FETCH_STATUS = 'UPDATE_SURVEY_DETAIL_FETCH_STATUS';
export const UPDATE_SURVEY_DETAIL_FETCH_ERROR = 'UPDATE_SURVEY_DETAIL_FETCH_ERROR';
export const RESET_SURVEY_DETAIL = 'RESET_SURVEY_DETAIL';
export const SET_NEW_CONFIRMED_SURVEY = 'SET_NEW_CONFIRMED_SURVEY';

export const SET_VOTE_FETCH_STATUS = 'SET_VOTE_FETCH_STATUS';
export const SET_VOTE_FREE_ANSWERS_FETCH_STATUS = 'SET_VOTE_FREE_ANSWERS_FETCH_STATUS';
export const SET_VOTE_FREE_ANSWERS_FETCH_ERROR = 'SET_VOTE_FREE_ANSWERS_FETCH_ERROR';
export const SET_VOTE_FETCH_ERROR = 'SET_VOTE_FETCH_ERROR';
export const UPDATE_REQ_FOR_SURVEY = 'UPDATE_REQ_FOR_SURVEY';
export const CLEAR_REQ_FOR_SURVEY = 'CLEAR_REQ_FOR_SURVEY';
export const UPDATE_REQ_FOR_SURVEY_FETCH_STATUS = 'UPDATE_REQ_FOR_SURVEY_FETCH_STATUS';
export const RESET_REQ_FOR_SURVEY = 'RESET_REQ_FOR_SURVEY';

export const SET_POST_NEW_SURVEY_DATA = 'SET_POST_NEW_SURVEY_DATA';
export const SET_POST_NEW_SURVEY_FETCH_STATUS = 'SET_POST_NEW_SURVEY_FETCH_STATUS';
export const SET_POST_NEW_SURVEY_FETCH_ERROR = 'SET_POST_NEW_SURVEY_FETCH_ERROR';

export const RESET_NEW_PROJECT_DATA = 'RESET_NEW_PROJECT_DATA';
export const RESET_VOTE_STATUS = 'RESET_VOTE_STATUS';
export const RESET_VOTE_STATE = 'RESET_VOTE_STATE';
