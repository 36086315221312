import React, { useEffect } from 'react';
import Checkbox from '../../../../components/Forms/Checkbox';
import { FormLabel } from '../../../../utils/StyledComponents/forms';
import { useFormState, useWatch } from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types/form';
import ErrorMessage from '../../../../components/Forms/ErrorMessage';
import i18next from 'i18next';
import { Trans } from 'react-i18next';

interface Props {
  formMethods: UseFormReturn;
}

const ProtocolType: React.FC<Props> = (props) => {
  const { register, control, setValue } = props.formMethods;
  const formState = useFormState({ control });
  const { errors } = formState;

  return (
    <>
      <FormLabel formType={'survey'}>
        <Trans>ProtocolType</Trans>
      </FormLabel>
      <div style={{ width: '90%' }}>
        <Checkbox
          label={i18next.t('ProtocolLabelNick')}
          name={'is_protocol_label_nick'}
          formRef={register}
          formState={formState}
          popoverText={i18next.t('ProtocolLabelPopoverNick')}
        />
        <Checkbox
          label={i18next.t('ProtocolLabelName')}
          name={'is_protocol_label_name'}
          formRef={register}
          formState={formState}
          popoverText={i18next.t('ProtocolLabelPopoverName')}
        />
        <Checkbox
          label={i18next.t('ProtocolLabelAnonymized')}
          name={'is_protocol_anonymized'}
          formRef={register}
          formState={formState}
          popoverText={i18next.t('ProtocolLabelPopoverAnonymized')}
        />
        <ErrorMessage fieldError={errors.protocolType} />
      </div>
    </>
  );
};

export default ProtocolType;
