import React from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import InputTextarea from '../../../../components/Forms/InputTextarea';
import { colors } from '../../../../theme';
import AnswersFieldArray from './AnswersFieldArray';
import getFieldError from '../../../../utils/getFieldError';
import { getProjectQuestionDefaultValue } from '../utils/defaultValues';
import { PrimarySmallButton } from '../../../../utils/StyledComponents/buttons';
import styled from 'styled-components';
import ErrorMessage from '../../../../components/Forms/ErrorMessage';
import { UseFormReturn } from 'react-hook-form/dist/types';

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 3rem;
  width: 100%;
  align-items: center;

  button {
    margin: 0;
  }
`;

interface Props {
  name: string;
  formMethods: UseFormReturn;
  schema?: any;
}

const QuestionsFieldArray: React.FC<Props> = (props) => {
  const { name, schema, formMethods } = props;
  const { control, register, formState, setValue } = formMethods;
  const { errors } = formState;
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  return (
    <>
      {fields.map((field, index) => (
        <React.Fragment key={field.id}>
          <InputTextarea
            label={'Rodzaj zadania'}
            name={`${name}.${index}.question`}
            formRef={register}
            formState={formState}
            formType={'survey'}
            fieldError={getFieldError(errors, `${name}.${index}.question`)}
            control={control}
            schema={schema}
            setValue={setValue}
          />

          <AnswersFieldArray
            formMethods={formMethods}
            name={`${name}.${index}.answers`}
            schema={schema}
          />
          <ButtonsContainer>
            {fields.length > 1 && (
              <PrimarySmallButton
                type={'button'}
                color={colors.red}
                bgColor={'transparent'}
                borderColor={colors.red}
                onClick={() => remove(index)}
                fullWidth={true}
              >
                Usuń powyższy rodzaj zadania
              </PrimarySmallButton>
            )}
            {fields.length === index + 1 && (
              <PrimarySmallButton
                type={'button'}
                onClick={() => append(getProjectQuestionDefaultValue())}
                color={colors.darkGreen}
                bgColor={'transparent'}
                borderColor={colors.darkGreen}
                fullWidth={true}
              >
                Dodaj kolejny rodzaj zadania
              </PrimarySmallButton>
            )}
          </ButtonsContainer>
        </React.Fragment>
      ))}
    </>
  );
};

export default QuestionsFieldArray;
