import React, { useState, useEffect } from 'react';
import { LogoSvg } from '../../utils/uiComponents';
import { Container } from '../../utils/StyledComponents/containers';
import { useHistory } from 'react-router-dom';
import { REQUEST_STATUSES } from '../../constants/requestStatuses.constants';
import SimpleModal from '../../components/Modal/SimpleModal';
import { EmailTitleIcon } from '../../components/Modal/ModalTitleIcons';
import { getMyProfilePath } from '../../constants/paths.constants';

interface Props {
  uuid: string;
  verifyEmail(data: { key: string }): void;
  verifyEmailFetchStatus: string;
}

const modalConfig = {
  title: 'modalVerifyEmailTitle',
  text: 'modalVerifyEmailText',
};

const VerifyEmail: React.FC<Props> = ({ uuid, verifyEmail, verifyEmailFetchStatus }) => {
  const history = useHistory();

  const [isModalOpen, setIsModalOpen] = useState<any>(false);

  useEffect(() => {
    verifyEmail({ key: uuid });
  }, [uuid, verifyEmail]);

  useEffect(() => {
    if (REQUEST_STATUSES.DONE === verifyEmailFetchStatus) {
      setIsModalOpen(true);
      setTimeout(() => {
        nextRoute();
      }, 5 * 1000);
    }
  }, [verifyEmailFetchStatus]);

  const nextRoute = () => history.push(getMyProfilePath());

  return (
    <Container>
      <SimpleModal
        {...modalConfig}
        open={isModalOpen || true}
        iconComponent={EmailTitleIcon}
        onButtonClick={nextRoute}
      />

      <LogoSvg src={'/images/ivoting-logo.svg'} />
    </Container>
  );
};

export default VerifyEmail;
