import React, { useEffect, useState } from 'react';
import { ButtonsWrapper, ModalButton, ModalTitle } from '../../../components/Modal/ModalPartials';
import { Trans } from 'react-i18next';
import { useForm, useWatch } from 'react-hook-form';
import { Form } from '../../../utils/StyledComponents/containers';
import { REQUEST_STATUSES } from '../../../constants/requestStatuses.constants';
import { showNotification } from '../../../utils/taost.config';
import SelectGroup from '../../../components/SelectGroup';
import styled from 'styled-components';
import MultiselectEmail from '../../../components/MultiselectEmail';
import ErrorMessage from '../../../components/Forms/ErrorMessage';

const StyledTitle = styled(ModalTitle)`
  margin-top: 10px;
`;

const ErrorMessageContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const checkIfNoEditableGroups = ({ availableGroups }: any) =>
  !availableGroups ||
  availableGroups?.length === 0 ||
  availableGroups.filter((group: any) => !group.read_only).length < 1;

interface Props {
  setOpen(flag: boolean): void;
  inviteMore(data: { emails: Array<string>; group_ids: Array<number> }): void;
  setInviteMoreFetchStatus(status: string | null): void;

  inviteMoreFetchStatus: string;
  availableGroups: Array<any>;
}

type Inputs = {
  email: string;
  emails: string;
  groups: any;
};

const InviteMoreModalContent: React.FC<Props> = ({
  setOpen,
  inviteMore,
  inviteMoreFetchStatus,
  availableGroups,
  setInviteMoreFetchStatus,
}) => {
  const [newEmails, setNewEmails] = useState<Array<string>>([]);
  // todo: tmp workaround, change when multiselect done
  const [selectedGroups, setSelectedGroups] = useState<Array<number>>([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    formState,
    setValue,
    setError,
    clearErrors,
    reset,
    control,
  } = useForm<Inputs>();

  const watchEmail = useWatch({
    name: 'email',
    defaultValue: '',
    control,
  });

  useEffect(() => {
    if (!!errors.groups) clearErrors('groups');
  }, [selectedGroups]);

  useEffect(() => {
    if (!!errors.email) clearErrors('email');
    if (!!errors.emails) clearErrors('emails');
  }, [watchEmail]);

  useEffect(() => {
    if (!!errors.emails) clearErrors('emails');
  }, [newEmails]);

  useEffect(() => {
    if (inviteMoreFetchStatus === REQUEST_STATUSES.DONE) {
      handleClose();
      showNotification({
        message: 'Pomyślnie zaproszono osoby.',
        type: 'success',
      });
    }
    if (inviteMoreFetchStatus === REQUEST_STATUSES.ERROR) {
      showNotification({
        message: 'Ups, coś poszło nie tak.',
        type: 'error',
      });
    }
  }, [inviteMoreFetchStatus]);

  const handleClose = () => {
    setOpen(false);
    setNewEmails([]);
    setSelectedGroups([]);
    reset();
    setInviteMoreFetchStatus(REQUEST_STATUSES.NULL);
  };

  // const getSelectedGroups = () => {
  //   return getValues('invited_groups')
  //     ? // @ts-ignore
  //       getValues('invited_groups').map((id: any) => ({ id }))
  //     : [];
  // };
  // const setSelectedGroups = (values: any) => {
  //   setValue('invited_groups', values);
  // };

  const onSubmit = (data: Inputs) => {
    let isValid = true;
    if (newEmails.length === 0) {
      isValid = false;
      setError('emails', { type: 'required' });
    }
    if (selectedGroups.length === 0) {
      isValid = false;
      setError('groups', { type: 'required' });
    }
    if (isValid) {
      inviteMore({ emails: newEmails, group_ids: selectedGroups });
    }
  };

  const areNoEditableGroups = checkIfNoEditableGroups({ availableGroups });

  const formGlobalError = areNoEditableGroups ? 'NoGroupsToInvite' : undefined;

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {!areNoEditableGroups && (
        <>
          <MultiselectEmail errors={errors} emails={newEmails} setEmails={setNewEmails} />
        </>
      )}
      <StyledTitle>
        <Trans>Zaproś do grupy</Trans>
      </StyledTitle>

      <SelectGroup
        groups={availableGroups}
        selectedGroups={selectedGroups}
        setSelectedGroups={setSelectedGroups}
        text={false}
      />

      <ErrorMessage fieldError={errors?.groups} customErrorMessage={formGlobalError} />

      <ButtonsWrapper>
        <ModalButton
          type={'submit'}
          full
          disabled={areNoEditableGroups || inviteMoreFetchStatus === REQUEST_STATUSES.FETCHING}
        >
          <Trans>Zaproś</Trans>
        </ModalButton>
        <ModalButton type={'button'} full cancel onClick={handleClose}>
          <Trans>Anuluj</Trans>
        </ModalButton>
      </ButtonsWrapper>
    </Form>
  );
};

export default InviteMoreModalContent;
