import React, { useEffect, useState } from 'react';
import { Button } from '../../../utils/uiComponents';
import SurveyTypeHeader from '../../../components/SurveyTypeHeader';
import { Trans } from 'react-i18next';
import { SurveyData, SurveyType } from '../../../types/surveys.types';
import QuestionPreview from '../../../components/SurveyComponents/QuestionPreview';
import { closeVoting } from '../../../reducers/Surveys/surveys.actions';
import { history } from '../../../utils/history';
import { getSurveyPath } from '../../../constants/paths.constants';
import {
  Center,
  FlexContainer,
  SurveyListButtonTime,
} from '../../../utils/StyledComponents/containers';
import { PrimarySmallButton } from '../../../utils/StyledComponents/buttons';
import { FormLabel } from '../../../utils/StyledComponents/forms';
import Countdown from 'react-countdown';
import styled from 'styled-components';
import SimpleModal from '../../../components/Modal/SimpleModal';
import InviteMoreModalContent, { checkIfNoEditableGroups } from './InviteMoreModalContent';
import SurveyShareModal from '../../../components/SurveyShareModal/SurveyShareModal';
import Tooltip from '@material-ui/core/Tooltip';

const TimerContainer = styled.div`
  font-weight: bold;
  font-size: 32px;
  text-align: center;
`;

const Uppercase = styled.span`
  text-transform: uppercase;
`;

export const PrimarySmallButtonWithTooltip = (props: any) => {
  const { disabledInfo, disabled } = props;

  return disabled ? (
    <Tooltip title={disabled ? disabledInfo : null}>
      <PrimarySmallButton {...props} />
    </Tooltip>
  ) : (
    <PrimarySmallButton {...props} />
  );
};

interface Props {
  uuid: string;
  survey: SurveyType;
  inviteMoreFetchStatus: string;

  getSurveyDetail(uuid: string): void;
  resetSurveyDetail(): void;
  setInviteMoreFetchStatus(status: string | null): void;
  inviteMore: any;
}

const SurveyDetail: React.FC<Props> = ({
  uuid,
  getSurveyDetail,
  survey,
  resetSurveyDetail,
  inviteMore,
  inviteMoreFetchStatus,
  setInviteMoreFetchStatus,
}) => {
  const [inviteModalOpen, setInviteModalOpen] = useState<any>(false);
  const [shareModalOpen, setShareModalOpen] = useState<any>(false);

  useEffect(() => {
    if (uuid) {
      getSurveyDetail(uuid);
    }
  }, [uuid]);

  useEffect(() => {
    return () => {
      resetSurveyDetail();
    };
  }, [uuid]);

  useEffect(() => {
    if (survey) {
      if (!survey.has_ended && !survey.is_own && !survey.is_voted)
        history.push(`${getSurveyPath()}/${uuid}/vote`);
      if (survey.has_ended || survey.show_results_after_voted)
        history.push(`${getSurveyPath()}/${uuid}/results`);
    }
  }, [survey]);

  const handleCloseVoting = () => {
    closeVoting(uuid).then(() => {
      history.push(`${getSurveyPath()}/${uuid}/results`);
    });
  };

  const countdownRenderer = ({ days, hours, minutes, seconds, completed }: any) => {
    if (completed) {
      return <></>;
    } else {
      return (
        <div>
          ({days}d:{hours}h:{minutes}m:{seconds}s)
        </div>
      );
    }
  };

  const handleShare = () => {
    setShareModalOpen(true);
  };

  const handleInvite = () => {
    setInviteModalOpen(true);
  };

  const areNoEditableGroups = checkIfNoEditableGroups({
    availableGroups: survey?.invited_groups || [],
  });

  return (
    <FlexContainer>
      {inviteModalOpen && (
        <SimpleModal
          open={inviteModalOpen}
          setOpen={setInviteModalOpen}
          title={'Zaproś'}
          text={'Możesz zaprosić kolejne osoby do głosowania wpisując ich adresy email.'}
          customButtonComponent={<></>}
        >
          <InviteMoreModalContent
            setOpen={setInviteModalOpen}
            availableGroups={survey.invited_groups}
            inviteMore={inviteMore}
            inviteMoreFetchStatus={inviteMoreFetchStatus}
            setInviteMoreFetchStatus={setInviteMoreFetchStatus}
          />
        </SimpleModal>
      )}
      {shareModalOpen && (
        <SurveyShareModal
          uuid={uuid}
          survey={survey}
          isOpen={shareModalOpen}
          setIsOpen={setShareModalOpen}
        />
      )}
      <Center>
        {survey && survey.survey_type && !survey.has_ended && (
          <>
            <SurveyTypeHeader type={survey.survey_type} />
            {survey.end_date && (survey.is_voted || survey.is_own) && (
              <TimerContainer>
                <Uppercase>Sprawdź wyniki za</Uppercase>
                <Countdown
                  date={survey.end_date}
                  renderer={countdownRenderer}
                  onComplete={() => history.push(`${getSurveyPath()}/${survey.uuid}/results`)}
                />
              </TimerContainer>
            )}
            <FormLabel formType={'survey'}>
              <Trans>{survey.survey_type === 'extended' ? 'SurveyTitle' : 'PollTitle'}</Trans>
            </FormLabel>
            {survey.name}
            {survey.questions?.map((question, key) => {
              return (
                <QuestionPreview
                  question={question}
                  key={key}
                  showFreeAnswers={false}
                  surveyType={survey.survey_type}
                />
              );
            })}
            {survey.is_own && (
              <>
                <PrimarySmallButtonWithTooltip
                  onClick={handleInvite}
                  disabled={false}
                  disabledInfo={<Trans>NoGroupsToInvite</Trans>}
                >
                  <Trans>Invite</Trans>
                </PrimarySmallButtonWithTooltip>
                {!survey.is_closed_vote && (
                  <PrimarySmallButton style={{ marginTop: '1rem' }} onClick={handleShare}>
                    <Trans>ShareVoting</Trans>
                  </PrimarySmallButton>
                )}
                <PrimarySmallButton style={{ marginTop: '1rem' }} onClick={handleCloseVoting}>
                  <Trans>End voting</Trans>
                </PrimarySmallButton>
              </>
            )}
          </>
        )}
      </Center>
    </FlexContainer>
  );
};

export default SurveyDetail;
