import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import InputText from '../../../components/Forms/InputText';
import { useForm, useWatch } from 'react-hook-form';
import { defaultErrorsType, Inputs } from '../ExtendedSurvey/extendedSurvey.config';
import SurveyTypeHeader from '../../../components/SurveyTypeHeader';
import { SurveyData, SurveyTypeEnum } from '../../../types/surveys.types';
import { PrimarySmallButton } from '../../../utils/StyledComponents/buttons';
import { Form } from '../../../utils/StyledComponents/containers';
import QuestionsFieldArray from '../ExtendedSurvey/QuestionsFieldArray';
import Checkbox from '../../../components/Forms/Checkbox';
import { getDefaultValues, getErrors } from './newSurveyExtended.helpers';

interface Props {
  newSurvey: SurveyData;
  updateNewSurveyData(data: any): void;
  setStep(stepNumber: number): void;
}

const CreateExtendedSurvey: React.FC<Props> = ({ updateNewSurveyData, setStep, newSurvey }) => {
  const [errors, setErrors] = useState<defaultErrorsType>(getErrors(newSurvey.questions || []));
  const { control, register, handleSubmit, getValues, formState, setValue, watch } =
    useForm<Inputs>({
      defaultValues: getDefaultValues(newSurvey),
    });

  const watchQuestions = useWatch({
    name: 'questions',
    control,
  });
  const watchName = useWatch({
    name: 'name',
    defaultValue: getDefaultValues(newSurvey).name,
    control,
  });

  const watchDefaultAnswerCheckbox = useWatch({ name: 'default_answer', control });

  const areDefaultAnswersValid = (data: Inputs) => {
    let answersWithDefaultCount: number = 0;
    data.questions.forEach((question, questionIndex) => {
      let isAnyDefault = question.answers?.findIndex((answer) => answer.value);
      if (isAnyDefault >= 0) answersWithDefaultCount++;
    });
    return answersWithDefaultCount === 0 || answersWithDefaultCount === data.questions.length;
  };

  const isSurveyValid = (data: Inputs) => {
    let isValid = true;
    if (data.name === '') {
      isValid = false;
      setErrors((prevState: any) => ({
        ...prevState,
        name: { type: 'required' },
      }));
    }
    // if (!areDefaultAnswersValid(data)) {
    //   isValid = false;
    //   showNotification({
    //     message:
    //       'Domyślna odpowiedź musi być ustawiona dla każdego pytania lub wyłączona dla wszyskich pytań.',
    //     type: 'error',
    //   });
    // }
    data.questions.forEach((question, questionIndex) => {
      if (question.question === '') {
        isValid = false;
        setErrors((prevState) => ({
          ...prevState,
          questions: prevState.questions.map((question, index) => {
            if (index !== questionIndex) return question;
            else
              return {
                ...question,
                question: { type: 'required' },
              };
          }),
        }));
      }
      if (!question?.answers?.length) {
        isValid = false;
        setErrors((prevState) => ({
          ...prevState,
          questions: prevState.questions.map((question, index) => {
            if (index !== questionIndex) return question;
            return {
              ...question,
              answers: { type: 'requiredAddAtLeastTwo' },
            };
          }),
        }));
      } else if (
        question.answers &&
        question.answers.map((answer) => answer.text).filter((value) => value).length < 2
      ) {
        isValid = false;
        setErrors((prevState) => ({
          ...prevState,
          questions: prevState.questions.map((question, index) => {
            if (index !== questionIndex) return question;
            return {
              ...question,
              answers: { type: 'requiredAtLeastTwo' },
            };
          }),
        }));
      } else if (data.default_answer) {
        let isAnswerSelected = false;
        question.answers.forEach((answer) => {
          isAnswerSelected = isAnswerSelected || answer.value;
        });
        if (!isAnswerSelected) {
          isValid = false;
          setErrors((prevState) => ({
            ...prevState,
            questions: prevState.questions.map((question, index) => {
              if (index !== questionIndex) return question;
              return {
                ...question,
                answers: { type: 'selectDefault' },
              };
            }),
          }));
          return;
        }
      }
    });
    return isValid;
  };

  const onSubmit = (data: Inputs) => {
    if (isSurveyValid(data)) {
      let calcBasedOnDefault = false;
      let survey: any = {
        name: data.name,
        questions: data.questions.map((question, questionIndex) => {
          let answers: Array<{ text: string }> = [];
          question?.answers?.forEach((answer, answerIndex) => {
            let answerToPush: any = { text: answer.text };
            if (answer.value && watchDefaultAnswerCheckbox) {
              answerToPush.is_default = true;
              calcBasedOnDefault = true;
            }
            answers.push(answerToPush);
          });
          return {
            question: question.question,
            answers: answers,
            is_free_answers: question.is_free_answers,
            min_required_answers: question.min_required_answers,
            max_required_answers: question.max_required_answers,
          };
        }),
        survey_type: 'extended',
        default_answer: data.default_answer,
      };
      survey.calculate_votes_based_on_default = calcBasedOnDefault;
      updateNewSurveyData(survey);
      setStep(2);
    }
  };

  useEffect(() => {
    if (watchName !== '' && errors.name)
      setErrors((prevState: any) => ({
        ...prevState,
        name: undefined,
      }));

    watchQuestions &&
      watchQuestions.forEach((question, questionIndex) => {
        if (question.question !== '' && errors.questions[questionIndex].question)
          setErrors((prevState) => ({
            ...prevState,
            questions: prevState.questions.map((question, index) => {
              if (index !== questionIndex) return question;
              else
                return {
                  ...question,
                  question: undefined,
                };
            }),
          }));
        if (
          question &&
          question.answers &&
          question.answers.map((answer) => answer.value).filter((value) => value).length > 1 &&
          errors.questions[questionIndex].answers
        )
          setErrors((prevState) => ({
            ...prevState,
            questions: prevState.questions.map((question, index) => {
              if (index !== questionIndex) return question;
              else
                return {
                  ...question,
                  answers: undefined,
                };
            }),
          }));
      });
  }, [watchQuestions, watchName, errors.name, errors.questions]);

  useEffect(() => {
    if (!watchDefaultAnswerCheckbox) {
      if (errors.questions) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          questions: prevErrors.questions.map((question) => {
            let error = {
              ...question,
            };
            if (error.answers?.type === 'selectDefault') delete error.answers;
            return error;
          }),
        }));
      }
    }
  }, [watchDefaultAnswerCheckbox]);

  return (
    <>
      <SurveyTypeHeader type={SurveyTypeEnum.extended} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputText
          label={'SurveyTitle'}
          name={'name'}
          formRef={register}
          fieldError={errors.name}
          formState={formState}
          formType={'survey'}
          showRequiredStar={false}
        />

        <div style={{ width: '90%' }}>
          <Checkbox
            label={'Uwzględnij brak odpowiedzi osoby uprawnionej do głosowania w wynikach'}
            name={'default_answer'}
            formRef={register}
            formState={formState}
            popoverText={
              'W przypadku gdy osoba zaproszona do głosowania nie odda głosu, zostanie doliczona w wynikach do wybranej przez organizatora odpowiedzi, np. "Brak głosu", "Nie mam zdania", etc. Jeśli zaznaczysz tę opcję, musisz wybrać domyślną odpowiedź dla każdego pytania.'
            }
            style={{ marginTop: '2rem' }}
          />
        </div>

        <QuestionsFieldArray
          formState={formState}
          setErrors={setErrors}
          {...{
            control,
            register,
            getValues,
            setValue,
            errors,
          }}
          defaultAnswer={watchDefaultAnswerCheckbox}
        />
        <PrimarySmallButton>
          <Trans>Further</Trans>
        </PrimarySmallButton>
      </Form>
    </>
  );
};

export default CreateExtendedSurvey;
