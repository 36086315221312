import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { FieldError, useForm, useWatch } from 'react-hook-form';
import InputText from '../../../components/Forms/InputText';
import InputTextarea from '../../../components/Forms/InputTextarea';
import SurveyTypeHeader from '../../../components/SurveyTypeHeader';
import { SurveyData } from '../../../types/surveys.types';
import { FormLabel, FormSublabel } from '../../../utils/StyledComponents/forms';
import { PrimarySmallButton } from '../../../utils/StyledComponents/buttons';
import { Form } from '../../../utils/StyledComponents/containers';
import AnswersFieldArray from '../ExtendedSurvey/AnswersFieldArray';
import Checkbox from '../../../components/Forms/Checkbox';
import { getDefaultValues } from './newSurveySimple.helpers';
import { yupResolver } from '@hookform/resolvers/yup';
import { SimpleSurveyStep1Schema } from './surveySimpleValidations';

interface Props {
  newSurvey: SurveyData;
  updateNewSurveyData(data: any): void;
  setStep(stepNumber: number): void;
}

type Inputs = {
  title: string;
  question: string;
  answers: [
    {
      text: string;
      value: boolean;
    }
  ];
  default_answer: boolean;
  extraAnswer: string;
};

const CreatePoll: React.FC<Props> = ({ updateNewSurveyData, setStep, newSurvey }) => {
  const [checkHistory, setCheckHistory] = useState<Array<number>>([]);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    formState,
    setValue,
  } = useForm<Inputs>({
    defaultValues: getDefaultValues(newSurvey),
    resolver: yupResolver(SimpleSurveyStep1Schema),
  });

  setStep(1);
  const watchAnswers = useWatch({
    name: 'answers',
    control,
  });
  const watchDefaultAnswerCheckbox = useWatch({
    name: 'default_answer',
    control,
  });

  const onSubmit = (data: Inputs) => {
    let question: any = {
      question: data.question,
      answers: [],
    };
    let calcBasedOnDefault = false;
    data.answers.forEach((answer, index) => {
      let answerToPush: any = { text: answer.text };
      if (answer.value && watchDefaultAnswerCheckbox) {
        answerToPush.is_default = answer.value;
        calcBasedOnDefault = true;
      }
      question.answers.push(answerToPush);
    });
    updateNewSurveyData({
      name: data.title,
      questions: [question],
      survey_type: 'simple',
      calculate_votes_based_on_default: calcBasedOnDefault,
      default_answer: data.default_answer,
    });
    setStep(2);
  };

  useEffect(() => {
    setStep(1);
  }, []);

  useEffect(() => {
    watchAnswers.forEach((answer, index) => {
      if (answer.value && !checkHistory.includes(index)) {
        setValue(`answers.${checkHistory[0]}.value` as 'answers.0.value', false);
        setCheckHistory((prev: any) => [index]);
      }
      if (!answer.value && checkHistory.includes(index))
        setCheckHistory((prev: any) => prev.filter((value: any) => value !== index));
    });
  }, [watchAnswers, checkHistory, setValue]);

  return (
    <>
      <SurveyTypeHeader type={'simple'} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputText
          label={'PollTitle'}
          name={'title'}
          formRef={register}
          fieldError={errors.title}
          formState={formState}
          formType={'survey'}
          showRequiredStar={true}
        />
        <InputTextarea
          label={'PollQuestion'}
          name={'question'}
          formRef={register}
          fieldError={errors.question}
          formState={formState}
          formType={'survey'}
          showRequiredStar={true}
          control={control}
        />
        <div style={{ width: '90%' }}>
          <Checkbox
            label={'Uwzględnij brak odpowiedzi osoby uprawnionej do głosowania w wynikach'}
            name={'default_answer'}
            formRef={register}
            formState={formState}
            popoverText={
              'W przypadku gdy osoba zaproszona do głosowania nie odda głosu, zostanie doliczona w wynikach do wybranej przez organizatora odpowiedzi, np. "Brak głosu", "Nie mam zdania", etc. Jeśli zaznaczysz tę opcję, musisz wybrać domyślną odpowiedź dla każdego pytania.'
            }
            style={{ marginTop: '2rem' }}
          />
        </div>

        <FormLabel formType={'survey'}>
          <Trans>SelectPollPossibleAnswers</Trans>
        </FormLabel>
        {watchDefaultAnswerCheckbox && (
          <FormSublabel>
            <Trans>Zaznacz jedną z odpowiedzi, aby ustawić ją jako domyślną</Trans>
          </FormSublabel>
        )}
        <AnswersFieldArray
          name="answers"
          formState={formState}
          nameExtraField="extraAnswer"
          onlyOneChoice={true}
          errors={errors}
          defaultAnswer={watchDefaultAnswerCheckbox}
          surveyType={'simple'}
          {...{ control, register, setValue, getValues }}
        />
        <PrimarySmallButton type={'submit'}>
          <Trans>Further</Trans>
        </PrimarySmallButton>
      </Form>
    </>
  );
};

export default CreatePoll;
