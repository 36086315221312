import React, { FC } from 'react';
import QuestionPreview from './QuestionPreview';
import { SurveyAnswer, SurveyType, SurveyTypeEnum } from '../../types/surveys.types';
import { FormLabel } from '../../utils/StyledComponents/forms';
import { Trans } from 'react-i18next';

interface SurveyPreviewProps {
  survey: SurveyType;
  selectedAnswers: any;
  errors: any;
  handleSelectAnswer: any;
  changeFreeAnswerValue: any;
  getSelectedAnswersCountForQuestion: any;
  freeAnswerErrors: any;
}

const SurveyPreview: FC<SurveyPreviewProps> = ({
  survey,
  selectedAnswers,
  errors,
  handleSelectAnswer,
  changeFreeAnswerValue,
  getSelectedAnswersCountForQuestion,
  freeAnswerErrors,
}) => {
  return (
    <>
      <FormLabel formType={'survey'}>
        <Trans>
          {survey.survey_type === SurveyTypeEnum.extended
            ? 'SurveyTitle'
            : survey.survey_type === SurveyTypeEnum.simple
            ? 'PollTitle'
            : ''}
        </Trans>
      </FormLabel>

      {survey.name}
      {survey.questions?.map((question, key) => {
        return (
          <QuestionPreview
            vote={true}
            question={question}
            key={question.id}
            selectedAnswers={selectedAnswers.filter(
              (selectedAnswer: any) => selectedAnswer && selectedAnswer.question_id === question.id
            )}
            setSelectedAnswers={(answer: SurveyAnswer) => handleSelectAnswer(question, answer)}
            changeFreeAnswerValue={changeFreeAnswerValue}
            selectedAnswersCountForQuestion={getSelectedAnswersCountForQuestion(
              selectedAnswers,
              question.id
            )}
            error={errors && errors[question.id]}
            freeAnswerError={errors && freeAnswerErrors[question.id]}
            surveyType={survey.survey_type}
          />
        );
      })}
    </>
  );
};

export default SurveyPreview;
