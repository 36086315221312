import React, { FC, useEffect } from 'react';
import {
  SurveyAnswer,
  SurveyType,
  SurveyTypeEnum,
  SurveyTypeProject,
} from '../../types/surveys.types';
import QuestionPreview from './QuestionPreview';
import styled from 'styled-components';
import ProjectQuestionPreview from './ProjectQuestionPreview';
import { Trans } from 'react-i18next';
import {
  FormLabel,
  FormLabelProject,
  FormLabelProjectContent,
} from '../../utils/StyledComponents/forms';
import { colors } from '../../theme';
import { ContentContainer } from '../../utils/StyledComponents/containers';
import ProjectVoteAgreements from './ProjectVoteAgreements';
import { UseFormReturn } from 'react-hook-form/dist/types/form';
import Checkbox from '../Forms/Checkbox';
import { defaultProjectImage } from '../../containers/Surveys/NewProject/utils/defaultValues';

const StyledContentContainer = styled(ContentContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeroImage = styled.img`
  object-fit: contain;
  max-width: 100%;
  max-height: 30vh;
  min-height: 30vh;
  width: auto;
  height: auto;
`;

const VotingInstruction = styled.div`
  font-size: 26px;
  text-align: center;
  margin-top: 20px;
`;

const VotingDetailsInstruction = styled(VotingInstruction)`
  color: ${colors.instruction};
`;

interface ProjectSurveyPreviewProps {
  survey: any;
  formMethods: UseFormReturn<any>;
  placeholderImages: any;
}

const ProjectSurveyPreview: FC<ProjectSurveyPreviewProps> = ({
  survey,
  formMethods,
  placeholderImages,
}) => {
  return (
    <StyledContentContainer>
      <HeroImage src={survey?.image?.image || placeholderImages?.project_placeholder_16_9} />

      <FormLabelProject>
        <Trans>SurveyName</Trans>
      </FormLabelProject>
      <FormLabelProjectContent formType={'survey'}>{survey.name}</FormLabelProjectContent>

      <FormLabelProject>
        <Trans>VotingCard</Trans>
      </FormLabelProject>

      <VotingInstruction>{survey.voting_ordinance}</VotingInstruction>
      <VotingInstruction>{survey.voting_card_instruction}</VotingInstruction>
      <VotingDetailsInstruction>
        <Trans>SeeVotingDetail</Trans>
      </VotingDetailsInstruction>

      {survey.questions?.map((question: any, index: number) => {
        return (
          <div key={question.uuid || index}>
            <ProjectQuestionPreview
              question={question}
              key={question.id}
              formMethods={formMethods}
              questionIndex={index}
              placeholderImage={placeholderImages?.project_placeholder_1_1}
            />
          </div>
        );
      })}

      <ProjectVoteAgreements formMethods={formMethods} />
    </StyledContentContainer>
  );
};

export default ProjectSurveyPreview;
