import React from 'react';
import { FormLabel } from '../../../../utils/StyledComponents/forms';
import { Trans } from 'react-i18next';
import Checkbox from '../../../../components/Forms/Checkbox';
import { Center } from '../../../../utils/StyledComponents/containers';
import DateTimePicker from '../../../../components/Forms/DateTimePicker';
import { useWatch } from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types/form';

interface Props {
  formMethods: UseFormReturn;
}

const TimeSettings: React.FC<Props> = (props) => {
  const { register, formState, control, setValue } = props.formMethods;
  const { errors } = formState;

  const watchTimeValue = useWatch({
    name: 'time.value',
    control,
  });

  return (
    <>
      <FormLabel formType={'survey'}>
        <Trans>ChooseDurationTime</Trans>
      </FormLabel>
      <div style={{ width: '90%' }}>
        <Checkbox
          label={'Bezterminowo'}
          type={'radio'}
          name={`time.value`}
          formRef={register}
          formState={formState}
          value={'indefinitely'}
        />
        <Checkbox
          label={'15 min'}
          type={'radio'}
          name={`time.value`}
          formRef={register}
          formState={formState}
          value={'15'}
        />
        <Checkbox
          label={'30 min'}
          type={'radio'}
          name={`time.value`}
          formRef={register}
          formState={formState}
          value={'30'}
        />
        <Checkbox
          label={'60 min'}
          type={'radio'}
          name={`time.value`}
          formRef={register}
          formState={formState}
          value={'60'}
        />
        <Checkbox
          label={'Own'}
          type={'radio'}
          name={'time.value'}
          formRef={register}
          formState={formState}
          value={'custom'}
          fieldError={errors.time?.value}
        />
        {watchTimeValue === 'custom' && (
          <>
            <Center>
              <FormLabel formType={'time'}>
                <Trans>StartDate</Trans>
              </FormLabel>
              <DateTimePicker
                control={control}
                onChange={(e: any) => {
                  setValue('time.start_date', new Date(e));
                }}
                name={'time.start_date'}
                formRef={register}
                fieldError={errors.time?.start_date}
              />
            </Center>
            <Center>
              <FormLabel formType={'time'}>
                <Trans>EndDate</Trans>
              </FormLabel>
              <DateTimePicker
                control={control}
                onChange={(e: any) => {
                  setValue('time.end_date', new Date(e));
                }}
                name={'time.end_date'}
                formRef={register}
                fieldError={errors.time?.end_date}
              />
            </Center>
          </>
        )}
      </div>
    </>
  );
};

export default TimeSettings;
