import { REQUEST_STATUSES } from '../../constants/requestStatuses.constants';
import axios from 'axios';
import { Dispatch } from 'redux';
import {
  CHECK_IF_USER_CAN_VOTE,
  CHECK_IF_USER_CAN_VOTE_FETCH_STATUS,
  CHECK_VERIFICATION,
  CHECK_VERIFICATION_FETCH_STATUS,
  SET_BLOCKCHAIN_FETCH_STATUS,
  SET_BLOCKCHAIN_KEYS,
  SET_BLOCKCHAIN_QR_CODE,
  SET_CHANGE_PASSWORD_FETCH_ERROR,
  SET_CHANGE_PASSWORD_FETCH_STATUS,
  SET_CLERK_VERIFICATION_KEYS_CHECK_FETCH_STATUS,
  SET_CLERK_VERIFICATION_VERIFY_FETCH_STATUS,
  SET_DELETE_MY_ACCOUNT_FETCH_STATUS,
  SET_ERROR_TYPES,
  SET_LOGIN_FETCH_STATUS,
  SET_REGISTER_FETCH_STATUS,
  SET_RESET_PASSWORD_FETCH_STATUS,
  SET_SMS_CODE_ID,
  SET_UPDATE_USER_FETCH_STATUS,
  SET_USER_DATA,
  SET_USER_FETCH_STATUS,
  SET_USER_NICKNAME_FETCH_STATUS,
  SET_VERIFY_EMAIL_FETCH_STATUS,
  SET_POST_PHONE_CODE_FETCH_STATUS,
  TOKEN_UPDATE,
  UNAUTH_USER,
  VERIFY_EMAIL_BY_CODE,
  VERIFY_EMAIL_BY_CODE_FETCH_STATUS,
  SET_UPDATE_USER_FETCH_ERROR,
  RESET_USER_PATCH_FETCH_STATUS,
  SET_LOGOUT_FETCH_STATUS,
} from './auth.actionTypes';
import {
  ChangePasswordData,
  ConfirmResetPasswordData,
  LoginDataEmail,
  LoginDataSocial,
  RegisterData,
  ResetPasswordData,
  UpdateTokenData,
  UserData,
  UserDataInputs,
} from '../../types/auth.types';
import { showNotification } from '../../utils/taost.config';
import { history, MyLocationState } from '../../utils/history';
import {
  deletePublicSurveyUid,
  deleteSessionToken,
  getPublicSurveyUid,
  getSessionToken,
  getVoterId,
  saveBlockchainKeysForUser,
  saveSessionToken,
} from '../../utils/localStorage';
import {
  getCreateSurveyPath,
  getLoginPath,
  getMyProfileAuthenticatePath,
  getSurveyPath,
  getVerifyEmailPath,
} from '../../constants/paths.constants';
import NetworkService from '../../utils/network.service';
import { toast } from 'react-toastify';

export function registerUser(data: RegisterData, state?: MyLocationState | undefined) {
  let url = process.env.REACT_APP_API_URL + `/auth/registration/`;
  let dataToPost: any = { ...data, survey: state?.uuid };
  const publicSurveyId = getPublicSurveyUid();
  if (publicSurveyId) {
    dataToPost.survey = publicSurveyId;
  }
  return function (dispatch: Dispatch): void {
    dispatch(setRegistrationFetchStatus(REQUEST_STATUSES.FETCHING));
    axios
      .post(url, dataToPost)
      .then((response) => {
        (response?.data?.key || response?.data?.token) && dispatch(updateToken(response.data));
        dispatch(setRegistrationFetchStatus(REQUEST_STATUSES.DONE));
      })
      .catch((error) => {
        dispatch(setRegistrationFetchStatus(REQUEST_STATUSES.ERROR));
        showNotification({
          response: error.response,
          type: 'error',
        });
      });
  };
}

// todo: refactor to send only data
export function loginUser(
  data: LoginDataEmail | LoginDataSocial,
  type: 'social/' | '',
  state?: MyLocationState | undefined
) {
  let url = process.env.REACT_APP_API_URL + `/auth${getLoginPath()}/${type}`;
  let dataToPost: any = { ...data };
  const publicSurveyId = getPublicSurveyUid();
  if (publicSurveyId) {
    dataToPost.survey = publicSurveyId;
  }
  return function (dispatch: Dispatch): void {
    dispatch(setLoginFetchStatus(REQUEST_STATUSES.FETCHING));
    axios
      .post(url, dataToPost)
      .then((response) => {
        dispatch(setLoginFetchStatus(REQUEST_STATUSES.DONE));
        dispatch(updateToken(response.data));
        // @ts-ignore
        dispatch(getAuthInfo(state));
        if (!state) {
          history.push('/');
        }
        deletePublicSurveyUid();
      })
      .catch((error) => {
        dispatch(setLoginFetchStatus(REQUEST_STATUSES.ERROR));
        console.log(error.response.data, error.response.data.types);
        if (error.response.data.types) {
          dispatch(setErrorType(error.response.data.types));
        } else if (error.response.status !== 500) {
          showNotification({
            message: 'Wprowadzony email lub hasło jest nieprawidłowy',
            type: 'error',
          });
        } else {
          showNotification({
            response: error.response,
            type: 'error',
          });
        }
      });
  };
}

export function checkIfUserCanVote(data: { survey_uuid: string | null; vid: string | null }): any {
  let url = process.env.REACT_APP_API_URL + `/surveys/check_account_for_survey/`;
  return function (dispatch: Dispatch) {
    dispatch(setCheckIfUserCanVoteFetchStatus(REQUEST_STATUSES.FETCHING));
    axios
      .post(url, data)
      .then((response) => {
        dispatch(setCheckIfUserCanVote(response.data));
        dispatch(setCheckIfUserCanVoteFetchStatus(REQUEST_STATUSES.DONE));
      })
      .catch((error) => {
        dispatch(setCheckIfUserCanVoteFetchStatus(REQUEST_STATUSES.ERROR));
      });
  };
}

export function verifyEmailByCode(data: { code: string | null }): any {
  let url = process.env.REACT_APP_API_URL + `/auth/registration/verify-email-by-code/`;
  return function (dispatch: Dispatch) {
    dispatch(setVerifyEmailByCodeFetchStatus(REQUEST_STATUSES.FETCHING));
    axios
      .post(url, data)
      .then((response) => {
        dispatch(setVerifyEmailByCode(response.data));
        dispatch(setVerifyEmailByCodeFetchStatus(REQUEST_STATUSES.DONE));
      })
      .catch((error) => {
        dispatch(setVerifyEmailByCodeFetchStatus(REQUEST_STATUSES.ERROR));
      });
  };
}

export function checkVerifications(data: { code: string | null }): any {
  let url = process.env.REACT_APP_API_URL + `/user/check_verification/`;
  return function (dispatch: Dispatch) {
    dispatch(setCheckVerificationFetchStatus(REQUEST_STATUSES.FETCHING));
    axios
      .post(url, data)
      .then((response) => {
        dispatch(setCheckVerification(response.data));
        dispatch(setCheckVerificationFetchStatus(REQUEST_STATUSES.DONE));
      })
      .catch((error) => {
        dispatch(setCheckVerificationFetchStatus(REQUEST_STATUSES.ERROR));
      });
  };
}

export function getAuthInfo(state?: MyLocationState | undefined): any {
  let url = process.env.REACT_APP_API_URL + `/auth/user/`;
  return function (dispatch: Dispatch) {
    dispatch(setUserFetchStatus(REQUEST_STATUSES.FETCHING));
    axios
      .get(url)
      .then((response) => {
        dispatch(setUserData(response.data));
        dispatch(setUserFetchStatus(REQUEST_STATUSES.DONE));
        if (!!state?.redirect) {
          if (state?.isVoting) {
            history.push(`${state?.redirect}/${state?.uuid}/vote`, state);
          } else {
            history.push(state?.redirect);
          }
        }
      })
      .catch((error) => {
        dispatch(setUserFetchStatus(REQUEST_STATUSES.ERROR));
        if (error.response && error.response.status === 401) {
          showNotification({
            message: 'Sesja wygasła. Zaloguj się ponownie.',
            type: 'error',
          });
        } else {
          showNotification({
            response: error.response,
            type: 'error',
          });
        }
      });
  };
}

export function updateUser(data: UserDataInputs | any, user: UserData) {
  if (data?.extended_profile?.postal_code) {
    data.extended_profile.postal_code = String(data?.extended_profile?.postal_code).replace(
      '-',
      ''
    );
  }

  return function (dispatch: Dispatch) {
    function update(redirect = true) {
      let url = process.env.REACT_APP_API_URL + `/auth/user/`;
      dispatch(setUserFetchStatus(REQUEST_STATUSES.FETCHING)); // ??
      axios
        .patch(url, data)
        .then((response) => {
          dispatch(setUserData(response.data));
          showNotification({
            message: 'Zaktualizowano pomyślnie',
            type: 'success',
          });
          dispatch(setUserFetchStatus(REQUEST_STATUSES.DONE)); // ??
          dispatch(setUpdateUserFetchStatus(REQUEST_STATUSES.DONE));
        })
        .catch((error) => {
          dispatch(setUserFetchStatus(REQUEST_STATUSES.ERROR)); // ??
          dispatch(setUpdateUserFetchStatus(REQUEST_STATUSES.ERROR));
          dispatch(setUpdateUserFetchError(error.response));
          showNotification({
            response: error.response,
            type: 'error',
          });
        });
    }

    dispatch(setUserFetchStatus(REQUEST_STATUSES.FETCHING)); // ??
    dispatch(setUpdateUserFetchStatus(REQUEST_STATUSES.FETCHING));
    let url = process.env.REACT_APP_API_URL + `/user/update_email/`;
    if (data?.email && user?.email && data.email !== user.email) {
      axios
        .post(url, { email: data.email })
        .then((response) => update(false))
        .catch((error) => {});
    } else {
      update();
    }
  };
}

// temporary simulation, only generate keys by BE
export function generateBlockchainKeys(userDataToSave: any, user: any) {
  return function (dispatch: Dispatch) {
    let url = process.env.REACT_APP_API_URL + `/user/temporary_blockchain/generate_keys/`;
    axios
      .post(url, {})
      .then((response) => {
        saveBlockchainKeysForUser(response.data, user.pk);
        dispatch(setBlockchainKeys(response.data));
        dispatch(setBlockchainFetchStatus(REQUEST_STATUSES.DONE));
      })
      .catch((error) => {
        dispatch(setBlockchainFetchStatus(REQUEST_STATUSES.ERROR));
        showNotification({
          response: error.response,
          type: 'error',
        });
      });
  };
}

// temporary simulation
export function generateQRCode(data: any) {
  return function (dispatch: Dispatch) {
    let url = process.env.REACT_APP_API_URL + `/user/generate_verification_qr/`;
    axios
      .post(url, data)
      .then((response) => {
        dispatch(setUserFetchStatus(REQUEST_STATUSES.DONE));
        dispatch(getAuthInfo());
      })
      .catch((error) => {
        dispatch(setUserFetchStatus(REQUEST_STATUSES.ERROR));
        showNotification({
          response: error.response,
          type: 'error',
        });
      });
  };
}

export function sendEmailConfirmation(data: { email: string }) {
  let url = process.env.REACT_APP_API_URL + `/auth/registration/resend-email-confirmation/`;
  return function (dispatch: Dispatch) {
    axios
      .post(url, data)
      .then((response) => {
        showNotification({
          message: 'Wysłano email weryfikacyjny',
          type: 'success',
        });
      })
      .catch((error) => {
        // dispatch(setUserFetchStatus(REQUEST_STATUSES.ERROR));
        showNotification({
          response: error.response,
          type: 'error',
        });
      });
  };
}

export function updateUserPhone(data: { phone: string }) {
  let url = process.env.REACT_APP_API_URL + `/auth/user/`;
  return function (dispatch: Dispatch) {
    dispatch(setUserFetchStatus(REQUEST_STATUSES.FETCHING));
    axios
      .patch(url, data)
      .then((response) => {
        dispatch(setUserFetchStatus(REQUEST_STATUSES.DONE));
        dispatch(setUserData(response.data));
        history.push(getMyProfileAuthenticatePath(), {
          phone: data.phone,
        });
      })
      .catch((error) => {
        dispatch(setUserFetchStatus(REQUEST_STATUSES.ERROR));
        showNotification({
          response: error.response,
          type: 'error',
        });
      });
  };
}

// todo: rename it
export function sendPhoneSignup(phone: string) {
  let url = process.env.REACT_APP_API_URL + `/phone_signup/send/`;

  return function (dispatch: Dispatch) {
    // dispatch(setVerifyPhoneFetchStatus(REQUEST_STATUSES.FETCHING));
    axios
      .post(url, phone)
      .then((response) => {
        dispatch(setSmsCodeId(response.data.id));
        showNotification({
          message: 'Wysłano kod sms na podany numer',
          type: 'success',
        });
        // dispatch(setVerifyPhoneFetchStatus(REQUEST_STATUSES.DONE));
      })
      .catch((error) => {
        showNotification({
          response: error.response,
          type: 'error',
        });
        // dispatch(setVerifyPhoneFetchStatus(REQUEST_STATUSES.ERROR));
      });
  };
}

// todo: rename it
export function confirmPhoneSignup(data: { id: string; raw_code: string }) {
  let url = process.env.REACT_APP_API_URL + `/phone_signup/confirm/`;
  return function (dispatch: Dispatch) {
    dispatch(setPostPhoneCodeFetchStatus(REQUEST_STATUSES.FETCHING));
    axios
      .post(url, data)
      .then((response) => {
        dispatch(getAuthInfo());
        dispatch(setPostPhoneCodeFetchStatus(REQUEST_STATUSES.DONE));
      })
      .catch((error) => {
        dispatch(setPostPhoneCodeFetchStatus(REQUEST_STATUSES.ERROR));
        showNotification({
          response: error.response,
          type: 'error',
        });
      });
  };
}

export function resetPassword(data: ResetPasswordData, config?: any) {
  let url = process.env.REACT_APP_API_URL + `/auth/password/reset/`;
  return function (dispatch: Dispatch) {
    dispatch(setResetPasswordFetchStatus(REQUEST_STATUSES.FETCHING));
    axios
      .post(url, data)
      .then((response) => {
        dispatch(setResetPasswordFetchStatus(REQUEST_STATUSES.DONE));
        if (config?.noToast) return;
        showNotification({
          message: 'ResetPasswordSuccess',
          type: 'success',
        });
      })
      .catch((error) => {
        dispatch(setResetPasswordFetchStatus(REQUEST_STATUSES.ERROR));
        showNotification({
          response: error.response,
          type: 'error',
        });
      });
  };
}

export function resetPasswordByPhone(data: ResetPasswordData, config?: any) {
  let url = process.env.REACT_APP_API_URL + `/auth/password/reset_phone/`;
  return function (dispatch: Dispatch) {
    dispatch(setResetPasswordFetchStatus(REQUEST_STATUSES.FETCHING));
    axios
      .post(url, data)
      .then((response) => {
        dispatch(setResetPasswordFetchStatus(REQUEST_STATUSES.DONE));
        if (config?.noToast) return;
        showNotification({
          message: 'ResetPasswordByPhoneSuccess',
          type: 'success',
        });
      })
      .catch((error) => {
        dispatch(setResetPasswordFetchStatus(REQUEST_STATUSES.ERROR));
        showNotification({
          response: error.response,
          type: 'error',
        });
      });
  };
}

export function confirmResetPassword(data: ConfirmResetPasswordData) {
  let url = process.env.REACT_APP_API_URL + `/auth/password/reset/confirm/`;
  return function (dispatch: Dispatch) {
    dispatch(setChangePasswordFetchStatus(REQUEST_STATUSES.FETCHING));
    axios
      .post(url, data)
      .then((response) => {
        dispatch(setChangePasswordFetchStatus(REQUEST_STATUSES.DONE));
        // todo: add translation
        showNotification({
          message: 'Zmieniono pomyślnie',
          type: 'success',
        });
        history.push(getLoginPath());
      })
      .catch((error) => {
        if (
          error.response.data?.new_password1 ||
          error.response.data?.new_password2 ||
          error.response.data?.email
        ) {
          let errorMessage: any = {};
          errorMessage.new_password1 =
            error.response.data?.new_password1 && error.response.data?.new_password1[0];
          errorMessage.new_password2 =
            error.response.data?.new_password2 && error.response.data?.new_password2[0];
          errorMessage.email = error.response.data?.email && error.response.data?.email[0];
          dispatch(setChangePasswordFetchError(errorMessage));
        } else {
          // todo: translation
          const errorMessage =
            error.response.data?.token || error.response.data?.uid
              ? 'Ważność linku wygasła'
              : undefined;
          if (errorMessage)
            showNotification({
              message: errorMessage,
              type: 'error',
            });
          dispatch(setChangePasswordFetchStatus(REQUEST_STATUSES.ERROR));
        }
      });
  };
}

export function changePassword(data: ChangePasswordData) {
  let url = process.env.REACT_APP_API_URL + `/auth/password/change/`;
  return function (dispatch: Dispatch) {
    dispatch(setChangePasswordFetchStatus(REQUEST_STATUSES.FETCHING));
    axios
      .post(url, data)
      .then((response) => {
        dispatch(setChangePasswordFetchStatus(REQUEST_STATUSES.DONE));
        showNotification({
          message: 'Zmieniono pomyślnie',
          type: 'success',
        });
      })
      .catch((error) => {
        dispatch(setChangePasswordFetchStatus(REQUEST_STATUSES.ERROR));
        showNotification({
          response: error.response,
          type: 'error',
        });
      });
  };
}

export function verifyEmail(data: { key: string }) {
  let url = process.env.REACT_APP_API_URL + `/auth/registration${getVerifyEmailPath()}/`;
  return function (dispatch: Dispatch) {
    axios
      .post(url, data)
      .then((response) => {
        dispatch(setVerifyEmailFetchStatus(REQUEST_STATUSES.DONE));
      })
      .catch((error) => {
        showNotification({
          response: error.response,
          type: 'error',
        });
      });
  };
}

export function clerkVerificationCheckKeys(keys: { uuid: string; key: string }) {
  const url = process.env.REACT_APP_API_URL + '/user/clerk_verification/check/';
  return function (dispatch: Dispatch) {
    dispatch(setClerkVerificationKeysCheckFetchStatus(REQUEST_STATUSES.FETCHING));
    axios
      .post(url, keys)
      .then((response) => {
        dispatch(setClerkVerificationKeysCheckFetchStatus(REQUEST_STATUSES.DONE));
      })
      .catch((error) => {
        dispatch(setClerkVerificationKeysCheckFetchStatus(REQUEST_STATUSES.ERROR));
      });
  };
}

export function clerkVerificationVerify(data: any) {
  const url = process.env.REACT_APP_API_URL + '/user/clerk_verification/verify/';
  return function (dispatch: Dispatch) {
    dispatch(setClerkVerificationVerifyFetchStatus(REQUEST_STATUSES.FETCHING));
    axios
      .post(url, data)
      .then((response) => {
        dispatch(setClerkVerificationVerifyFetchStatus(REQUEST_STATUSES.DONE));
      })
      .catch((error) => {
        dispatch(setClerkVerificationVerifyFetchStatus(REQUEST_STATUSES.ERROR));
      });
  };
}

export function getBlockchainStatement(type: string, data?: any) {
  let config: any;
  if (type === 'clerk') {
    config = {
      url: process.env.REACT_APP_API_URL + '/user/clerk_verification/statement/',
      method: 'POST',
      responseType: 'blob',
      data,
    };
  }
  if (type === 'user') {
    config = {
      url: process.env.REACT_APP_API_URL + '/user/public_key_statement/',
      method: 'GET',
      responseType: 'blob',
    };
  }
  if (config)
    axios(config)
      .then((response) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([response.data]));
        link.setAttribute('download', `Oswiadczenie.pdf`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
}

export function setUserNickname(data: { voting_nick: string }) {
  const url = process.env.REACT_APP_API_URL + '/user/update_voting_nick/';
  return function (dispatch: Dispatch) {
    dispatch(setUserNicknameFetchStatus(REQUEST_STATUSES.FETCHING));
    axios
      .post(url, data)
      .then((response) => {
        dispatch(setUserNicknameFetchStatus(REQUEST_STATUSES.DONE));
        dispatch(getAuthInfo());
      })
      .catch((error) => {
        dispatch(setUserNicknameFetchStatus(REQUEST_STATUSES.ERROR));
      });
  };
}

export const updateToken = (data: UpdateTokenData) => {
  if (data.token || data.key) {
    saveSessionToken({ token: data.token || data.key });
    NetworkService.setupDefaultHeaders({ token: data.token || data.key, voterId: getVoterId() });
  }
  return {
    type: TOKEN_UPDATE,
    payload: data.token || data.key,
  };
};

const resetSessionToken = () => {
  deletePublicSurveyUid();
  deleteSessionToken();
  // attempt according to: https://github.com/keppelen/react-facebook-login/issues/216
  // @ts-ignore
  if (window?.FB?.logout) window.FB?.logout(() => console.log('Logged of from FB'));
  NetworkService.resetDefaultHeaders();
  history.push(getLoginPath());
  return {
    type: UNAUTH_USER,
    payload: {},
  };
};

export function syncTokenWithStore(redirect: boolean = true) {
  return function (dispatch: Dispatch) {
    let sessionToken = getSessionToken();
    if (!sessionToken || sessionToken === 'null') {
      dispatch(unauthUser());
      if (redirect) {
        history.push(getLoginPath(), {
          redirect: history.location.pathname + history.location.search,
        });
      }
    } else {
      dispatch(updateToken({ token: sessionToken }));
    }
  };
}

export function deleteMyAccount() {
  const url = process.env.REACT_APP_API_URL + '/user/delete-my-account/';
  return function (dispatch: Dispatch) {
    dispatch(setDeleteMyAccountFetchStatus(REQUEST_STATUSES.FETCHING));
    axios
      .delete(url)
      .then((response) => {
        dispatch(setDeleteMyAccountFetchStatus(REQUEST_STATUSES.DONE));
      })
      .catch((error) => {
        dispatch(setDeleteMyAccountFetchStatus(REQUEST_STATUSES.ERROR));
      });
  };
}

export function resetPostPhoneCodeFetchStatus() {
  return function (dispatch: Dispatch) {
    dispatch(setPostPhoneCodeFetchStatus(REQUEST_STATUSES.NULL));
  };
}

const unauthUser = () => ({
  type: UNAUTH_USER,
});

export function logout() {
  const url = process.env.REACT_APP_API_URL + '/auth/logout/';
  return function (dispatch: Dispatch) {
    dispatch(setLogoutFetchError(REQUEST_STATUSES.FETCHING));
    axios
      .post(url, {})
      .then((response) => {
        dispatch(setLogoutFetchError(REQUEST_STATUSES.DONE));
      })
      .catch((error) => {
        dispatch(setLogoutFetchError(REQUEST_STATUSES.ERROR));
      });
    dispatch(resetSessionToken());
  };
}

const action = (type: any) => (payload: any) => ({ type, payload });
export const setUpdateUserFetchError = action(SET_UPDATE_USER_FETCH_ERROR);
export const setLogoutFetchError = action(SET_LOGOUT_FETCH_STATUS);

export const setUserData = (data: UserData) => {
  return {
    type: SET_USER_DATA,
    payload: data,
  };
};

export const setSmsCodeId = (id: string) => {
  return {
    type: SET_SMS_CODE_ID,
    payload: id,
  };
};

// Statuses

export const setRegistrationFetchStatus = (status: string | null) => {
  return {
    type: SET_REGISTER_FETCH_STATUS,
    payload: status,
  };
};

const setVerifyEmailFetchStatus = (status: string | null) => {
  return {
    type: SET_VERIFY_EMAIL_FETCH_STATUS,
    payload: status,
  };
};

export const setPostPhoneCodeFetchStatus = (status: string | null) => {
  return {
    type: SET_POST_PHONE_CODE_FETCH_STATUS,
    payload: status,
  };
};

const setLoginFetchStatus = (status: string | null) => {
  return {
    type: SET_LOGIN_FETCH_STATUS,
    payload: status,
  };
};

const setResetPasswordFetchStatus = (status: string | null) => {
  return {
    type: SET_RESET_PASSWORD_FETCH_STATUS,
    payload: status,
  };
};

const setChangePasswordFetchStatus = (status: string | null) => {
  return {
    type: SET_CHANGE_PASSWORD_FETCH_STATUS,
    payload: status,
  };
};

const setChangePasswordFetchError = (payload: any) => {
  return {
    type: SET_CHANGE_PASSWORD_FETCH_ERROR,
    payload,
  };
};

export const setUserFetchStatus = (status: string | null) => {
  return {
    type: SET_USER_FETCH_STATUS,
    payload: status,
  };
};

export const resetUserFetchStatus = () => {
  return {
    type: RESET_USER_PATCH_FETCH_STATUS,
  };
};

export const setCheckIfUserCanVote = (payload: any) => {
  return {
    type: CHECK_IF_USER_CAN_VOTE,
    payload,
  };
};

export const setVerifyEmailByCode = (payload: any) => {
  return {
    type: VERIFY_EMAIL_BY_CODE,
    payload,
  };
};

// export const setVerifyPhoneByCode = (payload: any) => {
//   return {
//     type: VERIFY_PHONE_BY_CODE,
//     payload,
//   };
// };

export const setCheckIfUserCanVoteFetchStatus = (status: string | null) => {
  return {
    type: CHECK_IF_USER_CAN_VOTE_FETCH_STATUS,
    payload: status,
  };
};

export const setCheckVerificationFetchStatus = (status: string | null) => {
  return {
    type: CHECK_VERIFICATION_FETCH_STATUS,
    payload: status,
  };
};

export const setCheckVerification = (payload: any) => {
  return {
    type: CHECK_VERIFICATION,
    payload,
  };
};

export const setVerifyEmailByCodeFetchStatus = (status: string | null) => {
  return {
    type: VERIFY_EMAIL_BY_CODE_FETCH_STATUS,
    payload: status,
  };
};

export const setUpdateUserFetchStatus = (status: string | null) => {
  return {
    type: SET_UPDATE_USER_FETCH_STATUS,
    payload: status,
  };
};

export const setBlockchainFetchStatus = (status: string | null) => {
  return {
    type: SET_BLOCKCHAIN_FETCH_STATUS,
    payload: status,
  };
};

export const setBlockchainKeys = (payload: {}) => {
  return {
    type: SET_BLOCKCHAIN_KEYS,
    payload,
  };
};

const setBlockchainQRCode = (payload: string | null) => {
  return {
    type: SET_BLOCKCHAIN_QR_CODE,
    payload,
  };
};

const setClerkVerificationKeysCheckFetchStatus = (status: string | null) => {
  return {
    type: SET_CLERK_VERIFICATION_KEYS_CHECK_FETCH_STATUS,
    payload: status,
  };
};

const setClerkVerificationVerifyFetchStatus = (status: string | null) => {
  return {
    type: SET_CLERK_VERIFICATION_VERIFY_FETCH_STATUS,
    payload: status,
  };
};

const setUserNicknameFetchStatus = (status: string | null) => {
  return {
    type: SET_USER_NICKNAME_FETCH_STATUS,
    payload: status,
  };
};

const setErrorType = (type: Array<string>) => {
  return {
    type: SET_ERROR_TYPES,
    payload: type,
  };
};

const setDeleteMyAccountFetchStatus = (status: string | null) => {
  return {
    type: SET_DELETE_MY_ACCOUNT_FETCH_STATUS,
    payload: status,
  };
};
