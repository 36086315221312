import React, { useState } from 'react';
import { colors, device } from '../theme';
import { history } from '../utils/history';
import styled from 'styled-components';
import { ContactPerson, PostUpdateContactPersonType } from '../types/contactPersons.types';
import { connect } from 'react-redux';
import {
  resetContactPerson,
  updateContactPerson,
} from '../reducers/ContactPersons/contactPersons.actions';
import { getGroup, getGroups } from '../reducers/Groups/groups.actions';
import { Group } from '../types/groups.types';
import { getContactPersonsPath } from '../constants/paths.constants';
import { ContactButton } from '../utils/StyledComponents/buttons';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';

const ActionButtons = styled.div`
  display: flex;
`;

const ActionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${colors.darkBlue};
  border: none;
  color: white;
  height: 1.5rem;
  width: 1.5rem;
  font-size: 0.875rem;
  margin-left: 0.375rem;
  &:hover {
    cursor: pointer;
  }

  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg.MuiSvgIcon-root {
    height: 70%;
  }
`;

interface Props {
  contact: ContactPerson;
  group: Group;
  showEditButton?: boolean;
  color?: string;
  isSelected?: boolean;

  updateContactPerson(
    id: number,
    data: PostUpdateContactPersonType,
    redirect?: string,
    successMessage?: string
  ): void;

  getGroups(): void;

  getGroup(data: { uuid: string }): void;

  handleSelect?(contact: ContactPerson): void;

  resetContactPerson(): void;
}

const ContactPersonButton: React.FC<Props> = ({
  contact,
  group,
  updateContactPerson,
  getGroups,
  showEditButton = true,
  color,
  getGroup,
  handleSelect,
  isSelected = false,
  resetContactPerson,
}) => {
  const [showName, setShowName] = useState<any>(false);

  const isContactInGroup = contact.groups.map((group) => group.id).includes(+group.id);

  const handleEditClick = () => {
    history.push(`${getContactPersonsPath()}/${contact.id}`);
  };

  const handleAddClick = () => {
    if (handleSelect) {
      handleSelect(contact);
    } else {
      let contactGroups: Array<number> = [];
      if (contact.groups) contactGroups = contact.groups.map((group) => group.id);
      updateContactPerson(
        contact.id,
        {
          ...contact,
          groups: [...contactGroups, +group.id],
        },
        undefined,
        'Kontakt został dodany do grupy'
      );
      getGroups();
      resetContactPerson();
    }
  };

  const handleRemoveClick = () => {
    let contactGroups: Array<number> = [];
    if (contact.groups) contactGroups = contact.groups.map((group) => group.id);
    updateContactPerson(
      contact.id,
      {
        ...contact,
        groups: contactGroups.filter((id) => id !== +group.id),
      },
      undefined,
      'Kontakt został usunięty z grupy'
    );
    getGroups();
    resetContactPerson();
  };

  const toggleName = () => {
    setShowName((prev: any) => !prev && (!!contact.name || !!contact.phone));
  };

  return (
    <ContactButton
      color={color ? color : isContactInGroup || isSelected ? colors.lightBlue : colors.gray}
      onClick={toggleName}
    >
      {showName ? `${contact.name} tel. ${contact.phone}` : contact.email || ''}
      <ActionButtons>
        {showEditButton && (
          <ActionButton type={'button'} onClick={handleEditClick}>
            <EditIcon />
          </ActionButton>
        )}
        <ActionButton
          type={'button'}
          onClick={() => {
            toggleName();
            if (isContactInGroup) handleRemoveClick();
            else handleAddClick();
          }}
        >
          {isContactInGroup || isSelected ? <CloseIcon /> : <AddIcon />}
        </ActionButton>
      </ActionButtons>
    </ContactButton>
  );
};

function mapStateToProps(state: any) {
  return {};
}

export default connect(mapStateToProps, {
  updateContactPerson,
  getGroups,
  getGroup,
  resetContactPerson,
})(ContactPersonButton);
