import React from 'react';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { FormLabel, generalInputStyles, getInputStyles } from '../../utils/StyledComponents/forms';
import { Trans } from 'react-i18next';
import moment from 'moment';
import InputReadOnly from './InputReadOnly';

const DatePickerContainer = styled.div`
  width: 100%;

  .MuiFormControl-root {
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    .MuiOutlinedInput-root {
      margin-left: auto;
      margin-right: auto;
      ${generalInputStyles}
      ${(props) => getInputStyles(props)}
      padding-left: 0px;
      padding-right: 0px;

      input {
        background: none;
        max-height: 100%;
        margin-top: 1px;
        margin-bottom: 1px;
      }
      fieldset {
        border: none;
      }
    }
    .Mui-error {
      text-align: center;
    }
  }
`;

const ProfileDatePicker = (props) => {
  const {
    name,
    control,
    defaultValue = null,
    initialFocusedDate,
    label,
    validationMessage,
    minDate,
    maxDate,
    DatePickerInput,
    validate,
    formType,
    color,
    showRequiredStar,
    final,
    disabled,
    formRef,
    fieldError,
    disabledValue,
  } = props;

  return final || disabled ? (
    <InputReadOnly
      formRef={formRef}
      defaultValue={disabledValue ? disabledValue : defaultValue}
      final={final}
      label={label}
      name={name}
      color={color}
    />
  ) : (
    <>
      <FormLabel formType={formType} color={color}>
        <Trans>{label}</Trans>
        {validate?.required || showRequiredStar ? '*' : ''}
      </FormLabel>
      <DatePickerContainer {...props}>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          initialFocusedDate={defaultValue || initialFocusedDate}
          render={({ field: { onChange, onBlur, value, name, ref }, fieldState, formState }) => (
            <KeyboardDatePicker
              ref={ref}
              margin="normal"
              inputVariant="outlined"
              id="date-picker-dialog"
              format="yyyy-MM-DD"
              placeholder="YYYY-MM-DD"
              cancelLabel="zamknij"
              okLabel="ok"
              clearLabel="wyczyść"
              helperText={fieldError?.message || validationMessage}
              error={fieldError?.message || validationMessage}
              clearable
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              minDate={minDate}
              maxDate={maxDate}
              TextFieldComponent={DatePickerInput}
              InputLabelProps={{
                position: 'start',
                shrink: (defaultValue !== '' && value !== '') || fieldState.isTouched,
              }}
              // inputProps={{ readOnly: true }}
              value={value}
              onChange={(e) => {
                if (e instanceof Date && !isNaN(e)) {
                  onChange(new Date(e).toISOString().split('T')[0]);
                } else {
                  let formattedDate = moment(e).format('YYYY-MM-DD');
                  onChange(formattedDate);
                }
              }}
              onBlur={onBlur}
            />
          )}
        />
      </DatePickerContainer>
    </>
  );
};

export default ProfileDatePicker;
