import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../theme';
import { Trans } from 'react-i18next';
import SimpleModal, { ModalProps } from './Modal/SimpleModal';
import { DeleteTitleIcon } from './Modal/ModalTitleIcons';
import { PrimarySmallButton } from '../utils/StyledComponents/buttons';
import { REQUEST_STATUSES } from '../constants/requestStatuses.constants';

const RemoveDataLinkText = styled.span`
  display: block;
  padding-top: 22px;
  text-decoration: underline;
  color: ${colors.white};
  font-size: 14px;
  cursor: pointer;
`;

const DeleteButtonContainer = styled.div`
  width: 100%;
  button {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }
`;

interface Props {
  deleteMyAccount(): void;
  deleteMyAccountFetchStatus?: string | null;
  logout?: any;
}

const DeleteAccountButton: React.FC<Props> = ({
  deleteMyAccount,
  deleteMyAccountFetchStatus,
  logout = () => {},
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const [modalConfig, setModalConfig] = useState<ModalProps>();

  const delayedLogoutRef: any = useRef();

  const handleDeleteAccountClick = () => {
    setModalConfig({
      iconComponent: DeleteTitleIcon,
      title: 'Usuń konto',
      text: ` Twoje dane zostaną trwale usunięte z aplikacji. Ta operacja jest nieodwracalna. Czy na pewno chcesz usunąć konto?`,
      buttonConfirmText: 'Tak',
      buttonCancelText: 'Nie',
      onButtonConfirmClick: () => {
        setIsOpen(false);
        deleteMyAccount();
      },
      onButtonCancelClick: () => {
        setIsOpen(false);
      },
    });
    setIsOpen(true);
  };

  const delayedLogout = () => {
    delayedLogoutRef.current = setTimeout(() => {
      logout();
    }, 5 * 1000);
  };

  const openConfirmationModal = () => {
    setIsOpen(false);
    setTimeout(() => {
      setModalConfig({
        iconComponent: DeleteTitleIcon,
        title: 'Konto usunięte',
        text: `Twoje konto w aplikacji zostało trwale usunięte.`,
        buttonConfirmText: 'Ok',
        onButtonConfirmClick: () => {
          setIsOpen(false);
          setIsConfirmationOpen(false);
          delayedLogoutRef.current && clearTimeout(delayedLogoutRef.current);
          logout();
        },
      });
      setIsConfirmationOpen(true);
      delayedLogout();
    }, 300);
  };

  useEffect(() => {
    if (deleteMyAccountFetchStatus === REQUEST_STATUSES.DONE) {
      openConfirmationModal();
    }
  }, [deleteMyAccountFetchStatus]);

  return (
    <DeleteButtonContainer>
      <SimpleModal open={isOpen} setOpen={setIsOpen} {...modalConfig} />
      <SimpleModal open={isConfirmationOpen} setOpen={setIsConfirmationOpen} {...modalConfig} />

      <PrimarySmallButton
        type="button"
        borderColor={colors.white}
        bgColor={colors.white}
        color={colors.lightBlue}
        onClick={(e: Event) => {
          e.preventDefault();
          handleDeleteAccountClick();
        }}
      >
        <Trans>DeleteAccount</Trans>
      </PrimarySmallButton>
    </DeleteButtonContainer>
  );
};

export default DeleteAccountButton;
