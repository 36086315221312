import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import privateRoutes from '../../routes';
import BaseTemplate from '../BaseTemplate';
import QrCodeModal from '../../components/QrCodeModal';
import { history, MyLocationState } from '../../utils/history';
import { getCreateProjectPath } from '../../constants/paths.constants';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { resetNewProjectData } from '../../reducers/Surveys/surveys.actions';
import { Location } from 'history';
import useResetNewProjectDataOnLocationChange from '../../utils/useResetNewProjectDataOnLocationChange';

interface Props {
  setLanguage: Dispatch<SetStateAction<string | undefined>>;
  resetNewProjectData(): void;
}

const Layout: React.FC<Props> = (props) => {
  const { resetNewProjectData } = props;

  useResetNewProjectDataOnLocationChange({ resetNewProjectData });

  return (
    <BaseTemplate>
      <QrCodeModal />
      {/*<ChangeLanguage setLanguage={setLanguage}/>*/}
      <Switch>
        {privateRoutes.map((route, key) => {
          return (
            <Route key={key} exact={route.exact} path={route.path} component={route.component} />
          );
        })}
        <Route path="*">
          <Redirect to="/surveys" />
        </Route>
      </Switch>
    </BaseTemplate>
  );
};

function mapStateToProps(state: any, otherProps: RouteComponentProps) {
  return {};
}

export default withRouter(connect(mapStateToProps, { resetNewProjectData })(Layout));
