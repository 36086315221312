import React, { FC, useEffect, useState } from 'react';
import { Center, Container } from '../../utils/StyledComponents/containers';
import SimpleModal from '../../components/Modal/SimpleModal';
import { Button, LogoSvg } from '../../utils/uiComponents';
import { Trans } from 'react-i18next';
import GoogleLogin from 'react-google-login';
import LoginForm from '../../components/LoginForm';
import { PrimarySmallButton } from '../../utils/StyledComponents/buttons';
import { history } from '../../utils/history';
import { getLoginPath, getSurveyPath } from '../../constants/paths.constants';
import { LoginDataEmail } from '../../types/auth.types';
import { REQUEST_STATUSES } from '../../constants/requestStatuses.constants';
import { getPublicSurveyUid } from '../../utils/localStorage';
import VerifyEmailCodeModal from '../VerifyEmailCodeModal';
import VerifyPhoneCodeModal from '../VerifyPhoneCodeModal';
import { getTargetSurveyPath } from '../../utils/authorizedVotingHelper';

const AuthorizedVotingLogin: FC<any> = (props) => {
  const {
    loginUser,
    getSurveyDetail,
    loginFetchStatus,
    errorTypes,
    accountForSurveyVotingData,
    user,
    surveyDetails,
    surveyDetailsFetchStatus,
    resetSurveyState,
    emailModalOpen,
    setEmailModalOpen,
    phoneModalOpen,
    setPhoneModalOpen,
  } = props;

  const onSubmit = (data: LoginDataEmail) => {
    loginUser(data, '', {});
  };

  const verifyEmailCallback = () => {
    setEmailModalOpen({ open: false, triggerRequest: false });
    checkIfVerificationRequired();
  };

  const verifyPhoneCallback = () => {
    setPhoneModalOpen({ open: false, triggerRequest: false });
    checkIfVerificationRequired();
  };

  const checkIfVerificationRequired = () => {
    if ((surveyDetailsFetchStatus !== REQUEST_STATUSES.DONE || !surveyDetails) && !user) return;
    else if (surveyDetails?.require_to_vote_email && !user.is_email_verified) {
      setEmailModalOpen({ triggerRequest: true, open: emailModalOpen.open });
      setTimeout(() => {
        setEmailModalOpen({ triggerRequest: true, open: true });
      }, 100);
    } else if (surveyDetails?.require_to_vote_phone && !user.is_phone_verified) {
      setPhoneModalOpen({ triggerRequest: true, open: phoneModalOpen.open });
      setTimeout(() => {
        setPhoneModalOpen({ triggerRequest: true, open: true });
      }, 100);
    } else if (
      (surveyDetails?.require_to_vote_email && user.is_email_verified) ||
      (surveyDetails?.require_to_vote_phone && user.is_phone_verified) ||
      (!surveyDetails?.require_to_vote_email && !surveyDetails?.require_to_vote_phone)
    ) {
      resetSurveyState();
      history.push(
        getTargetSurveyPath({ surveyUid: accountForSurveyVotingData?.survey_uuid })
      );
    }
  };

  useEffect(() => {
    if (loginFetchStatus !== REQUEST_STATUSES.DONE || !user?.email) return;
    const surveyUuid = accountForSurveyVotingData?.survey_uuid;
    if (surveyUuid) {
      getSurveyDetail(surveyUuid, !!user);
    }
  }, [loginFetchStatus, user]);

  useEffect(() => {
    checkIfVerificationRequired();
  }, [surveyDetailsFetchStatus, user]);

  return (
    <Container>
      <LogoSvg src={'/images/ivoting-logo.svg'} />
      <Center>
        <VerifyEmailCodeModal
          open={emailModalOpen.open}
          triggerRequest={emailModalOpen.triggerRequest}
          verifyCallback={verifyEmailCallback}
        />
        <VerifyPhoneCodeModal
          open={phoneModalOpen.open}
          triggerRequest={phoneModalOpen.triggerRequest}
          verifyCallback={verifyPhoneCallback}
        />
        <LoginForm
          onSubmit={onSubmit}
          hideSignUp={true}
          initialEmail={user?.email || accountForSurveyVotingData?.email}
          isLoading={
            surveyDetailsFetchStatus === REQUEST_STATUSES.FETCHING ||
            loginFetchStatus === REQUEST_STATUSES.FETCHING
          }
        />
      </Center>
    </Container>
  );
};

export default AuthorizedVotingLogin;
