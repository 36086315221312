import React from 'react';
import { Trans } from 'react-i18next';
import ErrorMessage from './ErrorMessage';
import { FormTypes } from '../../types/form.types';
import i18next from 'i18next';
import { Input, PrimaryLabel } from '../../utils/uiComponents';
import { FormInput, FormLabel } from '../../utils/StyledComponents/forms';

interface PasswordFormType extends FormTypes {
  customErrorMessage?: string | undefined | boolean;
}

const InputPassword: React.FC<PasswordFormType> = ({
  labelColor = '#000000',
  borderColor = '#000000',
  color = '#000000',
  label = 'Password',
  name = 'password',
  type = 'password',
  placeholder = '',
  formRef,
  fieldError,
  customErrorMessage,
  validate = {},
  formState,
}) => {
  return (
    <>
      <FormLabel labelColor={labelColor}>
        <Trans>{label}</Trans>
        {validate.required ? '*' : ''}
      </FormLabel>
      <FormInput
        type={type}
        {...formRef(name, validate)}
        placeholder={i18next.t(placeholder)}
        isDirty={formState.dirtyFields[name]}
      />
      <ErrorMessage
        fieldError={fieldError}
        validate={validate}
        customErrorMessage={typeof customErrorMessage === 'string' ? customErrorMessage : undefined}
      />
    </>
  );
};

export default InputPassword;
