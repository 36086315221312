import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import {
  Center,
  Container,
  TextOnlyViewContentContainer,
} from '../../utils/StyledComponents/containers';
import { termsContentNew, termsContentPl, termsTitlePl } from './terms.config';
import BaseTemplate from '../BaseTemplate';
import { device } from '../../theme';
import DownloadFileButton from '../../components/DownloadFileButton';
import { useHistory } from 'react-router-dom';

interface Props {
  fileUrl: any;
  fileLastUpdate: string;
  getConstants: any;
}

const TermsPageTitle = styled.div`
  font-size: 30px;
  line-height: 30px;
  font-weight: bold;
  margin-top: 80px;
  margin-bottom: 40px;
  text-align: center;

  @media ${device.laptop} {
    margin-top: 190px;
  }
`;

const TermsTitle = styled.a`
  font-size: 28px;
  line-height: 35px;
  font-weight: bold;
  margin-bottom: 60px;
  text-align: center;
  color: inherit;
  display: block;
  cursor: pointer;
`;

const TimeContainer = styled.p`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`;

const TermsAndConditions: React.FC<Props> = (props) => {
  const { fileUrl, fileLastUpdate, getConstants } = props;
  const history = useHistory();

  // useEffect(() => {
  //   if (fileUrl) history.push(fileUrl);
  // }, [fileUrl]);

  useEffect(() => {
    getConstants();
  }, []);

  return (
    <BaseTemplate>
      <Container>
        <Center>
          <TextOnlyViewContentContainer>
            {/* todo: detect language and change all content here */}
            {fileUrl && (
              <TermsTitle href={fileUrl} target="_blank" rel="noopener noreferrer">
                {termsTitlePl}
              </TermsTitle>
            )}
            <TimeContainer>
              {fileLastUpdate ? 'Aktualizacja z dnia ' + fileLastUpdate : ''}
            </TimeContainer>
          </TextOnlyViewContentContainer>
        </Center>
      </Container>
    </BaseTemplate>
  );
};

export default TermsAndConditions;
