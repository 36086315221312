import React, { useEffect, useState } from 'react';
import { history } from '../../../utils/history';
import { getSurveyPath, getSurveysPath } from '../../../constants/paths.constants';
import { Center, Container } from '../../../utils/StyledComponents/containers';
import { PrimarySmallButton } from '../../../utils/StyledComponents/buttons';
import { Trans } from 'react-i18next';
import { SurveyType } from '../../../types/surveys.types';
import SurveyShareModal from '../../../components/SurveyShareModal/SurveyShareModal';

interface Props {
  uuid: string;
  survey: SurveyType;
  resetVoteState(): void;
  getSurveyDetail?(uuid: string, isLogged?: any): void;
}

const Thanks: React.FC<Props> = ({ uuid, survey, resetVoteState, getSurveyDetail }) => {
  const [shareModalOpen, setShareModalOpen] = useState<any>(false);

  const handleShare = () => {
    // todo: find why sometimes it's cleared before opening this path
    if (!survey?.survey_type && uuid && getSurveyDetail) getSurveyDetail(uuid);
    setShareModalOpen(true);
  };

  const handleFurtherClick = () => {
    history.push(`${getSurveysPath()}`);
    resetVoteState?.();
  };

  useEffect(() => {
    return () => {
      resetVoteState?.();
    };
  }, []);

  useEffect(() => {
    if (!survey && uuid && getSurveyDetail) getSurveyDetail(uuid);
  }, [uuid]);

  return (
    <Container bgDark={true}>
      {shareModalOpen && survey && (
        <SurveyShareModal
          uuid={uuid}
          survey={survey}
          isOpen={shareModalOpen}
          setIsOpen={setShareModalOpen}
        />
      )}
      <Center>
        <h1 style={{ color: '#fff', textAlign: 'center', maxWidth: '90%' }}>
          <Trans>TanksForVoting</Trans>
        </h1>
        <>
          {!survey?.is_closed_vote && (
            <PrimarySmallButton onClick={handleShare}>
              <Trans>ShareVoting</Trans>
            </PrimarySmallButton>
          )}
          <PrimarySmallButton onClick={handleFurtherClick} style={{ marginTop: '24px' }}>
            <Trans>Further</Trans>
          </PrimarySmallButton>
        </>
      </Center>
    </Container>
  );
};

export default Thanks;
