import React, {useEffect, useState} from "react";
import {ListSurveyType} from "../types/surveys.types";
import Countdown from 'react-countdown';
import moment from "moment";
import {v1} from "uuid";
import {Trans} from "react-i18next";
import {history} from "../utils/history";
import {getSurveyPath} from "../constants/paths.constants";
import {RouteComponentProps} from "react-router";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {UserData} from "../types/auth.types";
import {SurveyListButton} from "../utils/StyledComponents/buttons";
import {SurveyListButtonInfo, SurveyListButtonTime} from "../utils/StyledComponents/containers";
import {TextEllipsis} from "../utils/StyledComponents/typography";

interface Props {
  survey: ListSurveyType;
  user: UserData;

  getSurveysList(): void;
}

function getColor(survey: ListSurveyType): string | undefined {
  if (!survey.is_voted && !survey.has_ended && !survey.is_own || survey.has_ended && !survey.is_viewed_results) return "#FF6868" // active and not voted
  if (survey.has_ended) return "#000" // ended
}

function getShowTimer(survey: ListSurveyType): boolean {
  return moment(survey.end_date).isAfter(moment(new Date))
}

const countdownRenderer = ({days, hours, minutes, seconds, completed}: any) => {
  if (completed) {
    return <></>
  } else {
    return <SurveyListButtonTime>{days}d:{hours}h:{minutes}m:{seconds}s</SurveyListButtonTime>;
  }
};

const SurveyButton: React.FC<Props> = ({survey, getSurveysList, user}) => {
  const [color, setColor] = useState<string | undefined>(undefined);
  const [showTimer, setShowTimer] = useState<boolean>(false);

  const showInfo = (!survey.is_voted && !survey.has_ended && !survey.is_own) || (survey.has_ended && !survey.is_viewed_results)

  useEffect(() => {
    refresh();
    return () => {
      setShowTimer(false);
    }
  }, [])

  const refresh = () => {
    setColor(getColor(survey));
    setShowTimer(getShowTimer(survey));
  }

  const handleButtonClick = () => {
    if (!survey.is_voted && !survey.has_ended && !survey.is_own) {
      history.push(`${getSurveyPath()}/${survey.uuid}/vote`);
    } else if ((survey.is_voted && !survey.has_ended) || (!survey.has_ended && survey.is_own)) {
      history.push(`${getSurveyPath()}/${survey.uuid}`);
    } else if (survey.has_ended) {
      history.push(`${getSurveyPath()}/${survey.uuid}/results`);
    }
  }

  return (
    <SurveyListButton
      bgColor={color}
      onClick={handleButtonClick}
      showInfo={showInfo}
      key={v1()}
      timer={showTimer}
    >
      <TextEllipsis style={{width: showInfo ? survey.has_ended ? "45%" : "70%" : "80%"}}>{survey.name}</TextEllipsis>
      {showTimer && <Countdown
          date={survey.end_date}
          renderer={countdownRenderer}
          onComplete={getSurveysList}
      />}
      {showInfo && (
        <SurveyListButtonInfo><Trans>{survey.has_ended ? 'Zobacz wyniki' : `New`}</Trans>!</SurveyListButtonInfo>)}
    </SurveyListButton>
  )
}

function mapStateToProps(state: any, otherProps: RouteComponentProps) {
  return {
    user: state.auth.user
  };
}

export default withRouter(connect(
  mapStateToProps,
  {}
)(SurveyButton));
