import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import _ from 'lodash';
import { useForm, useWatch } from 'react-hook-form';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateNewSurveyData } from '../../../reducers/Surveys/surveys.actions';
import Checkbox from '../../../components/Forms/Checkbox';
import ErrorMessage from '../../../components/Forms/ErrorMessage';
import { SurveyData } from '../../../types/surveys.types';
import SurveyTypeHeader from '../../../components/SurveyTypeHeader';
import { FormLabel } from '../../../utils/StyledComponents/forms';
import { PrimarySmallButton } from '../../../utils/StyledComponents/buttons';
import { Center, Form } from '../../../utils/StyledComponents/containers';
import DateTimePicker from '../../../components/Forms/DateTimePicker';
import moment from 'moment';

interface Props {
  type: 'simple' | 'extended' | 'image';
  newSurvey: any;

  updateNewSurveyData(data: any): void;

  setStep(stepNumber: number): void;
}

type Inputs = {
  time: {
    value: any;
    startDate: string;
    endDate: string;
  };
  is_overt: string; // converted to boolean in store
  show_on_page: boolean;
  close_after_submitted_votes: boolean;
  show_results_after_voted: boolean;
  require_to_vote_email: boolean;
  require_to_vote_phone: boolean;
  require_to_vote_qr: boolean;
  is_closed_vote: boolean;
  send_vote_confirmations: boolean;
};

const getDefaultValues = (newSurvey: SurveyData) => {
  return {
    time: newSurvey?.time ?? {
      value: '',
      startDate: null,
      endDate: null,
    },
    is_overt: String(newSurvey?.is_overt) ?? 'false',
    show_on_page: newSurvey?.show_on_page ?? false,
    close_after_submitted_votes: newSurvey?.close_after_submitted_votes ?? false,
    show_results_after_voted: newSurvey?.show_results_after_voted ?? false,
    require_to_vote_email: newSurvey?.require_to_vote_email ?? false,
    require_to_vote_phone: newSurvey?.require_to_vote_phone ?? false,
    require_to_vote_qr: newSurvey?.require_to_vote_qr ?? false,
    send_vote_confirmations: newSurvey?.send_vote_confirmations ?? false,
    is_closed_vote: newSurvey?.is_closed_vote ?? false,
  };
};

const SurveySettings: React.FC<Props> = ({ newSurvey, setStep, type, updateNewSurveyData }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    formState,
    setValue,
    setError,
    clearErrors,
    control,
  } = useForm<Inputs>({
    defaultValues: getDefaultValues(newSurvey),
  });

  const watchTimeValue = useWatch({
    name: 'time.value',
    defaultValue: newSurvey?.time?.value ?? '',
    control,
  });
  const watchStartDate = useWatch({
    name: 'time.startDate',
    defaultValue: newSurvey?.time?.startDate ?? null,
    control,
  });
  const watchEndDate = useWatch({
    name: 'time.endDate',
    defaultValue: newSurvey?.time?.endDate ?? null,
    control,
  });
  const watchClosedVoting = useWatch({
    name: 'is_closed_vote',
    defaultValue: newSurvey?.is_closed_vote ?? false,
    control,
  });
  const watchCloseAfterSubmittedVotes = useWatch({
    name: 'close_after_submitted_votes',
    defaultValue: newSurvey?.close_after_submitted_votes ?? false,
    control,
  });
  const [disabledInputs, setDisabledInputs] = useState<any>({ require_to_vote_email: false });

  const validateDates = (data?: Inputs): boolean => {
    let isValid = true;
    const now = moment();
    let startDate, endDate;
    if (moment((data && data.time.startDate) || watchStartDate).isValid()) {
      startDate = moment((data && data.time.startDate) || watchStartDate);
    }
    if (moment((data && data.time.endDate) || watchEndDate).isValid()) {
      endDate = moment((data && data.time.endDate) || watchEndDate);
    }
    if (startDate && endDate) {
      if (startDate > endDate) {
        isValid = false;
        setError('time.startDate', { type: 'startDateGreaterThanEndDate' });
        setError('time.endDate', { type: 'endDateLessThanStartDate' });
      }
      if (startDate.toString() === endDate.toString()) {
        isValid = false;
        setError('time.endDate', { type: 'datesCantBeTheSame' });
      }
    }
    if (!!endDate) {
      if (now > endDate) {
        isValid = false;
        setError('time.endDate', { type: 'dateCantBeInThePast' });
      }
    }
    if (data && !startDate) {
      isValid = false;
      setError('time.startDate', { type: 'required' });
    }
    if (data && !endDate) {
      isValid = false;
      setError('time.endDate', { type: 'required' });
    }
    return isValid;
  };

  const onSubmit = (data: Inputs) => {
    let isValid = true;
    if (!data.time || (data.time && !data.time.value)) {
      isValid = false;
      setError('time.value', { type: 'chooseOption' });
    }
    if (data.time.value === 'custom') {
      isValid = validateDates(data);
    }
    if (data.is_overt === '' || data.is_overt === null) {
      isValid = false;
      setError('is_overt', { type: 'chooseOption' });
    }
    if (isValid) {
      let survey = {
        ...newSurvey,
        is_overt: _.isString(data.is_overt) ? data.is_overt === 'true' : data.is_overt,
        show_on_page: data.show_on_page,
        time: data.time,
        survey_type: type,
        close_after_submitted_votes: data.close_after_submitted_votes,
        show_results_after_voted: data.show_results_after_voted,
        require_to_vote_email: data.require_to_vote_email,
        require_to_vote_phone: data.require_to_vote_phone,
        require_to_vote_qr: data.require_to_vote_qr,
        is_closed_vote: data.is_closed_vote,
        send_vote_confirmations: data.send_vote_confirmations,
      };
      updateNewSurveyData(survey);
      setStep(3);
    }
  };

  useEffect(() => {
    if (watchTimeValue === 'custom') {
      clearErrors(['time.startDate', 'time.endDate']);
      validateDates();
    }
  }, [watchStartDate, watchEndDate]);

  useEffect(() => {
    if (watchClosedVoting || watchCloseAfterSubmittedVotes) {
      setValue('require_to_vote_email', true);
      setDisabledInputs({
        ...disabledInputs,
        require_to_vote_email: true,
      });
    } else {
      setDisabledInputs({
        ...disabledInputs,
        require_to_vote_email: false,
      });
    }
  }, [watchCloseAfterSubmittedVotes, watchClosedVoting]);

  return (
    <>
      <SurveyTypeHeader type={newSurvey.survey_type} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormLabel formType={'survey'}>
          <Trans>ChooseDurationTime</Trans>
        </FormLabel>
        <div style={{ width: '90%' }}>
          <Checkbox
            label={'Bezterminowo'}
            type={'radio'}
            name={`time.value`}
            formRef={register}
            formState={formState}
            value={'indefinitely'}
          />
          <Checkbox
            label={'15 min'}
            type={'radio'}
            name={`time.value`}
            formRef={register}
            formState={formState}
            value={'15'}
          />
          <Checkbox
            label={'30 min'}
            type={'radio'}
            name={`time.value`}
            formRef={register}
            formState={formState}
            value={'30'}
          />
          <Checkbox
            label={'60 min'}
            type={'radio'}
            name={`time.value`}
            formRef={register}
            formState={formState}
            value={'60'}
          />
          <Checkbox
            label={'Own'}
            type={'radio'}
            name={'time.value'}
            formRef={register}
            formState={formState}
            value={'custom'}
          />
          <ErrorMessage fieldError={errors.time?.value} />
          {watchTimeValue === 'custom' && (
            <>
              <Center>
                <FormLabel formType={'time'}>
                  <Trans>StartDate</Trans>
                </FormLabel>
                <DateTimePicker
                  control={control}
                  onChange={(e: any) => {
                    setValue('time.startDate', moment(e).format('YYYY-MM-DDTHH:mm:ss'));
                  }}
                  name={'time.startDate'}
                  formRef={register}
                  fieldError={errors.time?.startDate}
                />
              </Center>
              <Center>
                <FormLabel formType={'time'}>
                  <Trans>EndDate</Trans>
                </FormLabel>
                <DateTimePicker
                  control={control}
                  onChange={(e: any) => {
                    setValue('time.endDate', moment(e).format('YYYY-MM-DDTHH:mm:ss'));
                  }}
                  disablePast={true}
                  name={'time.endDate'}
                  formRef={register}
                  fieldError={errors.time?.endDate}
                />
              </Center>
            </>
          )}
          <FormLabel formType={'survey'}>
            {newSurvey.survey_type === 'extended' ? (
              <Trans>Survey options</Trans>
            ) : (
              <Trans>Poll options</Trans>
            )}
          </FormLabel>
          <div style={{ width: '90%' }}>
            <Checkbox
              label={newSurvey.survey_type === 'extended' ? 'PublicSurvey' : 'PublicPoll'}
              type={'radio'}
              name={'is_overt'}
              formRef={register}
              formState={formState}
              value={'true'}
              popoverText={'isOvertTrueDescription'}
            />
            <Checkbox
              label={newSurvey.survey_type === 'extended' ? 'SecretSurvey' : 'SecretPoll'}
              type={'radio'}
              name={'is_overt'}
              formRef={register}
              formState={formState}
              value={'false'}
              fieldError={errors.is_overt}
              popoverText={'isOvertFalseDescription'}
            />
          </div>
          <FormLabel formType={'survey'}>Ustawienia dodatkowe</FormLabel>
          <div>
            {/* todo: move all of this to single file */}
            <Checkbox
              label={'ClosedPoll'}
              name={'is_closed_vote'}
              formRef={register}
              formState={formState}
              popoverText={'Głosowanie tylko dla określonej grupy użytkowników'}
              style={{ marginTop: '2rem' }}
            />
            <Checkbox
              label={'showOnPage'}
              name={'show_on_page'}
              formRef={register}
              formState={formState}
              popoverText={'showOnPageDescription'}
            />
            <Checkbox
              label={'End the vote if everyone has voted'}
              name={'close_after_submitted_votes'}
              formRef={register}
              formState={formState}
            />
            <Checkbox
              label={'ShowResultsAfterVotedDescription'}
              name={'show_results_after_voted'}
              formRef={register}
              formState={formState}
            />
            <Checkbox
              label={'SendVoteConfirmation'}
              name={'send_vote_confirmations'}
              formRef={register}
              formState={formState}
            />
          </div>
          <FormLabel formType={'survey'}>
            <Trans>RequiredAuthorization</Trans>
          </FormLabel>
          <div style={{ width: '90%' }}>
            <Checkbox
              label={'Email'}
              name={'require_to_vote_email'}
              formRef={register}
              formState={formState}
              popoverText={'requireToVoteEmail'}
              disabled={disabledInputs?.require_to_vote_email}
            />
            <Checkbox
              label={'Phone'}
              name={'require_to_vote_phone'}
              formRef={register}
              formState={formState}
              popoverText={'requireToVotePhone'}
            />
            <Checkbox
              label={'QrCode'}
              name={'require_to_vote_qr'}
              formRef={register}
              formState={formState}
              popoverText={'requireToVoteQr'}
            />
          </div>
        </div>
        <PrimarySmallButton>
          <Trans>Further</Trans>
        </PrimarySmallButton>
      </Form>
    </>
  );
};

function mapStateToProps(state: any, otherProps: RouteComponentProps) {
  return {
    newSurvey: state.surveys.newSurvey,
    user: state.auth.user,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    updateNewSurveyData,
  })(SurveySettings)
);
