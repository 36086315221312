import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Layout from './Layout';
import { RouteComponentProps } from 'react-router';

function mapStateToProps(state: any, otherProps: RouteComponentProps) {
  return {};
}

export default withRouter(connect(mapStateToProps, {})(Layout));
