import React, { FC } from 'react';
import { Form } from '../utils/StyledComponents/containers';
import TextBetweenTwoHrs from './Design/TextBetweenTwoHrs';
import InputEmail from './Forms/InputEmail';
import { emailPattern } from '../constants/patterns.constants';
import InputPassword from './Forms/InputPassword';
import Checkbox from './Forms/Checkbox';
import LinkToTermsAndConditions from './LinkToTermsAndConditions';
import { PrimarySmallButton } from '../utils/StyledComponents/buttons';
import { Trans } from 'react-i18next';
import { history } from '../utils/history';
import { getRegisterPath, getResetPasswordPath } from '../constants/paths.constants';
import { useForm } from 'react-hook-form';

interface LoginFormProps {
  onSubmit: any;
  hideSignUp?: boolean;
  initialEmail?: string;
  isLoading?: boolean;
}

const LoginForm: FC<LoginFormProps> = (props): any => {
  const { onSubmit, hideSignUp, initialEmail, isLoading } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
    formState,
  } = useForm<any>({
    defaultValues: {
      regulation: true,
      email: initialEmail,
    },
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <InputEmail
        formRef={register}
        fieldError={errors.email}
        validate={{
          required: true,
          pattern: emailPattern,
        }}
        formState={formState}
      />
      <InputPassword
        formRef={register}
        fieldError={errors.password}
        validate={{ required: true }}
        formState={formState}
      />
      <Checkbox
        style={{ margin: '1rem 0' }}
        labelComponent={<LinkToTermsAndConditions type={'login'} />}
        name={'regulation'}
        formRef={register}
        formState={formState}
        validate={{ required: true }}
        uppercase={false}
        fieldError={errors.regulation}
      />
      <PrimarySmallButton
        type="submit"
        style={{ marginTop: '0.3rem', marginBottom: '1.5rem' }}
        disabled={isLoading}
      >
        <Trans>SignIn</Trans>
      </PrimarySmallButton>

      {!hideSignUp && (
        <>
          <p style={{ marginTop: '1.5rem', marginBottom: '0.5rem' }}>
            <Trans>NewUser</Trans>?
          </p>
          <PrimarySmallButton
            type="submit"
            style={{ margin: '0.2rem 0' }}
            onClick={() => history.push(getRegisterPath(), history.location.state)}
            disabled={isLoading}
          >
            <Trans>SignUp</Trans>
          </PrimarySmallButton>
        </>
      )}

      <p
        style={{ marginTop: '1.5rem', marginBottom: '0.5rem' }}
        onClick={() => history.push(getRegisterPath(), history.location.state)}
      >
        <Trans>IForgotMyPassword</Trans>?
      </p>
      <PrimarySmallButton
        type="submit"
        style={{ margin: '0.2rem 0' }}
        onClick={() => history.push(getResetPasswordPath())}
        disabled={isLoading}
      >
        <Trans>RecoverMyPassword</Trans>
      </PrimarySmallButton>
    </Form>
  );
};

export default LoginForm;
