import Keycloak from 'keycloak-js';

const keycloackConstantsFromCyberID = {
  realm: 'CyberID',
  'auth-server-url': 'https://k.softmaker.space/auth/',
  'ssl-required': 'external',
  resource: 'Brival',
  credentials: {
    secret: 'mNIovVjO6oSYOUb3ojNHhIclYBPXtgss',
  },
  'confidential-port': 0,
};

const keycloak = Keycloak({
  url: 'https://k.softmaker.space/auth',
  realm: 'CyberID',
  clientId: 'React-auth',
});

export default keycloak;
