import React, { useEffect, useState } from 'react';
import { Location } from 'history';
import { ListSurveyType } from '../../types/surveys.types';
import { UserData } from '../../types/auth.types';
import moment from 'moment';
import { Trans } from 'react-i18next';
import SurveysButtonsList from '../../components/SurveysButtonsList';
import { SurveyListH1 } from '../../utils/StyledComponents/typography';
import { Center, FlexContainer } from '../../utils/StyledComponents/containers';

interface Props {
  surveys: Array<ListSurveyType>;
  location: Location;
  user: UserData;
  getSurveysList(reqParams?: string): void;
  setUpdatedSurveysList(data: any): void;
  resetSurveysList(): void;
}

const Surveys: React.FC<Props> = ({
  surveys,
  getSurveysList,
  location,
  user,
  setUpdatedSurveysList,
  resetSurveysList,
}) => {
  const [reqParams, setReqParams] = useState<any>();
  const [filter, setFilter] = useState<any>('');

  const updateReqParams = () => {
    let searchParams1 = new URLSearchParams(location.search);
    const date = moment(new Date()).add(3, 'seconds').format('YYYY-MM-DDTHH:mm:ss');
    let queryData: any = {};
    setFilter('all');
    if (searchParams1.get('active')) {
      queryData['start_date_before'] = date;
      queryData['end_date_after'] = date;
      // queryData["status"] = 2;
      setFilter('active');
    }
    if (searchParams1.get('mine')) {
      queryData['created_by'] = user.pk;
      setFilter('mine');
    }

    let searchParams = new URLSearchParams(queryData).toString()
      ? `?${new URLSearchParams(queryData).toString()}`
      : '';
    setReqParams(searchParams);
  };

  useEffect(() => {
    setUpdatedSurveysList(null);
    updateReqParams();
    return () => {
      resetSurveysList();
    };
  }, [location.search]);

  useEffect(() => {
    if (filter) getSurveysList(reqParams);
  }, [reqParams]);

  const getTabName = () => {
    switch (filter) {
      case 'all':
        return 'AllVotings';
      case 'active':
        return 'ActiveVotings';
      case 'mine':
        return 'MineVotings';
      default:
        break;
    }
  };

  return (
    <FlexContainer>
      <Center>
        <SurveyListH1>
          <Trans>{getTabName()}</Trans>
        </SurveyListH1>
        {filter && filter === 'active' && (
          <>
            <SurveysButtonsList
              allSurveys={surveys}
              lists={[
                {
                  filters: [
                    {
                      key: 'has_ended',
                      value: false,
                    },
                    {
                      key: 'is_voted',
                      value: false,
                    },
                  ],
                  sort: ['-created_at'],
                },
                {
                  filters: [
                    {
                      key: 'has_ended',
                      value: false,
                    },
                    {
                      key: 'is_voted',
                      value: true,
                    },
                  ],
                  sort: ['-end_date'],
                },
              ]}
              getSurveysList={() => updateReqParams()}
            />
          </>
        )}
        {filter && filter === 'all' && (
          <>
            <SurveysButtonsList
              allSurveys={surveys}
              lists={[
                {
                  filters: [
                    {
                      key: 'has_ended',
                      value: false,
                    },
                    {
                      key: 'is_voted',
                      value: false,
                    },
                  ],
                  sort: ['-created_at'],
                },
                {
                  filters: [
                    {
                      key: 'has_ended',
                      value: false,
                    },
                    {
                      key: 'is_voted',
                      value: true,
                    },
                  ],
                  sort: ['-end_date'],
                },
              ]}
              getSurveysList={() => updateReqParams()}
            />
            <SurveyListH1 style={{ marginTop: '90px' }}>
              <Trans>EndedVotings</Trans>
            </SurveyListH1>
            <SurveysButtonsList
              allSurveys={surveys}
              lists={[
                {
                  filters: [
                    {
                      key: 'has_ended',
                      value: true,
                    },
                    {
                      key: 'is_viewed_results',
                      value: false,
                    },
                  ],
                  sort: ['-created_at'],
                },
                {
                  filters: [
                    {
                      key: 'has_ended',
                      value: true,
                    },
                    {
                      key: 'is_viewed_results',
                      value: true,
                    },
                  ],
                  sort: ['-end_date'],
                },
              ]}
              getSurveysList={() => updateReqParams()}
            />
          </>
        )}
        {filter && filter === 'mine' && (
          <>
            <SurveysButtonsList
              allSurveys={surveys}
              lists={[
                {
                  filters: [
                    {
                      key: 'has_ended',
                      value: false,
                    },
                    {
                      key: 'is_voted',
                      value: false,
                    },
                  ],
                  sort: ['-created_at'],
                },
                {
                  filters: [
                    {
                      key: 'has_ended',
                      value: false,
                    },
                    {
                      key: 'is_voted',
                      value: true,
                    },
                  ],
                  sort: ['-end_date'],
                },
                {
                  filters: [
                    {
                      key: 'has_ended',
                      value: true,
                    },
                    {
                      key: 'is_viewed_results',
                      value: false,
                    },
                  ],
                  sort: ['-created_at'],
                },
                {
                  filters: [
                    {
                      key: 'has_ended',
                      value: true,
                    },
                    {
                      key: 'is_viewed_results',
                      value: true,
                    },
                  ],
                  sort: ['-end_date'],
                },
              ]}
              getSurveysList={() => updateReqParams()}
            />
          </>
        )}
      </Center>
    </FlexContainer>
  );
};

export default Surveys;
