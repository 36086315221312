import MyProfile from './containers/MyProfile';
import Surveys from './containers/Surveys';
import { RouteProps } from 'react-router-dom';
import NewSurvey from './containers/Surveys/NewSurvey';
import Authenticate from './containers/MyProfile/Authenticate';
import Login from './containers/Login';
import CyberIDLogin from './containers/CyberIDLogin';
import Register from './containers/Register';
import Reset from './containers/Reset';
import TermsAndConditions from './containers/TermsAndConditions';
import Info from './containers/Info';
import VerifyEmail from './containers/VerifyEmail';
import ContactPersons from './containers/ContactPersons';
import ContactPersonsDetail from './containers/ContactPersons/Details';
import Groups from './containers/Groups';
import GroupsDetail from './containers/Groups/Details';
import Invite from './containers/Surveys/NewSurvey/Invite';
import SurveyDetail from './containers/Surveys/Details';
import SurveyVote from './containers/Surveys/Vote';
import Thanks from './containers/Surveys/Thanks';
import Nickname from './containers/Login/Nickname';
import QrVerification from './containers/QrVerification';
import {
  getAuthorizedVotingLandingPath,
  getAuthorizedVotingLoginPath,
  getAuthorizedVotingRegisterPath,
  getContactPersonsPath,
  getCreateSurveyPath,
  getGroupsPath,
  getInfoPath,
  getLoginPath,
  getMyProfileAuthenticatePath,
  getMyProfilePath,
  getRegisterPath,
  getResetPasswordPath,
  getResetPasswordSuccessPath,
  getSurveyPath,
  getSurveysPath,
  getTermsAndConditionsPath,
  getVerifyEmailPath,
  invitePath,
  qrVerificationPath,
  getCreateProjectPath,
  getSurveyThanksPath,
  getCyberIDLoginPath,
} from './constants/paths.constants';
import SurveyResults from './containers/Surveys/Results';
import { PublicRouteTypes } from './types/publicRoute.types';
import ResetSuccess from './containers/Reset/ResetSuccess';
import AuthorizedVotingLanding from './containers/AuhorizedVotingLanding';
import AuthorizedVotingLogin from './containers/AuthorizedVotingLogin';
import AuthorizedVotingRegistration from './containers/AuthorizedVotingRegistration';
import ProjectStep1 from './containers/Surveys/NewProject/ProjectStep1';
import ProjectStep2 from './containers/Surveys/NewProject/ProjectStep2';
import ProjectStep3 from './containers/Surveys/NewProject/ProjectStep3';
import ProjectStep4 from './containers/Surveys/NewProject/ProjectStep4';

export const publicRoutes: Array<PublicRouteTypes> = [
  {
    exact: true,
    path: `${getLoginPath()}/nickname`,
    component: Nickname,
  },
  {
    exact: true,
    path: getLoginPath(),
    component: Login,
  },
  {
    exact: true,
    path: getCyberIDLoginPath(),
    component: CyberIDLogin,
  },
  {
    exact: true,
    path: getRegisterPath(),
    component: Register,
    isBackVisible: true,
    backPath: getLoginPath(),
  },
  {
    exact: true,
    path: `${getResetPasswordPath()}/:uid/:token`,
    component: Reset,
  },
  {
    exact: true,
    path: `${getResetPasswordSuccessPath()}`,
    component: ResetSuccess,
    isBackVisible: true,
    backPath: getResetPasswordPath(),
  },
  {
    exact: true,
    path: getResetPasswordPath(),
    component: Reset,
    isBackVisible: true,
    backPath: getLoginPath(),
  },
  {
    exact: true,
    path: getTermsAndConditionsPath(),
    component: TermsAndConditions,
  },
  {
    exact: true,
    path: getInfoPath(),
    component: Info,
  },
  {
    exact: true,
    path: `${getVerifyEmailPath()}/:uuid`,
    component: VerifyEmail,
  },
  {
    exact: true,
    path: `${getSurveyPath()}/:uuid/vote`,
    component: SurveyVote,
  },
  {
    exact: true,
    path: getSurveyThanksPath(),
    component: Thanks,
  },
  {
    exact: true,
    path: `${getSurveyPath()}/:uuid/results`,
    component: SurveyResults,
  },
  {
    exact: true,
    path: `${getAuthorizedVotingLandingPath()}`,
    component: AuthorizedVotingLanding,
  },
  {
    exact: true,
    path: `${getAuthorizedVotingLandingPath()}`,
    component: AuthorizedVotingLanding,
  },
  {
    exact: true,
    path: `${getAuthorizedVotingLoginPath()}`,
    component: AuthorizedVotingLogin,
  },
  {
    exact: true,
    path: `${getAuthorizedVotingRegisterPath()}`,
    component: AuthorizedVotingRegistration,
  },
];

const privateRoutes: Array<RouteProps> = [
  {
    path: getMyProfileAuthenticatePath(),
    exact: true,
    component: Authenticate,
  },
  {
    path: getMyProfilePath(),
    exact: true,
    component: MyProfile,
  },
  {
    path: `${getSurveyPath()}/:uuid`,
    exact: true,
    component: SurveyDetail,
  },
  {
    path: getSurveysPath(),
    exact: true,
    component: Surveys,
  },
  {
    path: getCreateSurveyPath(),
    exact: true,
    component: NewSurvey,
  },
  {
    path: `${getCreateSurveyPath()}/:type`,
    exact: true,
    component: NewSurvey,
  },
  {
    path: `${getContactPersonsPath()}/:id`,
    exact: true,
    component: ContactPersonsDetail,
  },
  {
    path: getContactPersonsPath(),
    exact: true,
    component: ContactPersons,
  },
  {
    path: `${getGroupsPath()}/:uuid`,
    exact: true,
    component: GroupsDetail,
  },
  {
    path: getGroupsPath(),
    exact: true,
    component: Groups,
  },
  {
    path: invitePath(),
    exact: true,
    component: Invite,
  },
  {
    path: qrVerificationPath(),
    exact: true,
    component: QrVerification,
  },
  {
    path: getCreateProjectPath({ step: 1 }),
    exact: true,
    component: ProjectStep1,
  },
  {
    path: getCreateProjectPath({ step: 2 }),
    exact: true,
    component: ProjectStep2,
  },
  {
    path: getCreateProjectPath({ step: 3 }),
    exact: true,
    component: ProjectStep3,
  },
  {
    path: getCreateProjectPath({ step: 4 }),
    exact: true,
    component: ProjectStep4,
  },
];

export default privateRoutes;
