import React, { useEffect, useState } from 'react';
import BaseTemplate from '../../BaseTemplate';
import SurveyTypeHeader from '../../../components/SurveyTypeHeader';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { Center, FlexContainer } from '../../../utils/StyledComponents/containers';
import { FormLabel } from '../../../utils/StyledComponents/forms';
import { colors, device } from '../../../theme';
import {
  PrimarySmallButton,
  PrimarySmallLinkAsButton,
} from '../../../utils/StyledComponents/buttons';
import { history } from '../../../utils/history';
import { getSurveyPath } from '../../../constants/paths.constants';
import { closeVoting, getSurveyProtocol } from '../../../reducers/Surveys/surveys.actions';
import SurveyShareModal from '../../../components/SurveyShareModal/SurveyShareModal';
import {
  ResultAnswer,
  ResultQuestion,
  ResultTypeWithSurveyData,
  SurveyResultsSubmissionProps,
  SurveyTypeEnum,
} from '../../../types/surveys.types';

const Image: any = styled.div`
  margin-top: 0.625rem;
  height: 150px;
  width: 150px;
  border: 2px solid
    ${(props: any) =>
      props.borderColor
        ? props.borderColor
        : props.disabled
        ? '#0A5057'
        : props.isDirty || props.defaultValue
        ? '#373737'
        : '#000000'};
  border-radius: 10px;
  background: ${(props: any) => (props.bgImage ? `url(${props.bgImage})` : 'transparent')};
  background-size: 100% 100%;
  background-repeat: no-repeat;

  ${(props: any) =>
    props.vote &&
    `&:hover {
      cursor: pointer;
    }`}
`;

const ProgressBar: any = styled.progress`
  border-radius: 5px;
  height: 36px;

  &::-webkit-progress-bar {
    border-radius: 5px;

    background-color: transparent;
  }

  &::-webkit-progress-value {
    border-radius: 5px;

    background-color: #16becf;
  }

  display: ${(props: any) => (props.mobile ? 'block' : 'none')};

  width: 100%;

  @media ${device.laptop} {
    display: ${(props: any) => (props.mobile ? 'none' : 'block')};
  }
`;

const AnswerContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;

  @media ${device.laptop} {
    min-width: 800px;
    flex-direction: row;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 36px;
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;
  align-items: center;

  @media ${device.laptop} {
    margin-right: 3rem;
    align-items: start;
    width: 30%;
  }
`;

const PercentSpan = styled.span`
  color: #bbbbbb;
`;

const ResultSubmission = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 10px;
  margin: 5px;
  border-radius: 30px;
  font-weight: bold;
  background: ${colors.brightGray};
  color: ${colors.lightSeaGreen};
  font-size: 12px;

  * {
    padding: 0.5rem;
  }
`;

const SurveyResultsSubmission: React.FC<SurveyResultsSubmissionProps> = ({ submission }) => {
  return (
    <ResultSubmission>
      {/* <img src={'/images/contact-avatar.svg'} alt={""}/> */}
      {submission.name}
    </ResultSubmission>
  );
};

const RightSide = styled.div`
  @media ${device.laptop} {
    width: 70%;
  }
`;

const SubmissionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px 0;
`;

const ShowLessMoreButton = styled.button`
  background: none;
  border: none;
  outline: none;
  color: ${colors.lightSeaGreen};
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
  align-self: center;
  font-size: 12px;
  margin-left: 10px;
`;

interface SurveyResultsAnswerProps {
  answer: ResultAnswer;
  is_overt: boolean;
  total_votes: number;
}

const SurveyResultsAnswer: React.FC<SurveyResultsAnswerProps> = ({
  answer,
  is_overt,
  total_votes,
}) => {
  const percent = total_votes ? ((answer.votes / total_votes) * 100).toFixed(2) : 0;
  const [areSubmissionsExpanded, setAreSubmissionsExpanded] = useState<any>(false);
  return (
    <>
      {answer.image && (
        <AnswerContainer>
          <Flex>
            <span>
              {answer.text} - <PercentSpan>{percent}%</PercentSpan>
            </span>
            <ProgressBar mobile={true} value={answer.votes} max={total_votes} />
            <Image bgImage={answer.image.image} />
          </Flex>
          <RightSide>
            <ProgressBar value={answer.votes} max={total_votes} />
          </RightSide>
        </AnswerContainer>
      )}
      {!answer.image && (
        <AnswerContainer>
          <Flex>
            <span>
              {answer.text} - <PercentSpan>{percent}%</PercentSpan>
            </span>
            <ProgressBar mobile={true} value={answer.votes} max={total_votes} />
          </Flex>
          {is_overt && (
            <RightSide>
              <ProgressBar value={answer.votes} max={total_votes} />
              <SubmissionsContainer>
                {answer.submissions &&
                  answer.submissions.length > 0 &&
                  answer.submissions.length > 3 &&
                  !areSubmissionsExpanded && (
                    <>
                      {answer.submissions.slice(0, 3).map((submission, key) => (
                        <SurveyResultsSubmission key={key} submission={submission} />
                      ))}
                      <ShowLessMoreButton onClick={() => setAreSubmissionsExpanded(true)}>
                        +{answer.submissions.length - 3} więcej
                      </ShowLessMoreButton>
                    </>
                  )}

                {answer.submissions && answer.submissions.length > 3 && areSubmissionsExpanded && (
                  <>
                    {answer.submissions.map((submission, key) => (
                      <SurveyResultsSubmission key={key} submission={submission} />
                    ))}
                    <ShowLessMoreButton onClick={() => setAreSubmissionsExpanded(false)}>
                      Pokaż mniej{' '}
                    </ShowLessMoreButton>
                  </>
                )}

                {answer.submissions &&
                  answer.submissions.length > 0 &&
                  answer.submissions.length <= 3 && (
                    <>
                      {answer.submissions.map((submission, key) => (
                        <SurveyResultsSubmission key={key} submission={submission} />
                      ))}
                    </>
                  )}
              </SubmissionsContainer>
            </RightSide>
          )}
        </AnswerContainer>
      )}
    </>
  );
};

const AnswersContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;

  @media ${device.laptop} {
    max-width: 800px;
  }
`;

interface SurveyResultsQuestionProps {
  question: ResultQuestion;
  is_overt: boolean;
  survey_type: SurveyTypeEnum | string;
}

const SurveyResultsQuestion: React.FC<SurveyResultsQuestionProps> = ({
  question,
  is_overt,
  survey_type,
}) => {
  const getQuestionLabel = (survey_type: SurveyTypeEnum | string) => {
    switch (survey_type) {
      case SurveyTypeEnum.image:
      case SurveyTypeEnum.simple:
      case SurveyTypeEnum.extended:
        return 'Question';
      case SurveyTypeEnum.project:
        return 'ProjectQuestion';
    }
  };

  return (
    <>
      <FormLabel formType={'survey'}>
        <Trans>{getQuestionLabel(survey_type)}</Trans>
      </FormLabel>
      {question.question.question}
      <AnswersContainer>
        {question?.question?.answers?.map((answer, key) => (
          <SurveyResultsAnswer
            key={key}
            answer={answer}
            is_overt={is_overt}
            total_votes={question.total_votes}
          />
        ))}
      </AnswersContainer>
    </>
  );
};

const ResultsHeader = styled.div`
  h2 {
    font-size: 32px;
    color: #9bbe72;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
  }
`;

const DownloadProtocolClaim = styled.span`
  display: inline-block;
  margin: 10px 0;
`;

interface SurveyResultsProps {
  uuid: string;
  survey: ResultTypeWithSurveyData;

  getSurveyResults(uuid: string): void;
  resetSurveyDetail(): void;
}

const SurveyResults: React.FC<SurveyResultsProps> = ({
  uuid,
  survey,
  resetSurveyDetail,
  getSurveyResults,
}) => {
  const [shareModalOpen, setShareModalOpen] = useState<any>(false);

  useEffect(() => {
    survey && resetSurveyDetail();
    if (uuid) {
      getSurveyResults(uuid);
    }
    return () => {
      resetSurveyDetail();
    };
  }, [uuid]);

  useEffect(() => {
    if (survey) {
      if (!survey.has_ended && !survey.is_own) {
        if (!survey.is_voted) history.push(`${getSurveyPath()}/${uuid}/vote`);
        if (survey.is_voted && !survey.show_results_after_voted)
          history.push(`${getSurveyPath()}/${uuid}`);
      }
      if (!survey.has_ended && survey.is_own) {
        if (!survey.show_results_after_voted) history.push(`${getSurveyPath()}/${uuid}`);
      }
    }
  }, [survey]);

  const handleCloseVoting = () => {
    closeVoting(uuid).then(() => {
      getSurveyResults(uuid);
    });
  };

  const handleDownloadPdfClick = () => {
    getSurveyProtocol(uuid, survey.name);
  };

  const handleShare = () => {
    setShareModalOpen(true);
  };

  const getResultsHeader = (survey_type: SurveyTypeEnum | string) => {
    switch (survey_type) {
      case SurveyTypeEnum.image:
      case SurveyTypeEnum.simple:
        return 'Sondaż został zakończony';
      case SurveyTypeEnum.extended:
        return 'Ankieta została zakończona';
      case SurveyTypeEnum.project:
        return 'Głosowanie zostało zakończone';
    }
  };

  const getSurveyLabel = (survey_type: SurveyTypeEnum | string) => {
    switch (survey_type) {
      case SurveyTypeEnum.image:
      case SurveyTypeEnum.simple:
        return 'PollTitle';
      case SurveyTypeEnum.extended:
        return 'SurveyTitle';
      case SurveyTypeEnum.project:
        return 'ProjectTitle';
    }
  };

  return (
    <BaseTemplate>
      {shareModalOpen && (
        <SurveyShareModal
          uuid={uuid}
          survey={survey}
          isOpen={shareModalOpen}
          setIsOpen={setShareModalOpen}
        />
      )}
      <FlexContainer>
        <Center>
          {survey && (
            <>
              <SurveyTypeHeader type={survey.survey_type} />
              {survey.has_ended && (
                <ResultsHeader>
                  <h2>{getResultsHeader(survey.survey_type)}</h2>
                  <h2>Poniżej przedstawiamy wyniki</h2>
                </ResultsHeader>
              )}
              <FormLabel formType={'survey'}>
                <Trans>{getSurveyLabel(survey.survey_type)}</Trans>
              </FormLabel>
              {survey.name}
              {survey.questions &&
                survey.questions.map((question, key) => (
                  <SurveyResultsQuestion
                    key={key}
                    question={question}
                    is_overt={survey.is_overt}
                    survey_type={survey.survey_type}
                  />
                ))}
              <DownloadProtocolClaim>
                Jeśli chcesz sprawdzić pełną listę osób, które oddały głos w głosowaniu pobierz plik
                w PDF.
              </DownloadProtocolClaim>

              {/* on way from api, doesn't work on ios */}
              {/*<PrimarySmallButton onClick={handleDownloadPdfClick}>*/}
              {/*  <Trans>DownloadProtocol</Trans>*/}
              {/*</PrimarySmallButton>*/}

              <PrimarySmallLinkAsButton
                target="_blank"
                rel="noopener noreferrer"
                href={`${process.env?.REACT_APP_API_URL}/surveys/${survey.uuid}/result_protocol/`}
              >
                <Trans>DownloadProtocol</Trans>
              </PrimarySmallLinkAsButton>

              <PrimarySmallButton style={{ marginTop: '1rem' }} onClick={handleShare}>
                <Trans>ShareVoting</Trans>
              </PrimarySmallButton>

              {!survey.has_ended && survey.is_own && (
                <>
                  <PrimarySmallButton style={{ marginTop: '1rem' }} onClick={handleCloseVoting}>
                    <Trans>End voting</Trans>
                  </PrimarySmallButton>
                </>
              )}
            </>
          )}
        </Center>
      </FlexContainer>
    </BaseTemplate>
  );
};

export default SurveyResults;
