import React, { useEffect, useState } from 'react';
import { REQUEST_STATUSES } from '../../constants/requestStatuses.constants';
import SimpleModal from '../../components/Modal/SimpleModal';
import { Trans } from 'react-i18next';
import { CheckTitleIcon, LockTitleIcon } from '../../components/Modal/ModalTitleIcons';
import { PrimarySmallButton } from '../../utils/StyledComponents/buttons';
import { colors } from '../../theme';
import styled from 'styled-components';
import { UserData } from '../../types/auth.types';
import { cropDataForBlockchain } from './profileHelpers';
import { extendedProfileFields } from './ExtendedProfilePart';

const ModalBodyTitle = styled.div`
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
`;

const ModalBodyText = styled.div`
  text-align: center;
  font-size: 20px;
`;

const modalConfig = {
  title: 'EncryptedData',
  text: 'modalVerifyEmailText',
  buttonConfirmText: 'Confirm',
  buttonCancelText: 'Cancel',
};

const confirmModalConfig = {
  title: 'AssignSuccess',
  text: 'DataSavedOnBlockchain',
};

const shownDataToSave = (user: any) => {
  const dataToSave = cropDataForBlockchain(user);
  if (dataToSave.country === 1) dataToSave.country = 'Polska';
  return dataToSave;
};

interface Props {
  user: UserData;
  blockchainFetchStatus: string | null;
  blockchainKeys: { public_key: string | null; private_key: string | null };
  onClick: any;
  forceCloseModals: boolean | null | undefined;
}

const BlockchainButton: React.FC<Props> = ({
  user,
  blockchainFetchStatus,
  blockchainKeys,
  onClick,
  forceCloseModals,
}) => {
  const [isBlockchainModalOpen, setIsBlockchainModalOpen] = useState<any>(false);
  const [isBlockchainConfirmModalCOpen, setIsBlockchainConfirmModalOpen] = useState<any>(false);

  useEffect(() => {
    if (!forceCloseModals) return;
    setIsBlockchainModalOpen(false);
    setIsBlockchainConfirmModalOpen(false);
  }, [forceCloseModals]);

  useEffect(() => {
    if (
      blockchainFetchStatus === REQUEST_STATUSES.DONE &&
      blockchainKeys.public_key &&
      blockchainKeys.private_key
    ) {
      setIsBlockchainModalOpen(false);
      setIsBlockchainConfirmModalOpen(true);
    }
  }, [blockchainKeys, blockchainFetchStatus]);

  const saveOnBlockchain = (data: { phone: string; email: string }) => {
    onClick(data);
  };

  const dataToSave = shownDataToSave(user);

  return (
    <>
      <SimpleModal
        {...modalConfig}
        setOpen={setIsBlockchainModalOpen}
        open={isBlockchainModalOpen}
        iconComponent={LockTitleIcon}
        onButtonClick={() => saveOnBlockchain({ email: user.email, phone: user.phone })}
      >
        <div>
          <ModalBodyTitle>
            <Trans>EmailAddress</Trans>
          </ModalBodyTitle>
          <ModalBodyText> {dataToSave.email}</ModalBodyText>

          <ModalBodyTitle>
            <Trans>Phone</Trans>
          </ModalBodyTitle>
          <ModalBodyText> {dataToSave.phone}</ModalBodyText>

          <ModalBodyTitle>
            <Trans>FirstName</Trans>
          </ModalBodyTitle>
          <ModalBodyText> {dataToSave.first_name}</ModalBodyText>

          <ModalBodyTitle>
            <Trans>LastName</Trans>
          </ModalBodyTitle>
          <ModalBodyText> {dataToSave.last_name}</ModalBodyText>

          <ModalBodyTitle>
            <Trans>Organization</Trans>
          </ModalBodyTitle>
          <ModalBodyText> {dataToSave.organization}</ModalBodyText>

          {dataToSave?.extended_profile &&
            Object.entries(extendedProfileFields).map(([formId, value]) => {
              return (
                <>
                  <ModalBodyTitle key={formId}>
                    <Trans>{value?.label}</Trans>
                  </ModalBodyTitle>
                  <ModalBodyText>
                    {formId === 'country' ? 'Polska' : dataToSave.extended_profile[formId]}
                  </ModalBodyText>
                </>
              );
            })}
        </div>
      </SimpleModal>

      <SimpleModal
        {...confirmModalConfig}
        open={isBlockchainConfirmModalCOpen}
        iconComponent={CheckTitleIcon}
        onButtonClick={() => setIsBlockchainConfirmModalOpen(false)}
      />

      {user.is_phone_verified &&
        user.is_email_verified &&
        user.extended_profile &&
        !user.qr &&
        !blockchainKeys.public_key && (
          <PrimarySmallButton
            type="button"
            borderColor={colors.white}
            bgColor={colors.white}
            color={colors.lightBlue}
            onClick={(e: Event) => {
              e.preventDefault();
              setIsBlockchainModalOpen(true);
            }}
            onButtonCancelClick={() => setIsBlockchainModalOpen(false)}
          >
            <Trans>SaveOnBlockchain</Trans>
          </PrimarySmallButton>
        )}
    </>
  );
};

export default BlockchainButton;
