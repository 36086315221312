import React, { FC, useEffect } from 'react';
import Select, { components } from 'react-select';
import styled from 'styled-components';
import { Controller } from 'react-hook-form';
import { colors, device } from '../../theme';
import { Trans } from 'react-i18next';
import { FormInput, FormLabel, getInputStyles } from '../../utils/StyledComponents/forms';
import ErrorMessage from '../../components/Forms/ErrorMessage';
import InputReadOnly from '../../components/Forms/InputReadOnly';

const SelectContainer = styled.div`
  text-align: center;
  width: 100%;
`;

const RelativeContainer = styled.div`
  position: relative;
`;

const StyledSelect = styled(Select)`
  text-align: center;
  margin-top: 10px;

  & .select__control {
    ${(props) => getInputStyles(props)};
    padding-top: 0;
    padding-bottom: 0;
    background: none;
    margin-left: auto;
    margin-right: auto;
    border-radius: 30px;
    width: 90%;
    position: relative;
    pointer-events: ${({ pointerEvents = 'auto' }: any) => pointerEvents};
    height: 3.75rem;

    @media ${device.laptop} {
      max-width: 600px;
    }

    .select__value-container {
      color: ${({ color }) => color};
    }

    .select__placeholder,
    .select__single-value {
      color: ${({ color }) => color};
    }

    .select__value {
      padding: 1rem 1.25rem;
    }
  }

  .select__menu {
    margin-top: 0px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    //width: 90%;
    padding-top: 8px;
    padding-right: 2px;
    padding-bottom: 8px;
  }
`;

const Option = styled.div`
  min-height: 2.7rem;
  line-height: 2rem;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &:hover {
    cursor: pointer;
    background-color: ${colors.lightBlue};
  }
`;

const Sublabel = styled.div`
  margin-top: -0.1rem;
  padding-bottom: 0.6rem;
  font-size: 1rem;
  line-height: 1rem;
  color: ${colors.grayDescription};
`;

const CustomOption = (props: any) => {
  const { innerProps, isDisabled, innerRef, children } = props;
  return !isDisabled ? (
    <Option {...innerProps} ref={innerRef} className={'react-select__option'}>
      <div>{children}</div>
      {props?.data?.sublabel && <Sublabel>{props.data.sublabel}</Sublabel>}
    </Option>
  ) : null;
};

const SearchSelect: FC<any> = ({
  label = '',
  control,
  name,
  options,
  defaultValue,
  final,
  color,
  borderColor,
  caretColor,
  fieldError,
  showRequiredStar,
  validate,
  placeholder = 'Wybierz jedną z opcji...',
  noOptionsMessage = 'Brak dostępnych opcji',
  formRef,
  disabled,
  disabledValue,
  setValue,
}) => {
  useEffect(() => {
    if (setValue && disabledValue && disabled) setValue(name, disabledValue);
  }, [disabledValue, disabled]);

  return (final || disabled) && formRef ? (
    <InputReadOnly
      formRef={formRef}
      defaultValue={
        disabledValue
          ? disabledValue
          : options.find((option: any) => option.value === defaultValue)?.label || ''
      }
      final={final}
      label={label}
      name={name}
      color={color}
    />
  ) : (
    <SelectContainer>
      {label && (
        <FormLabel color={color}>
          <Trans>{label}</Trans>
          {showRequiredStar && '*'}
        </FormLabel>
      )}

      <Controller
        defaultValue={defaultValue}
        control={control}
        name={name}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
        }) => (
          <RelativeContainer>
            <StyledSelect
              borderColor={borderColor}
              color={color}
              caretColor={caretColor}
              ref={ref}
              classNamePrefix={'select'}
              options={options}
              isClearable={true}
              isSearchable={true}
              value={options.find((option: any) => option.value === value) || ''}
              onChange={(val: any) => {
                onChange(val && val.value);
              }}
              placeholder={placeholder}
              noOptionsMessage={() => noOptionsMessage}
              components={{ Option: CustomOption }}
              onBlur={onBlur}
            />
            <ErrorMessage fieldError={fieldError} />
          </RelativeContainer>
        )}
      />
    </SelectContainer>
  );
};

export default SearchSelect;
