import { Step1InputsType, Step2InputsType } from '../containers/Surveys/NewProject/utils/types';

export enum SurveyTypeEnum {
  simple = 'simple',
  extended = 'extended',
  image = 'image',
  project = 'project',
}

export interface ListSurveyType {
  end_date: Date;
  has_ended: boolean;
  has_started: boolean;
  is_viewed_results: boolean;
  is_voted: boolean;
  name: string;
  start_date: Date;
  uuid: string;
  is_own: boolean;
}

export interface SurveyType extends SurveyData {
  type: SurveyTypeEnum;
  uuid: string;
  name: string;
  end_date: Date;
  start_date: Date;
  has_started: boolean;
  has_ended: boolean;
  is_voted: boolean;
  is_viewed_results: boolean;
  qr: string;
  created_by: {
    first_name: string;
    last_name: string;
    organization: string;
  };
  share_url: string;
}

export interface SurveyTypeProject {
  image: {
    id: number;
    image: string | null;
  };
  voting_card_instruction: string;
}

export interface SurveyData extends ListSurveyType {
  name: string;
  is_overt: boolean;
  show_on_page: boolean;
  time: {
    value: any;
    startDate: string;
    endDate: string;
  };
  description: SurveyTypeEnum | string;
  questions: Array<SurveyQuestion>;
  survey_type: SurveyTypeEnum | string;
  close_after_submitted_votes: boolean;
  show_results_after_voted: boolean;
  invited_groups: Array<number>;
  invited_coordinator_groups: Array<number>;
  require_to_vote_email: boolean;
  require_to_vote_phone: boolean;
  require_to_vote_qr: boolean;
  is_closed_vote: boolean;
  calculate_votes_based_on_default?: boolean;
  default_answer: boolean;
  send_vote_confirmations?: boolean;
}

export interface SurveyQuestion {
  id: any;
  question: string;
  is_free_answers?: boolean;
  answers: Array<SurveyAnswer>;
  max_required_answers: number;
  min_required_answers: number;
}

export interface SurveyAnswer {
  text: string;
  image?: any;
  id?: any;
  is_default?: boolean;
  is_free_answer?: boolean;
  question_id?: number;
}

export interface ResultsType {
  is_overt: boolean;
  survey_type: SurveyTypeEnum | string;
  uuid: string;
  questions: Array<ResultQuestion>;
  name: string;
  has_ended: boolean;
  show_results_after_voted: boolean;
  is_own: boolean;
  is_voted: boolean;
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export interface ResultTypeWithSurveyData extends ResultsType, Omit<SurveyType, 'questions'> {}

export interface ResultQuestion {
  question: {
    question: string;
    answers: Array<ResultAnswer>;
  };
  total_votes: number;
}

export interface ResultAnswer {
  text: string;
  image: {
    id: number;
    image: string;
  };
  votes: number;
  submissions: Array<ResultSubmission>;
}

export interface ResultSubmission {
  is_anonymous: boolean;
  name: string;
}

export interface SurveyResultsSubmissionProps {
  submission: ResultSubmission;
}
