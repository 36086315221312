import { ActionButton } from './ActionButton';
import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { handleAppInstall, shouldButtonVisibleOnSecondInit } from '../utils/pwaInstallHelper';
import DownloadIcon from '@material-ui/icons/GetApp';
import { PrimarySmallButton } from '../utils/StyledComponents/buttons';

const DownloadAppButton: FC<any> = (props) => {
  const { style, ...otherProps } = props;

  // className is required, because it can be handled only in vanilla JS
  return (
    <>
      <PrimarySmallButton
        className={'install-app-btn'}
        type="button"
        style={{
          marginTop: 2,
          marginBottom: 15,
          visibility: shouldButtonVisibleOnSecondInit() ? 'visible' : 'hidden',
          ...style,
        }}
        onClick={handleAppInstall}
        {...otherProps}
      >
        <Trans>Install</Trans> <DownloadIcon />
      </PrimarySmallButton>
    </>
  );
};

export default DownloadAppButton;
