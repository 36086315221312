import React, { useEffect, useState } from 'react';
import { LoginDataEmail, LoginDataSocial } from '../../types/auth.types';
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { Button, LogoSvg } from '../../utils/uiComponents';
import { Trans } from 'react-i18next';
import { getLoginPath } from '../../constants/paths.constants';
import { history, MyLocationState } from '../../utils/history';
import { Center, Container, Form } from '../../utils/StyledComponents/containers';
import { PrimarySmallButton } from '../../utils/StyledComponents/buttons';
import { REQUEST_STATUSES } from '../../constants/requestStatuses.constants';
import { CheckTitleIcon, LockTitleIcon } from '../../components/Modal/ModalTitleIcons';
import SimpleModal, { ModalProps } from '../../components/Modal/SimpleModal';
import LoginForm from '../../components/LoginForm';
import TextBetweenTwoHrs from '../../components/Design/TextBetweenTwoHrs';
import DownloadAppButton from '../../components/DownloadAppButton';

interface Props {
  loginUser(
    data: LoginDataEmail | LoginDataSocial,
    type: 'social/' | '',
    state?: MyLocationState | undefined
  ): any;
  loginFetchStatus: string | null;
  errorTypes: Array<string> | null;
}

type Inputs = {
  email: string;
  password: string;
  regulation: boolean;
};

const Login: React.FC<Props> = ({ loginUser, loginFetchStatus, errorTypes }) => {
  const isVoting = !!history.location.state?.isVoting;
  const redirectToSurvey = history.location.state?.redirectToSurvey;
  const canVoteWithoutLogin = !!history.location.state?.canVoteWithoutLogin;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modalConfig, setModalConfig] = useState<ModalProps>();
  const [email, setEmail] = useState<string>();

  const onSubmit = (data: LoginDataEmail) => {
    setEmail(data.email);
    loginUser(data, '', history.location.state);
  };

  const handleFBLoginClick = (e: Event) => {
    e.preventDefault();
    // @ts-ignore
    if (!window.FB) {
      console.error('Import FB failed');
      return;
    }

    // @ts-ignore
    window.FB.getLoginStatus(function (statusResponse: any) {
      if (statusResponse.status === 'connected') {
        responseFacebook(statusResponse.authResponse);
      } else {
        // @ts-ignore
        window.FB.login(function (loginResponse: any) {
          if (loginResponse.authResponse) {
            responseFacebook(loginResponse.authResponse);
          } else {
            console.log('User cancelled login or did not fully authorize.');
          }
        });
      }
    });
  };

  const responseFacebook = (response: any) => {
    loginUser(
      {
        provider: 'facebook',
        access_token: response.accessToken,
      },
      'social/',
      history.location.state
    );
  };

  const responseGoogle = (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    if ('accessToken' in response) {
      loginUser(
        {
          provider: 'google-oauth2',
          access_token: response.accessToken,
        },
        'social/',
        history.location.state
      );
    }
  };

  const failureGoogle = (response: any) => {
    console.error(response);
  };

  useEffect(() => {
    if (loginFetchStatus === REQUEST_STATUSES.ERROR && errorTypes) {
      if (errorTypes.includes('inactive_account')) {
        setModalConfig({
          title: 'Nieaktywne konto',
          text: `Twoje konto nie zostało aktywowane. Aby zakończyć rejestrację należy otworzyć e-mail aktywacyjny i 
          kliknąć znajdujący się w nim link. E-mail został wysłany na adres: ${email}. Link jest aktywny tylko 10 min 
          od momentu wysłania. Jeśli e-mail aktywacyjny nie dotarł proszę sprawdź SPAM.`,
          iconComponent: LockTitleIcon,
        });
        setIsOpen(true);
      }
    }
  }, [loginFetchStatus, errorTypes]);

  useEffect(() => {
    if (typeof redirectToSurvey === 'string' && loginFetchStatus === REQUEST_STATUSES.DONE)
      history.push(redirectToSurvey);
  }, [loginFetchStatus]);

  return (
    <Container>
      <SimpleModal open={isOpen} setOpen={setIsOpen} {...modalConfig} />
      <LogoSvg src={'/images/ivoting-logo.svg'} />
      <Center>
        <DownloadAppButton />
      </Center>
      <Center>
        {/*<Button onClick={handleFBLoginClick} bgColor={'#204784'}>*/}
        {/*  <Trans>LoginViaFacebook</Trans>*/}
        {/*</Button>*/}
        {/*<GoogleLogin*/}
        {/*  clientId={process.env.REACT_APP_GOOGLE as string}*/}
        {/*  buttonText="LOGIN WITH GOOGLE"*/}
        {/*  onSuccess={responseGoogle}*/}
        {/*  onFailure={failureGoogle}*/}
        {/*  render={(props: { onClick: () => void; disabled?: boolean }): JSX.Element => (*/}
        {/*    <Button onClick={props.onClick} bgColor={'#D2352D'}>*/}
        {/*      <Trans>LoginViaGoogle</Trans>*/}
        {/*    </Button>*/}
        {/*  )}*/}
        {/*/>*/}

        {/*<TextBetweenTwoHrs text={'Or'} />*/}

        <LoginForm onSubmit={onSubmit} />

        {isVoting && canVoteWithoutLogin && (
          <>
            <PrimarySmallButton
              type="button"
              style={{ margin: '2rem 0' }}
              onClick={() => history.push(`${getLoginPath()}/nickname`, history.location.state)}
            >
              <Trans>VoteWithoutRegistration</Trans>
            </PrimarySmallButton>
          </>
        )}
      </Center>
    </Container>
  );
};

export default Login;
