import { Action } from '../../types/reducerAction.types';
import {
  CLEAR_REQ_FOR_SURVEY,
  RESET_NEW_PROJECT_DATA,
  RESET_NEW_SURVEY_DATA,
  RESET_REQ_FOR_SURVEY,
  RESET_SURVEY_DETAIL,
  RESET_SURVEYS_LIST,
  RESET_VOTE_STATE,
  RESET_VOTE_STATUS,
  SET_NEW_CONFIRMED_SURVEY,
  SET_POST_NEW_SURVEY_DATA,
  SET_POST_NEW_SURVEY_FETCH_ERROR,
  SET_POST_NEW_SURVEY_FETCH_STATUS,
  SET_VOTE_FETCH_ERROR,
  SET_VOTE_FETCH_STATUS,
  SET_VOTE_FREE_ANSWERS_FETCH_ERROR,
  SET_VOTE_FREE_ANSWERS_FETCH_STATUS,
  UPDATE_NEW_SURVEY_DATA,
  UPDATE_REQ_FOR_SURVEY,
  UPDATE_REQ_FOR_SURVEY_FETCH_STATUS,
  UPDATE_SURVEY_DETAIL,
  UPDATE_SURVEY_DETAIL_FETCH_ERROR,
  UPDATE_SURVEY_DETAIL_FETCH_STATUS,
  UPDATE_SURVEYS_LIST,
} from './surveys.actionTypes';
import { REQUEST_STATUSES } from '../../constants/requestStatuses.constants';

export interface missingAuthorizationsType {
  requireLogin?: boolean | null;
  isAge?: boolean | null;
  isAddress?: boolean | null;
  isNick?: boolean | null;
  isNameSurname?: boolean | null;
  isAnonProtocol?: boolean | null;
}

const defaultVoteState = {
  // free answers are created before voting
  freeAnswersFetchStatus: REQUEST_STATUSES.NULL,
  fetchStatus: REQUEST_STATUSES.NULL,
  postFreeAnswersFetchError: null,
  postFetchError: null,
};

const INITIAL_STATE = {
  list: null,
  newSurvey: {},
  newConfirmedSurvey: null,
  detail: null,
  detailFetchStatus: REQUEST_STATUSES.NULL,
  detailFetchError: null,
  postNewSurveyData: {
    data: null,
    fetchStatus: REQUEST_STATUSES.NULL,
    fetchError: null,
  },
  vote: defaultVoteState,
  // used only for projects rn
  userRequirementsForSurvey: {
    fetchStatus: REQUEST_STATUSES.NULL,
    details: {
      survey_uuid: null,
      missing_age: null,
      invalid_age: null,
      missing_email: null,
      missing_phone: null,
      missing_address: null,
      missing_name: null,
    },
  },
};

export default function surveys(state = INITIAL_STATE, action: Action) {
  switch (action.type) {
    case UPDATE_SURVEYS_LIST:
      return {
        ...state,
        list: action.payload,
      };
    case RESET_SURVEYS_LIST:
      return {
        ...state,
        list: null,
      };
    case UPDATE_NEW_SURVEY_DATA:
      return {
        ...state,
        newSurvey: {
          ...state.newSurvey,
          ...action.payload,
        },
      };
    case RESET_NEW_SURVEY_DATA:
      return {
        ...state,
        newSurvey: {},
      };
    case UPDATE_SURVEY_DETAIL:
      return {
        ...state,
        detail: action.payload,
      };
    case UPDATE_SURVEY_DETAIL_FETCH_STATUS:
      return {
        ...state,
        detailFetchStatus: action.payload,
      };
    case UPDATE_SURVEY_DETAIL_FETCH_ERROR:
      return {
        ...state,
        detailFetchError: action.payload,
      };
    case RESET_SURVEY_DETAIL:
      return {
        ...state,
        detail: null,
      };
    case SET_VOTE_FETCH_STATUS:
      return {
        ...state,
        vote: { ...state.vote, fetchStatus: action.payload },
      };
    case SET_VOTE_FREE_ANSWERS_FETCH_STATUS:
      return {
        ...state,
        vote: { ...state.vote, freeAnswersFetchStatus: action.payload },
      };
    case SET_VOTE_FREE_ANSWERS_FETCH_ERROR:
      return {
        ...state,
        vote: {
          ...state.vote,
          postFreeAnswersFetchError: action.payload,
        },
      };
    case SET_VOTE_FETCH_ERROR:
      return {
        ...state,
        vote: {
          ...state.vote,
          postFetchError: action.payload,
        },
      };
    case RESET_VOTE_STATE:
      return {
        ...state,
        vote: { ...defaultVoteState },
      };
    case UPDATE_REQ_FOR_SURVEY:
      return {
        ...state,
        userRequirementsForSurvey: { ...state.userRequirementsForSurvey, details: action.payload },
      };
    case CLEAR_REQ_FOR_SURVEY:
      return {
        ...state,
        userRequirementsForSurvey: {
          ...state.userRequirementsForSurvey,
          details: INITIAL_STATE.userRequirementsForSurvey.details,
        },
      };
    case UPDATE_REQ_FOR_SURVEY_FETCH_STATUS:
      return {
        ...state,
        userRequirementsForSurvey: {
          ...state.userRequirementsForSurvey,
          fetchStatus: action.payload,
        },
      };
    case RESET_REQ_FOR_SURVEY:
      return {
        ...state,
        userRequirementsForSurvey: {
          ...INITIAL_STATE.userRequirementsForSurvey,
        },
      };
    case SET_NEW_CONFIRMED_SURVEY: {
      return { ...state, newConfirmedSurvey: action.payload };
    }
    case SET_POST_NEW_SURVEY_DATA: {
      return {
        ...state,
        postNewSurveyData: {
          ...state.postNewSurveyData,
          data: action.payload,
        },
      };
    }
    case SET_POST_NEW_SURVEY_FETCH_STATUS: {
      return {
        ...state,
        postNewSurveyData: {
          ...state.postNewSurveyData,
          fetchStatus: action.payload,
        },
      };
    }
    case SET_POST_NEW_SURVEY_FETCH_ERROR: {
      return {
        ...state,
        postNewSurveyData: {
          ...state.postNewSurveyData,
          fetchError: action.payload,
        },
      };
    }
    case RESET_NEW_PROJECT_DATA: {
      return {
        ...state,
        postNewSurveyData: INITIAL_STATE.postNewSurveyData,
      };
    }
    case RESET_VOTE_STATUS: {
      return {
        ...state,
        vote: INITIAL_STATE.vote,
      };
    }
    default:
      return state;
  }
}
