import ProjectSurveyPreview from '../../../../components/SurveyComponents/ProjectSurveyPreview';
import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useHistory, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getSurveysList as _getSurveysList,
  projectVote,
  resetSurveysList as _resetSurveysList,
  resetUserRequirementsForSurvey as _resetUserRequirementsForSurvey,
  resetVoteStatus,
  setUpdatedSurveysList as _setUpdatedSurveysList,
} from '../../../../reducers/Surveys/surveys.actions';
import VerifyEmailCodeModal from '../../../VerifyEmailCodeModal';
import VerifyPhoneCodeModal from '../../../VerifyPhoneCodeModal';
import {
  setEmailModalOpen as _setEmailModalOpen,
  setPhoneModalOpen as _setPhoneModalOpen,
} from '../../../../reducers/Utils/utils.actions';
import SimpleModal from '../../../../components/Modal/SimpleModal';
import {
  getLoginPath,
  getMyProfilePath,
  getSurveyPath,
  getSurveysPath,
} from '../../../../constants/paths.constants';
import { Trans, useTranslation } from 'react-i18next';
import {
  ClockIcon,
  HandTitleIcon,
  InfoIcon,
  SquareCheck,
  UserTitleIcon,
} from '../../../../components/Modal/ModalTitleIcons';
import { savePublicSurveyUid } from '../../../../utils/localStorage';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from '../../../../utils/yup/yupExtended';
import { Form } from '../../../../utils/StyledComponents/containers';
import { UseFormReturn } from 'react-hook-form/dist/types/form';
import getFieldError from '../../../../utils/getFieldError';
import { setUserNickname } from '../../../../reducers/Auth/auth.actions';
import VotingClause from '../../../../components/VotingClause/VotingClause';
import { PrimarySmallButton } from '../../../../utils/StyledComponents/buttons';
import { REQUEST_STATUSES } from '../../../../constants/requestStatuses.constants';
import { getProjectVoteSchema } from './projectVoteValidation';
import constants from '../../../../reducers/Constants/constants.reducer';
import { getConstants } from '../../../../reducers/Constants/constants.actions';
import { PrimarySmallSpinnerButton } from '../../../../components/SpinnerButton';
import moment from 'moment';

const defaultAddressAgeModalConfig = {
  title: 'MissingProfileInformations',
  text: 'NoAgeOrAddress',
};

const isVotingInFuture = (survey: any) => {
  if (!survey?.start_date) return false;
  return moment(survey.start_date).isAfter(moment(new Date()));
};

const getFormattedDateToVotingStart = (survey: any) => {
  const diff = moment(survey.start_date).diff(moment());
  return moment.duration(diff).humanize(true);
};

export interface Inputs {
  anonymized_approval: boolean;
  nickname_approval: boolean;
  name_approval: boolean;
  approval: any;
  assign_to_group: any;
  questions: Array<{
    min_required_answers: number;
    max_required_answers: number;
    answers: Array<{
      id: number;
      is_checked: boolean;
    }>;
  }>;
}

interface SurveyProjectVoteProps {
  survey: any;
  userRequirementsForSurvey?: object;
  emailModalOpen?: any;
  phoneModalOpen?: any;

  checkIfUserCanVote(data: any): void;
  setEmailModalOpen(data: any): void;
  setPhoneModalOpen(data: any): void;
  getUserRequirementsForSurvey(data: any): void;
  resetUserRequirementsForSurvey(data: any): void;
  projectVote(data: any): void;
  vote: {
    fetchStatus: string | null;
  };
  resetVoteStatus(): void;
  smsCodeId: any;
  userRequirementsForSurveyFetchStatus: any;
}

const ProjectVote: React.FC<any> = ({
  survey,
  emailModalOpen,
  phoneModalOpen,
  setEmailModalOpen,
  setPhoneModalOpen,
  getUserRequirementsForSurvey,
  resetUserRequirementsForSurvey,
  user,
  userRequirementsForSurvey,
  projectVote,
  vote,
  resetVoteStatus,
  placeholderImages,
  getConstants,
  postVoteFetchStatus,
  smsCodeId,
  userRequirementsForSurveyFetchStatus,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const missingValuesRef = useRef<any>();
  const [addressAgeModalConfig, setAddressAgeModalConfig] = useState<any>(
    defaultAddressAgeModalConfig
  );
  const [isProfileModalOpen, setIsProfileModalOpen] = useState<boolean>(false);

  const redirectWithClearingRequirementsTo = (path: any, options?: any) => {
    resetUserRequirementsForSurvey(null);
    history.push(path, options);
  };

  const handleProfileModalButtonClick = ({ redirect = true }: { redirect?: boolean }) => {
    setIsProfileModalOpen(false);
    resetUserRequirementsForSurvey(null);
    savePublicSurveyUid(survey?.uuid);
    if (redirect) {
      let searchParams = new URLSearchParams();
      searchParams.set('required', missingValuesRef.current.join(','));
      redirectWithClearingRequirementsTo(`${getMyProfilePath()}?${searchParams.toString()}`);
    }
  };

  const handleRedirectToLoginModalButtonClick = () => {
    handleProfileModalButtonClick({ redirect: false });
    redirectWithClearingRequirementsTo(getLoginPath(), {
      redirectToSurvey: `${getSurveyPath()}/${survey?.uuid}/vote`,
    });
  };

  const verifyEmailCallback = () => {
    setEmailModalOpen({ open: false, triggerRequest: false });
    getUserRequirementsForSurvey({ surveyUuid: survey?.uuid });
  };

  const verifyPhoneCallback = () => {
    setPhoneModalOpen({ open: false, triggerRequest: false });
    getUserRequirementsForSurvey({ surveyUuid: survey?.uuid });
  };

  const handleInvalidAgeModalButtonClick = () => {
    redirectWithClearingRequirementsTo(getMyProfilePath());
  };

  useEffect(() => {
    if (survey && isVotingInFuture(survey)) return;

    if (userRequirementsForSurvey.missing_email) {
      setEmailModalOpen({ triggerRequest: true, open: emailModalOpen.open });
      setTimeout(() => {
        setEmailModalOpen({ triggerRequest: true, open: true });
      }, 100);
    } else if (userRequirementsForSurvey.missing_phone) {
      if (smsCodeId || phoneModalOpen.triggerRequest) return;
      setPhoneModalOpen({ triggerRequest: false, open: phoneModalOpen.open });
      setTimeout(() => {
        setPhoneModalOpen({ triggerRequest: false, open: true });
      }, 100);
    } else if (
      (userRequirementsForSurvey.missing_age && !userRequirementsForSurvey.invalid_age) ||
      // when user is logged in, but doesn't have extended profile
      (userRequirementsForSurvey.missing_age &&
        userRequirementsForSurvey.invalid_age &&
        !user?.extended_profile) ||
      userRequirementsForSurvey.missing_name ||
      userRequirementsForSurvey.missing_address
    ) {
      missingValuesRef.current = Object.entries(userRequirementsForSurvey)
        .filter(([key, val]) => !!val && typeof val != 'string' && key && !key.includes('invalid_'))
        .map(([key, val]) => key.replace('missing_', ''));
      setAddressAgeModalConfig((prevAddressAgeModalConfig: any) => ({
        ...prevAddressAgeModalConfig,
        text:
          t(defaultAddressAgeModalConfig.text) +
          missingValuesRef.current.map((value: string) => t(value)).join(', '),
        onButtonClick: handleProfileModalButtonClick,
      }));
      setIsProfileModalOpen(true);
    } else if (
      userRequirementsForSurvey.missing_age &&
      userRequirementsForSurvey.invalid_age &&
      !user?.pk
    ) {
      setAddressAgeModalConfig((prevAddressAgeModalConfig: any) => ({
        ...prevAddressAgeModalConfig,
        title: 'Brakujące dane',
        text: t('Zaloguj się, aby zweryfikować możliwość oddania głosu'),
        onButtonClick: handleRedirectToLoginModalButtonClick,
      }));
      setIsProfileModalOpen(true);
    } else if (!userRequirementsForSurvey.missing_age && userRequirementsForSurvey.invalid_age) {
      setAddressAgeModalConfig((prevAddressAgeModalConfig: any) => ({
        ...prevAddressAgeModalConfig,
        title: t('InvalidAgeModalTitle'),
        text: t('InvalidAgeModalText') + `: ${survey.require_to_vote_min_age} lat`,
        onButtonClick: handleInvalidAgeModalButtonClick,
        iconComponent: HandTitleIcon,
      }));
      setIsProfileModalOpen(true);
    } else if (areAllRequirementsMet(userRequirementsForSurvey)) {
      setIsProfileModalOpen(false);
    }
  }, [userRequirementsForSurvey]);

  useEffect(() => {
    if (areAllRequirementsMet(userRequirementsForSurvey)) {
      setIsProfileModalOpen(false);
    }
  }, [userRequirementsForSurveyFetchStatus]);

  const areAllRequirementsMet = (userRequirementsForSurvey: any) => {
    return (
      !userRequirementsForSurvey.invalid_age &&
      !userRequirementsForSurvey.missing_address &&
      !userRequirementsForSurvey.missing_age &&
      !userRequirementsForSurvey.missing_email &&
      !userRequirementsForSurvey.missing_name &&
      !userRequirementsForSurvey.missing_phone
    );
  };

  const getDefaultValues = (survey: any) => {
    return {
      anonymized_approval: false,
      nickname_approval: false,
      name_approval: false,
      assign_to_group: false,
      questions: survey.questions.map((question: any, index: number) => ({
        max_required_answers: question.max_required_answers,
        min_required_answers: question.min_required_answers,
        answers: question.answers?.map((answer: any, index: number) => ({
          id: answer.id,
          is_checked: answer.is_default,
        })),
      })),
    };
  };

  const copiedFormMethods = useRef<UseFormReturn<any>>();

  const formMethods: UseFormReturn<Inputs> = useForm<Inputs>({
    resolver: yupResolver(getProjectVoteSchema(copiedFormMethods.current)),
    defaultValues: getDefaultValues(survey),
  });

  useEffect(() => {
    copiedFormMethods.current = formMethods;
  }, [formMethods]);

  const { handleSubmit } = formMethods;

  const onSubmit = (data: any) => {
    const selectedAnswerIds = data.questions
      .map((question: any) =>
        question.answers.filter((answer: any) => answer.is_checked).map((answer: any) => answer.id)
      )
      .flat();

    let preparedData = {
      uuid: survey.uuid,
      answers: selectedAnswerIds,
      assign_to_group: data.assign_to_group,
      is_anonymous: data.nickname_approval,
    };
    if (!data.nickname_approval && !data.name_approval && !data.anonymized_approval) {
      formMethods?.setError('approval', {
        type: 'manual',
        message: 'Musisz zaznaczyć minimum jedną zgodę',
      });
      return;
    }
    projectVote(preparedData);
  };

  useEffect(() => {
    getConstants();
    return () => {
      resetVoteStatus();
    };
  }, []);

  let timeModal: React.MutableRefObject<any> = useRef();

  const recalcAndShowClockModal = (survey: any, updateTimeOnly?: boolean) => {
    if (isVotingInFuture(survey)) {
      const formattedTextToVotingStart = getFormattedDateToVotingStart(survey);
      setAddressAgeModalConfig((prevAddressAgeModalConfig: any) => ({
        iconComponent: ClockIcon,
        title: t('VotingDidntStart'),
        text: `${t('YouWillStartVoting')} ${formattedTextToVotingStart}`,
        onButtonClick: () => {
          if (timeModal.current) clearTimeout(timeModal.current);
          timeModal.current = undefined;
          history.push(getSurveysPath());
        },
      }));
      !updateTimeOnly && setIsProfileModalOpen(true);

      timeModal.current = setTimeout(() => {
        recalcAndShowClockModal(survey, true);
      }, 60 * 1000);
    } else {
      setIsProfileModalOpen(false);
    }
  };

  useEffect(() => {
    if (survey.start_date && isVotingInFuture(survey)) {
      recalcAndShowClockModal(survey);
    }
  }, [survey]);

  return (
    <Form autoComplete={'off'} onSubmit={handleSubmit(onSubmit)}>
      <SimpleModal
        iconComponent={UserTitleIcon}
        {...addressAgeModalConfig}
        open={isProfileModalOpen}
        setOpen={setIsProfileModalOpen}
        disableClose={true}
      />
      <VerifyEmailCodeModal
        open={emailModalOpen.open}
        triggerRequest={emailModalOpen.triggerRequest}
        verifyCallback={verifyEmailCallback}
      />
      <VerifyPhoneCodeModal
        open={phoneModalOpen.open}
        triggerRequest={phoneModalOpen.triggerRequest}
        verifyCallback={verifyPhoneCallback}
      />
      {vote?.fetchStatus === REQUEST_STATUSES.DONE && (
        <SimpleModal
          title={'VoteApplied'}
          text={'TanksForVoting'}
          open={true}
          onButtonClick={() => redirectWithClearingRequirementsTo(getSurveysPath())}
          iconComponent={SquareCheck}
        />
      )}
      <ProjectSurveyPreview
        survey={survey}
        formMethods={formMethods}
        placeholderImages={placeholderImages}
      />

      <PrimarySmallSpinnerButton isSpinning={postVoteFetchStatus === REQUEST_STATUSES.FETCHING}>
        <Trans>Vote</Trans>
      </PrimarySmallSpinnerButton>

      {survey && <VotingClause organization={survey.organization} />}
    </Form>
  );
};

function mapStateToProps(state: any, otherProps: RouteComponentProps) {
  return {
    surveys: state.surveys.list,
    location: otherProps.location,
    user: state.auth.user,
    emailModalOpen: state.utils.emailModal,
    phoneModalOpen: state.utils.phoneModal,
    setUserNicknameFetchStatus: state.auth.setUserNicknameFetchStatus,
    vote: state.surveys.vote,
    postVoteFetchStatus: state.surveys.vote?.fetchStatus,
    placeholderImages: state.constants.details?.settings,
    smsCodeId: state.auth.smsCodeId,
    userRequirementsForSurveyFetchStatus: state.surveys.userRequirementsForSurvey?.fetchStatus,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getSurveysList: _getSurveysList,
    setUpdatedSurveysList: _setUpdatedSurveysList,
    resetSurveysList: _resetSurveysList,
    setEmailModalOpen: _setEmailModalOpen,
    setPhoneModalOpen: _setPhoneModalOpen,
    resetUserRequirementsForSurvey: _resetUserRequirementsForSurvey,
    setUserNickname,
    projectVote,
    resetVoteStatus,
    getConstants,
  })(ProjectVote)
);
