import React from 'react';
import { FieldError } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { ValidationTypes } from '../../types/form.types';
import { FormInputErrorText } from '../../utils/StyledComponents/forms';

interface Props {
  fieldError?: FieldError | undefined;
  validate?: ValidationTypes;
  customErrorMessage?: string;
}

const ErrorMessage: React.FC<Props> = ({ fieldError, validate, customErrorMessage }) => {
  let message = '';
  if (customErrorMessage) message = customErrorMessage;
  else if (fieldError) {
    switch (fieldError.type) {
      case 'required':
        message = 'ErrorRequired';
        break;
      case 'min':
        message = fieldError.message || 'Min';
        break;
      case 'max':
        message = fieldError.message || 'Max';
        break;
      case 'maxLength':
        const value = fieldError.message && +fieldError.message;
        message = `To pole nie może być dłuższe niż ${value} znaków`;
        break;
      case 'minLength':
        message = 'MinLength';
        break;
      case 'pattern':
        message = 'Niewłaściwy format';
        break;
      case 'validate':
        message = 'Validate';
        break;
      case 'add_new_group':
        message = 'Dodaj nową grupę z podanymi adresami email';
        break;
      case 'requiredAddAtLeastTwo':
        message = 'Dodaj minimum 2 odpowiedzi';
        break;
      case 'requiredAtLeastTwo':
        message = 'Wymagane minimum 2 odpowiedzi';
        break;
      case 'requiredThree':
        message = 'Wymagane 3 odpowiedzi';
        break;
      case 'selectDefault':
        message = 'Zaznacz domyślną odpowiedź';
        break;
      case 'fillAnswer':
        message = 'Uzupełnij odpowiedź';
        break;
      case 'email':
        message = 'Błędny email';
        break;
      case 'emailExsist':
        message = 'Taki adres email jest już dodany';
        break;
      case 'selectAnswer':
        message = 'Zaznacz odpowiedź';
        break;
      case 'differentPasswords':
        message = 'Hasła róźnią się';
        break;
      case 'chooseOption':
        message = 'Wybierz jedną z opcji';
        break;
      case 'endDateLessThanStartDate':
        message = 'Data zakończenia nie może być wcześnieja niż data rozpoczęcia';
        break;
      case 'startDateGreaterThanEndDate':
        message = 'Data rozpoczęcia nie może być późniejsza niż data zakończenia';
        break;
      case 'datesCantBeTheSame':
        message = 'Daty nie mogą być takie same';
        break;
      case 'dateCantBeInThePast':
        message = 'Data nie może być w przeszłości';
        break;
      case 'nicknameTaken':
        message = 'Ten nick jest już zajęty. Proszę wybierz inny.';
        break;
      case 'manual':
      default:
        message = fieldError?.message || 'Error';
        break;
    }
  }
  return message ? (
    <FormInputErrorText style={{ textAlign: 'center' }}>
      <Trans>{message}</Trans>
    </FormInputErrorText>
  ) : (
    <></>
  );
};

export default ErrorMessage;
