import React from 'react';
import { isSWAvailable, useServiceWorker } from '../utils/useServiceWorker';
import { AlertTitleIcon } from './Modal/ModalTitleIcons';
import SimpleModal from './Modal/SimpleModal';

const SWModal = () => {
  const [isOpen, onUpdateClick] = useServiceWorker();

  const newVersionAvailableModalConfig = {
    iconComponent: AlertTitleIcon,
    title: 'Nowa wersja aplikacji',
    text: `Dostepna jest nowa wersja aplikacji. Naciśnij przycisk "Aktualizuj" aby zaktualizować aplikację do najnowszej wersji.`,
    buttonText: 'Aktualizuj',
    handleClose: onUpdateClick,
    disableClose: true,
  };

  return (
    <>{isSWAvailable() && <SimpleModal open={isOpen} {...newVersionAvailableModalConfig} />}</>
  );
};

export default SWModal;
