import axios from 'axios';
import { history } from './history';
import { SET_IS_LOGGED, UNAUTH_USER } from '../reducers/Auth/auth.actionTypes';
import { showNotification } from './taost.config';
import { deleteSessionToken, getSessionToken, getVoterId } from './localStorage';
import { getLoginPath, pathsExcludedFromInterceptor } from '../constants/paths.constants';
import { matchPath } from 'react-router';

const isInterceptorExcluded = () => {
  const routes = pathsExcludedFromInterceptor;
  const currentPathname = window.location.pathname;
  for (let route of routes) {
    const activePath = matchPath(currentPathname, route);
    if (activePath) {
      return true;
    }
  }
  return false;
};

const versionInterceptor = (response: any) => {
  const pwaVersion = response.headers['x-app-version-pwa'];
  const androidVersion = response.headers['x-app-version-android'];
  const iosVersion = response.headers['x-app-version-ios'];
};

export default class NetworkService {
  static setupDefaultHeaders({
    token,
    voterId,
  }: {
    token?: string | null;
    voterId?: string | null;
  }) {
    if (token) axios.defaults.headers.common['Authorization'] = `Token ${token}`;
    if (voterId) axios.defaults.headers.common['X-VOTE-ID'] = voterId;
  }

  static resetDefaultHeaders() {
    axios.defaults.headers.common = {};
  }

  static syncHeaderTokenWithStorage() {
    const token = getSessionToken();
    const voterId = getVoterId();
    if (token && token.length > 1) this.setupDefaultHeaders({ token, voterId });
  }

  static setupInterceptors(store: any) {
    axios.interceptors.response.use(
      function (response) {
        versionInterceptor(response);
        return response;
      },
      function (error) {
        console.error(error.response.status);
        if (+error.response.status === 401) {
          if (isInterceptorExcluded()) return Promise.reject(error);
          deleteSessionToken();
          axios.defaults.headers.common = {};
          store.dispatch({ type: UNAUTH_USER });
          history.push(getLoginPath());
        }
        if (+error.response.status === 500) {
          showNotification({
            message: 'ErrorServer',
            type: 'error',
          });
        }
        return Promise.reject(error);
      }
    );
  }
}
