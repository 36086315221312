import styled from 'styled-components';
import { device, size, sizes } from '../../theme';

export const SurveyListButtonInfo = styled.div`
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 50%;
  right: 2px;
  transform: translateY(-50%);

  height: calc(100% - 4px);
  padding: 0 21px;

  background-color: #9bbe72;
  border-radius: 50px;

  text-transform: uppercase;
  font-size: 15px;

  display: none;

  @media ${device.laptop} {
    display: flex;
  }
`;

export const SurveyListButtonTime = styled.div`
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);

  font-size: 0.875rem;
  line-height: 0.875rem;
`;

export const BaseTemplateContainer = styled.div`
  // min-height: 100vh;
  margin-top: env(safe-area-inset-top);
  margin-bottom: env(safe-area-inset-bottom);
  min-height: calc(100% - env(safe-area-inset-top));
  position: relative;
`;

export const HeaderContainer = styled.div`
  display: none;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translate(-50%, 0);

  @media ${device.laptop} {
    display: block;
  }
`;

export const Container: any = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: ${(props: any) =>
    props.bgDark ? 'url(/images/mobile-bg-dark.svg)' : 'url(/images/mobile-bg-white.svg)'};
  background-size: 100% auto;
  background-repeat: repeat-y;
  margin-top: env(safe-area-inset-top);
  margin-bottom: env(safe-area-inset-bottom);
  height: calc(100% - env(safe-area-inset-top));

  @media ${device.laptop} {
    background-image: ${(props: any) =>
      props.bgDark ? 'url(/images/background-dark.svg)' : 'url(/images/background-white.svg)'};
    background-position: center center;
    background-size: cover;
  }
`;

export const ContainerBlock: any = styled(Container)`
  display: block;
  padding: 28px;
  padding-top: ${sizes.navbarHeightDesktopPxVal + 20}px;
  position: relative;
`;

export const FlexContainer: any = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 80px 0 100px 0;

  background-image: ${(props: any) =>
    props.bgDark ? 'url(/images/mobile-bg-dark.svg)' : 'url(/images/mobile-bg-white.svg)'};
  background-size: 100% auto;
  background-repeat: repeat-y;

  @media ${device.laptop} {
    padding: 190px 0 100px 0;
    background-image: ${(props: any) =>
      props.bgDark ? 'url(/images/background-dark.svg)' : 'url(/images/background-white.svg)'};
    background-position: center center;
    background-size: cover;
  }
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.laptop} {
    max-width: 600px;
  }
`;

export const FormBlock = styled(Form)`
  width: 100%;
  display: block;

  button {
    margin-left: auto;
    margin-right: auto;
  }

  @media ${device.laptop} {
    width: 600px;
  }
`;

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  //max-width: 90%;
  //margin-left: auto;
  //margin-right: auto;
`;

export const TextOnlyViewContentContainer = styled.div`
  width: ${sizes.contentWidthVwVal}vw;
  max-width: ${sizes.maxContentWidthPxVal}px;
`;

export const AbsoluteButtonContainer = styled.div`
  position: absolute;
  bottom: 60px;
  left: 0;
  right: 0;

  button {
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-height: ${size.heightXS}) {
    position: relative;
    padding-top: 20px;
    button {
      min-width: 250px;
    }
  }
`;

export const ContentContainer = styled.div`
  @media ${device.laptop} {
    > * {
      max-width: 650px;
    }
  }
`;
