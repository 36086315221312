import React from 'react';
import InputTextarea from '../../../components/Forms/InputTextarea';
import { FormLabel, FormSublabel } from '../../../utils/StyledComponents/forms';
import { Trans } from 'react-i18next';
import AnswersFieldArray from './AnswersFieldArray';
import { useFieldArray } from 'react-hook-form';
import { BigCircleButton } from '../../../utils/uiComponents';
import styled from 'styled-components';
import DeleteOutlineIcon from '@material-ui/icons/Close';
import { colors } from '../../../theme';
import Checkbox from '../../../components/Forms/Checkbox';

const ButtonsContainer = styled.div`
  display: flex;

  button {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`;

interface Props {
  questions?: any;
  register?: any;
  errors?: any;
  formState?: any;
  handleAppend?: any;
  control?: any;
  setValue?: any;
  getValues?: any;
  setErrors?: any;
  defaultAnswer?: boolean;
}

const QuestionsFieldArray: React.FC<Props> = (props) => {
  const {
    control,
    register,
    setValue,
    getValues,
    errors,
    formState, // to check dirty fields
    /*questions, register, formState, handleAppend*/
    setErrors,
    defaultAnswer,
  } = props;

  const { fields, append, remove, prepend } = useFieldArray({
    control,
    name: 'questions',
  });

  return (
    <>
      {fields.map((item: any, questionIndex: number) => {
        return (
          <React.Fragment key={item.id}>
            <InputTextarea
              label={'Question'}
              name={`questions[${questionIndex}].question`}
              formRef={register}
              fieldError={errors?.questions?.[questionIndex]?.question}
              formState={formState}
              defaultValue={item.question}
              formType={'survey'}
              showRequiredStar={false}
              control={control}
            />
            <Checkbox
              label={'Włącz odpowiedź swobodną'}
              name={`questions[${questionIndex}].is_free_answers`}
              formRef={register}
              formState={formState}
              style={{ marginTop: '2rem' }}
              defaultChecked={item.is_free_answers}
              popoverText={
                'Ostrzeżenie dla Twórcy ankiety: Ivoting.pl nie ponosi odpowiedzialności za treść wpisów zamieszczanych przez głosujących w polu Odpowiedź swobodna.'
              }
            />
            <FormLabel formType={'survey'}>
              <Trans>CreateOwnAnswers</Trans>
            </FormLabel>
            {defaultAnswer && (
              <FormSublabel>
                <Trans>Zaznacz jedną z odpowiedzi, aby ustawić ją jako domyślną</Trans>
              </FormSublabel>
            )}
            <AnswersFieldArray
              nestIndex={questionIndex}
              item={item}
              questionIndex={questionIndex}
              formState={formState}
              handleAppend={() => {}}
              errors={errors}
              onlyOneChoice={true}
              defaultAnswer={defaultAnswer}
              surveyType={'extended'}
              {...{ control, register, setValue, getValues }}
            />
            <ButtonsContainer>
              {fields.length > 1 && (
                <BigCircleButton
                  type={'button'}
                  color={colors.red}
                  bgColor={'transparent'}
                  borderColor={colors.red}
                  onClick={() => remove(questionIndex)}
                >
                  <DeleteOutlineIcon />
                </BigCircleButton>
              )}
              {fields.length === questionIndex + 1 && (
                <BigCircleButton
                  type={'button'}
                  onClick={() => {
                    append({ name: 'append' });
                    setErrors((prevState: any) => ({
                      ...prevState,
                      questions: [
                        ...prevState.questions,
                        {
                          question: undefined,
                          answers: undefined,
                        },
                      ],
                    }));
                  }}
                >
                  +
                </BigCircleButton>
              )}
            </ButtonsContainer>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default QuestionsFieldArray;
