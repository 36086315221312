import React, { useEffect } from 'react';
import InputImage from '../../../components/Forms/InputImage';
import { FormInput } from '../../../utils/StyledComponents/forms';
import ErrorMessage from '../../../components/Forms/ErrorMessage';
import { useFieldArray } from 'react-hook-form';
import { BigCircleButton } from '../../../utils/uiComponents';
import { colors } from '../../../theme';
import DeleteOutlineIcon from '@material-ui/icons/Close';
import styled from 'styled-components';

const PictureControlsContainer = styled.div`
  display: flex;
`;

const DeleteButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -2px;

  button {
    margin: auto 10px;
  }
`;

interface Props {
  register: any;
  formState: any;
  control: any;
  name?: string;
  answerCount: number;
  getImageDefaultValue: any;
  isEditMode: any;
  newSurvey: any;
  answersErrors: any;
  setAnswerCount: (arg1: any) => void;
  setAnswersErrors: (arg1: any) => void;
  setValue: any;
}

const PicturesFieldArray: React.FC<Props> = (props) => {
  const {
    register,
    formState,
    control,
    answerCount,
    getImageDefaultValue,
    isEditMode,
    newSurvey,
    answersErrors,
    setAnswerCount,
    setAnswersErrors,
    setValue,
  } = props;

  const { fields, remove, append }: any = useFieldArray({
    control,
    name: 'answers',
  });

  useEffect(function setDefaultImagesValues() {
    if (!newSurvey || !newSurvey.questions || !newSurvey.questions[0].answers.length) return;
    for (let [index, answer] of Object.entries(newSurvey.questions[0].answers)) {
      const { image }: any = answer;
      setValue(`answers[${index}].image`, image);
    }
  }, []);

  return (
    <>
      {fields.map((answer: any, index: number) => {
        return (
          <React.Fragment key={answer.id}>
            <PictureControlsContainer>
              <InputImage
                name={`answers[${index}].image`}
                formRef={register}
                formState={formState}
                defaultValue={getImageDefaultValue(
                  isEditMode,
                  isEditMode ? newSurvey.questions[0].answers[index] : undefined
                )}
                control={control}
              />
              {fields.length > 2 ? (
                <DeleteButtonContainer>
                  <BigCircleButton
                    type={'button'}
                    color={colors.red}
                    bgColor={'transparent'}
                    borderColor={colors.red}
                    onClick={() => remove(index)}
                  >
                    <DeleteOutlineIcon />
                  </BigCircleButton>
                </DeleteButtonContainer>
              ) : (
                <></>
              )}
            </PictureControlsContainer>
            <FormInput
              {...register(`answers[${index}].text`)}
              formType={'survey'}
              defaultValue={fields[index].text}
            />
            {!!answersErrors[index] && <ErrorMessage fieldError={{ type: 'fillAnswer' }} />}
          </React.Fragment>
        );
      })}
      <BigCircleButton
        type={'button'}
        onClick={() => {
          setAnswerCount((prev: any) => prev + 1);
          setAnswersErrors({});
          append({ image: null, text: null });
        }}
      >
        +
      </BigCircleButton>
    </>
  );
};

export default PicturesFieldArray;
