import React, { useEffect, useState } from 'react';
import { UserData } from '../../../types/auth.types';
import { history } from '../../../utils/history';
import NewSurveySimple from '../NewSurveySimple';
import NewSurveyExtended from '../NewSurveyExtended';
import NewSurveyPicture from '../NewSurveyPicture';
import SurveySettings from './SurveySettings';
import Invite from './Invite';
import Preview from './Preview';
import { getMyProfilePath } from '../../../constants/paths.constants';
import { Center, FlexContainer } from '../../../utils/StyledComponents/containers';
import { AbsoluteDrawerBackButton } from '../../../components/DrawerBackButton';
import NewSurveyTypeSelect from './NewSurveyTypeSelect';
import { SurveyData } from '../../../types/surveys.types';
import { showNotification } from '../../../utils/taost.config';
import i18next from 'i18next';

interface Props {
  user: UserData;
  type: 'simple' | 'extended' | 'image' | undefined;
  newSurvey: SurveyData;
  updateNewSurveyData(data: any): void;

  resetNewSurveyData(): void;
}

const NewSurvey: React.FC<Props> = ({
  user,
  type: surveyType,
  resetNewSurveyData,
  newSurvey,
  updateNewSurveyData,
}) => {
  const [step, setStep] = useState<any>(surveyType ? 1 : 0);
  const [isBackVisible, setIsBackVisible] = useState<any>(false);

  useEffect(() => {
    if (!user.is_phone_verified || !user.is_email_verified) {
      showNotification({
        message: i18next.t('MissingEmailAndPhoneVerification'),
        type: 'error',
        autoClose: 5000,
      });
      setTimeout(() => history.push(getMyProfilePath()), 300);
    }
  }, []);

  useEffect(() => {
    resetNewSurveyData();
  }, []);

  useEffect(() => {
    if (surveyType && step > 1) setIsBackVisible(true);
    if (!surveyType || step === 1) setIsBackVisible(false);
  }, [step, surveyType]);

  const getSurvey = () => {
    switch (surveyType) {
      case 'simple':
        return (
          <NewSurveySimple
            setStep={setStep}
            newSurvey={newSurvey}
            updateNewSurveyData={updateNewSurveyData}
          />
        );
      case 'extended':
        return (
          <NewSurveyExtended
            setStep={setStep}
            newSurvey={newSurvey}
            updateNewSurveyData={updateNewSurveyData}
          />
        );
      case 'image':
        return (
          <NewSurveyPicture
            setStep={setStep}
            newSurvey={newSurvey}
            updateNewSurveyData={updateNewSurveyData}
          />
        );
      default:
        return null;
    }
  };

  return (
    <FlexContainer>
      <Center>
        {!surveyType && <NewSurveyTypeSelect setStep={setStep} />}
        {surveyType && step === 1 && getSurvey()}
        {isBackVisible && (
          <AbsoluteDrawerBackButton
            isBackVisible={isBackVisible}
            onClick={() => {
              setStep((prevState: any) => prevState - 1);
            }}
          />
        )}
        {surveyType && step === 2 && <SurveySettings type={surveyType} setStep={setStep} />}
        {surveyType && step === 3 && <Invite setStep={setStep} />}
        {surveyType && step === 4 && (
          <Preview setStep={setStep} setIsBackVisible={setIsBackVisible} />
        )}
      </Center>
    </FlexContainer>
  );
};

export default NewSurvey;
